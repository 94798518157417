export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_AGE = 'SET_USER_AGE';
export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_TEAM_ID = 'SET_TEAM_ID';
export const SET_LEAGUE_ID = 'SET_LEAGUE_ID';
export const SET_TEAM_NAME = 'SET_TEAM_NAME';
export const SET_TEAM_LOGO = 'SET_TEAM_LOGO';
export const SET_TEAM_ROLE = 'SET_TEAM_ROLE';
export const SET_TEAM_SPORT = 'SET_TEAM_SPORT';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_MOBILE_PHONE = 'SET_MOBILE_PHONE';
export const SET_USER_IMAGE = 'SET_USER_IMAGE';
export const SET_TEAM_USERID = 'SET_TEAM_USERID';
export const SET_MASTER_ACCOUNT = 'SET_MASTER_ACCOUNT';
export const SET_TEAM_PASSCODE = 'SET_TEAM_PASSCODE';
export const SET_API_ENDPOINT = 'SET_API_ENDPOINT';
export const SET_TEAM_PID = 'SET_TEAM_PID';
export const SET_LOGIN_UUID = 'SET_LOGIN_UUID';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_DOB = 'SET_DOB';
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const SET_PUSH_TOKEN = 'SET_PUSH_TOKEN';
export const SET_TIMEZOME = 'SET_TIMEZOME';
export const SET_ADMIN_FLAG = 'SET_ADMIN_FLAG';
export const SET_EMP_NAME = 'SET_EMP_NAME';
export const SET_EMP_ID = 'SET_EMP_ID';
export const SET_PANIC_BUTTON = 'SET_PANIC_BUTTON';
export const SET_BUS_BOARDING = 'SET_BUS_BOARDING';
export const SET_EARLY_RELEASE = 'SET_EARLY_RELEASE';
export const SET_FLAG_ARRAY = 'SET_FLAG_ARRAY';
export const SET_SLOT_INDEX = 'SET_SLOT_INDEX';

export const SET_HALL_PASS = 'SET_HALL_PASS';
export const SET_REUNIFY = 'SET_REUNIFY';
export const SET_EMERGENCY = 'SET_EMERGENCY';
export const SET_SKIP_FLAG = 'SET_SKIP_FLAG';

export const SET_LOGO_URL = 'SET_LOGO_URL';

export const setJwtToken = jwtToken => dispatch => {
    dispatch({
        type: SET_JWT_TOKEN,
        payload: jwtToken
    });
};

export const setName = name => dispatch => {
    dispatch({
        type: SET_USER_NAME,
        payload: name
    });
};

export const setAge = age => dispatch => {
    dispatch({
        type: SET_USER_AGE,
        payload: age
    });
};

export const setFirstName = firstName => dispatch => {
    dispatch({
        type: SET_FIRST_NAME,
        payload: firstName
    });
};

export const setLastName = lastName => dispatch => {
    dispatch({
        type: SET_LAST_NAME,
        payload: lastName
    });
};

export const setTeamId = teamId => dispatch => {
    dispatch({
        type: SET_TEAM_ID,
        payload: teamId
    });
};

export const setLeagueId = leagueId => dispatch => {
    dispatch({
        type: SET_LEAGUE_ID,
        payload: leagueId
    });
};

export const setTeamName = teamName => dispatch => {
    dispatch({
        type: SET_TEAM_NAME,
        payload: teamName
    });
};

export const setTeamLogo = teamLogo => dispatch => {
    dispatch({
        type: SET_TEAM_LOGO,
        payload: teamLogo
    });
};

export const setTeamRole = teamRole => dispatch => {
    dispatch({
        type: SET_TEAM_ROLE,
        payload: teamRole
    });
};

export const setTeamSport = teamSport => dispatch => {
    dispatch({
        type: SET_TEAM_SPORT,
        payload: teamSport
    });
};

export const setUserId = userId => dispatch => {
    dispatch({
        type: SET_USER_ID,
        payload: userId
    });
};

export const setMobilePhone = mobilePhone => dispatch => {
    dispatch({
        type: SET_MOBILE_PHONE,
        payload: mobilePhone
    });
};

export const setUserImage = userImage => dispatch => {
    dispatch({
        type: SET_USER_IMAGE,
        payload: userImage
    });
};

export const setTeamUserId = teamUserId => dispatch => {
    dispatch({
        type: SET_TEAM_USERID,
        payload: teamUserId
    });
};

export const setMasterAccount = masterAccount => dispatch => {
    dispatch({
        type: SET_MASTER_ACCOUNT,
        payload: masterAccount
    });
};

export const setTeamPasscode = passcode => dispatch => {
    dispatch({
        type: SET_TEAM_PASSCODE,
        payload: passcode
    });
};

export const setApiEndpoint = apiEndpoint => dispatch => {
    dispatch({
        type: SET_API_ENDPOINT,
        payload: apiEndpoint
    });
};


export const setTeamPId = teamPId => dispatch => {
    dispatch({
        type: SET_TEAM_PID,
        payload: teamPId
    });
};

export const setLoginUuid = loginUuid => dispatch => {
    dispatch({
        type: SET_LOGIN_UUID,
        payload: loginUuid
    });
};

export const setCompanyName = companyName => dispatch => {
    dispatch({
        type: SET_COMPANY_NAME,
        payload: companyName
    });
};

export const setDob = dob => dispatch => {
    dispatch({
        type: SET_DOB,
        payload: dob
    });
};

export const setProfileImage = profileImage => dispatch => {
    dispatch({
        type: SET_PROFILE_IMAGE,
        payload: profileImage
    });
};

export const setPushToken = pushToken => dispatch => {
    dispatch({
        type: SET_PUSH_TOKEN,
        payload: pushToken
    });
};

export const setTimeZone = timeZone => dispatch => {
    dispatch({
        type: SET_TIMEZOME,
        payload: timeZone
    });
};
export const setAdminFlag = adminFlag => dispatch => {
    dispatch({
        type: SET_ADMIN_FLAG,
        payload: adminFlag
    });
};
export const setEmployeeName = employeeName => dispatch => {
    dispatch({
        type: SET_EMP_NAME,
        payload: employeeName
    });
};
export const setEmployeeId = employeeId => dispatch => {
    dispatch({
        type: SET_EMP_ID,
        payload: employeeId
    });
};
export const setPanicButton = panicButton => dispatch => {
    dispatch({
        type: SET_PANIC_BUTTON,
        payload: panicButton
    });
};
export const setBusBoarding = busBoarding => dispatch => {
    dispatch({
        type: SET_BUS_BOARDING,
        payload: busBoarding
    });
};
export const setEarlyRelease = doEarlyRelease => dispatch => {
    dispatch({
        type: SET_EARLY_RELEASE,
        payload: doEarlyRelease
    });
};

export const setFlagArray = flagArray => dispatch => {
    dispatch({
        type: SET_FLAG_ARRAY,
        payload: flagArray
    });
};


export const setSIndex = sIndex => dispatch => {
    dispatch({
        type: SET_SLOT_INDEX,
        payload: sIndex
    });
};


export const setHallPass = hallPassMenu => dispatch => {
    dispatch({
        type: SET_HALL_PASS,
        payload: hallPassMenu
    });
};

export const setReunify = reunifyMenu => dispatch => {
    dispatch({
        type: SET_REUNIFY,
        payload: reunifyMenu
    });
};

export const setEmergency = emergencyMenu => dispatch => {
    dispatch({
        type: SET_EMERGENCY,
        payload: emergencyMenu
    });

    
};

export const setSkipFlag = skipFlag => dispatch => {
    dispatch({
        type: SET_SKIP_FLAG,
        payload: skipFlag
    });

    
};

export const setLogoUrl = logoUrl => dispatch => {
    dispatch({
        type: SET_LOGO_URL,
        payload: logoUrl
    });

    
};