import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import FolderIcon from '@mui/icons-material/Folder';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { DataGrid } from '@mui/x-data-grid';
import {   GridActionsCellItem,
    GridAddIcon,
    GridDeleteIcon,
  } from "@mui/x-data-grid";
import Avatar from '@mui/material/Avatar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'; 
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Icon from '@mui/material/Icon';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import HomeIcon from '@mui/icons-material/Home';
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import {QRCodeSVG} from 'qrcode.react';
import Stack from '@mui/material/Stack';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Countdown from 'react-countdown';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';

const MatEdit = ({ index }) => {

    const handleEditClick = () => {
        // some action
    }


    return  <Icon baseClassName="fas" className="fa-plus-circle" color="primary" />
};


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Room() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [locArray,setLocArray] = useState([]);
  const [rowId,setRowId] = useState();
  const [roomArray, setRoomArray] = React.useState([])
  const [passId, setPassId] = React.useState()
  const [passSelected, setPassSelected] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false);
  const [folderOpen, setFolderOpen] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(false);
  const [alignment, setAlignment] = React.useState('web');
  const [didSelect, setDidSelect] = React.useState(false);
  const [user,setUser] = useState(reactLocalStorage.get('userName'))
  const [searchParams] = useSearchParams();
  const [passArray, setPassArray] = React.useState([]);
  const [dateSelected, setDateSelected] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"));
  const [pieArray, setPieArray] = React.useState([]);
  const [lineArray, setLineArray] = React.useState([]);
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const { pathname } = useLocation();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


  useEffect(() => {

    var compName = reactLocalStorage.get('companyName');
    var empId = reactLocalStorage.get('empId');

    getRooms(compName)


  }, []);




  const goToMenu = () => navigate('/teacher',true)
  const goToRoom = () => navigate('/room',true)
  const goToAccounts = () => navigate('/student',true)
  const goToReported = () => navigate('/reported',true)
  const goToPasses = () => navigate('/pass',true)
  const goToTeacher = () => navigate('/teachercreate',true)
  const goToGroups = () => navigate('/groupteacher',true)


  const goToEdit=(pId)=>{
    navigate('/editpass',{state:{id:pId}});
    }

    const goToEditFolder=(pId)=>{
      navigate('/editfolder',{state:{id:pId}});
      }




    
   

const passChange = (value) => {

      if (value != '' && value != undefined && value != null) {
  
          console.log(value.id)
          setPassId(value.id)
          setPassSelected(value.label)
  
      }
  
    
  
  
    }



    

    const getFolderPasses = (compName,folderId) => {
    
      var token = reactLocalStorage.get('jwtToken');
  
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.get(apiUrl + '/PassMaints/GetFolder/' + compName + "/" + folderId, auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error)
      
                } else {
      
                    console.log(response.data);
      
              
                  
                    var tempArray = [];
                    setRoomArray([])
  
                    for (var i = 0; i < response.data.length; i++) {
  
                     console.log("===================")
                     console.log(response.data[i].pasS_ID)
                     tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].id,"color": response.data[i].pasS_COLOR,"folderFlag": response.data[i].foldeR_FLAG ,"approve": response.data[i].approvE_FLAG, "folderId": response.data[i].foldeR_ID})
  
                 }
  
                 if (tempArray) {
  
                  setRoomArray(tempArray)
  
                 }
  
                
                  
                 
             
                    
      
      
                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
      
        });
      
      }
      



const Completionist = () => <Typography style={{color:'red'}}>Open</Typography>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};
  
  const columns = [

       { field: 'folderName', headerName: ' ',flex: 1},
    
        { field: 'passName', headerName: 'Room',flex: 1},

        {
        field: 'makeDefault', headerName: 'Default',flex: 1,
  
            renderCell: (params) => {
                return (
       
  <>
                    {params.row.makeDefault === "Y" ?(

<>
<HomeIcon />
</>
                    ) : (
<>
</>

                    )}
  
  </>
  

                );
             }
          },
        {
          flex: 1,

          renderCell: (params) => {
              return (
     




<Button variant="outlined" style={{fontSize: 12}} onClick={() => makeDefault(params.row.id, "Y")}>Make Default</Button>
       
              );
           }
        },

        {
            flex: 1,
            field: 'id', headerName: ' ',flex: 1,
            renderCell: (params) => {
                return (
       
  
  
  
  
  <Button variant="contained" style={{fontSize: 12, backgroundColor: 'red'}} onClick={() => makeDefault(params.row.id, "N")}>Remove</Button>
         
                );
             }
          },


   



    ];
  
    

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);

    
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleFolderClose = () => {
    setFolderOpen(false);
  };

  const goToPass = () => {

    navigate('/addpass')

  }

  const goToHall = () => navigate('/hallteacher',true)

  const goToFolder = () => {

    navigate('/addfolder')

  }

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => locArray.find((row) => row.id === id));
    console.log(selectedRowsData);
    console.log("Here is the id: " + selectedRowsData.id)

    setDidSelect(!didSelect);
    
   
  };


  const notify = () => toast("Pass was updated successfully");

  const doUpload = () => {

    setAlert(false);

    putPass(rowId,"N")

  };


  const postPass = (compName,pName,pImage,pColor,timeNeeded,dateCreated,userName,capacity,locationId) => {

    setLoading(true);

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

    console.log("COMPANY_NAME " + compName)
    console.log("PASS_NAME " + pName)
    console.log("PASS_IMAGE " + pImage)
    console.log("PASS_COLOR " + pColor)
    console.log("TIME_NEEDED " + timeNeeded)
    console.log("DATE_CREATED " + dateCreated)
    console.log("USERNAME " + userName)
    console.log("CAPACITY " + capacity)
    console.log("LOCATION_ID " + locationId)

var apiUrl = reactLocalStorage.get('apiUrl');
axios.post(apiUrl + '/PassMaints',{ 
       COMPANY_NAME: compName,
       PASS_NAME: pName,
       PASS_IMAGE: pImage,
       PASS_COLOR: pColor,
       TIME_NEEDED: timeNeeded,
       DATE_CREATED: dateCreated,
       USERNAME: userName,
       CAPACITY: capacity,
       LOCATION_ID: locationId
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setPassName('')

      notify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }



    const getRooms = (compName) => {
    
      var token = reactLocalStorage.get('jwtToken');
      var empId = reactLocalStorage.get('empId');
      var userName = reactLocalStorage.get('userName');
  
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };

        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.get(apiUrl + '/HallEmps/GetRooms/' + compName + "/" + empId + "/" + userName, auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error)
      
                } else {
      
        
                    setLocArray(response.data)
  
                  
                 
             
                    
      
      
                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
      
        });
      
      }
  
  


    const makeDefault = (id,makeDefault) => {

      var companyName = reactLocalStorage.get('companyName');
      var userName = reactLocalStorage.get('userName');

      setLoading(true);

      var jwtToken = reactLocalStorage.get('jwtToken');

      const auth = {
          headers: {
              Authorization: "Bearer " + jwtToken
          }
      };
  
      
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.put(apiUrl + '/HallEmps/RoomDefault/' + id, {
          COMPANY_NAME: companyName,
          USERNAME: userName,
          MAKE_DEFAULT: makeDefault
      }, auth).then(response => {
    
          if (response.data.error) {
              setLoading(false);
            
              console.log(response.data.error)
    
          } else {
              
            console.log(response.data)
            setLoading(false);
            getRooms(companyName)
  
 
          }
    
      }).catch(error => {
    
  
        console.log(error)
    
      });
    
    }
  
    

    const updateDest = (id) => {

      var companyName = reactLocalStorage.get('companyName');
      var userName = reactLocalStorage.get('userName');

      setLoading(true);

      var jwtToken = reactLocalStorage.get('jwtToken');

      const auth = {
          headers: {
              Authorization: "Bearer " + jwtToken
          }
      };
  
      
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.put(apiUrl + '/Passes/UpdateDest/' + id, {
          ID: id,
          USERNAME: userName
      }, auth).then(response => {
    
          if (response.data.error) {
              setLoading(false);
            
              console.log(response.data.error)
    
          } else {
              
            console.log(response.data)
            setLoading(false);
  
            var dateAdded = moment(dateSelected).tz("America/New_York").format("YYYY-MM-DD");

      
          }
    
      }).catch(error => {
    
        setLoading(false);
        console.log(error)
    
      });
    
    }
  
    

    const putPass = (id) => {

      //in post request

      var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
  
      var name = reactLocalStorage.get('name');
      var userName = reactLocalStorage.get('userName');
      var companyName = reactLocalStorage.get('companyName');


      setLoading(true);

      var jwtToken = reactLocalStorage.get('jwtToken');

      const auth = {
          headers: {
              Authorization: "Bearer " + jwtToken
          }
      };
  
      
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.put(apiUrl + '/Passes/PutPass/' + id, {
          RECEIVED_FLAG: "Y",
          RECEIVED_DATE: dateCreated,
          RECEIVED_BY: name
      }, auth).then(response => {
    
          if (response.data.error) {
              setLoading(false);
            
              console.log(response.data.error)
    
          } else {
              
            console.log(response.data)
            setLoading(false);
  
  
          }
    
      }).catch(error => {
    
  
        console.log(error)
    
      });
    
    }
  
  const navigate = useNavigate();
  const goToLogout = () => navigate('/');

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };
  

  

  const handleClose = () => {
    setAlert(false);
  };



  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{width: 400,height: 300,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Default Time: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}
        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />

            </IconButton>


            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             

        
My Rooms
            </Typography>

            
           <Button variant="contained" onClick={goToTeacher} style={{backgroundColor: 'white'}}>


           <Typography style={{color: 'black'}}>
               + Create Pass
               </Typography>
           </Button>

           <Button variant="outlined" style={{borderColor: 'white',marginLeft: 20,alignItems: 'center'}} onClick={() => goToGroups()}>
           <Typography style={{color: 'white'}}>
               Manage Groups
               </Typography>
           </Button>

          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
     
     <ListItemButton onClick={goToMenu}>
 <ListItemIcon>
   <HallIcon />
 </ListItemIcon>
 <ListItemText primary="Hall Monitor" />
</ListItemButton>

<ListItemButton onClick={goToRoom}>
 <ListItemIcon>
 <BarChartIcon />
 </ListItemIcon>
 <ListItemText primary="My Rooms" />
</ListItemButton>


<ListItemButton onClick={goToReported}>
 <ListItemIcon>
 <WarningIcon />
 </ListItemIcon>
 <ListItemText primary="Reported" />
</ListItemButton>



       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>

<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>
     </List>




        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
       
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>



  
      <DataGrid
        style={{marginTop: 10}}
        rows={locArray}
        columns={columns}
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />



                </Paper>
              </Grid>

              <Grid item xs={12}>


              {didSelect ? (
      

      <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >


      <ToggleButton variant="contained" color="success" value="web">Passes</ToggleButton>
      <ToggleButton variant="contained" color="info" value="android">Edit</ToggleButton>
      <ToggleButton variant="contained" color="error" value="ios">Delete</ToggleButton>
    </ToggleButtonGroup>

        ) : (
          <>
            
          </>
        )}
       



              </Grid>

              <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{marginBottom: 10}} id="modal-modal-title" variant="h6" component="h2">
            Change Origin/Return
          </Typography>
        
          <Autocomplete
          disablePortal
          id="combo-box-demo"
      onChange={(event, value) => {

      passChange(value)

      if (value) {

        if (value.folderFlag == "Y" && value.folderFlag != undefined && value.folderFlag != null) {

          var companyName = reactLocalStorage.get('companyName');
          getFolderPasses(companyName,value.folderId)
          setFolderOpen(true);

        } else {

          setModalOpen(false);
          updateDest(value.id)
        }

      }
    


      }} 
  options={passArray}
  style={{marginBottom: 20}}
  renderOption={(props, option) => (
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

    <Box
    style={{
      backgroundColor: option.color,
      width: 200,
      padding: 5,
      borderRadius: 10,
      color: 'white'

    }}
  >

{option.folderFlag == "Y" ? (
<>



<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} >

<Typography>{option.label}</Typography>
<ArrowForwardIcon/>



</Stack>


</>          

  ) : (
    <>
   <Typography>{option.label}</Typography>
    </>
  )}
     
      </Box>










</Box>










)}
renderInput={(params) => (
<TextField
{...params}
label="Where to?"
/>
)}
/>


<Button onClick={handleModalClose}>Cancel</Button>

        </Box>

      </Modal>


      <Modal
        open={folderOpen}
        onClose={handleFolderClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{marginBottom: 10}} id="modal-modal-title" variant="h6" component="h2">
            Select Room
          </Typography>
        
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => {

            console.log(value.id)
    
            passChange(value)

            setModalOpen(false);

            setFolderOpen(false);
  

            }} 
        options={roomArray}
        style={{marginBottom: 20}}
        renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

          <Box
          style={{
            backgroundColor: option.color,
            width: 200,
            padding: 5,
            borderRadius: 10,
            color: 'white'

          }}
        >

            <Typography>{option.label}</Typography>
     
            </Box>
     

    


     




    </Box>










  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Where to?"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password', // disable autocomplete and autofill
      }}
    />
  )}
/>

<Button onClick={handleFolderClose}>Cancel</Button>

        </Box>

      </Modal>


              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove Room/Pass"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this room/pass?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}