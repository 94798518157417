import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
  Snackbar,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from '@mui/material';
import { Alert } from '@mui/material';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';

export default function Lost() {


  const [progressVisible, setProgressVisible] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  });

  const [emailValidating, setEmailValidating] = useState(false);
  const [emailTimeout, setEmailTimeout] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });



    console.log(value)

  };

  const validateEmail = (email) => {
    if (email.includes('@') && email.includes('.')) {
      setEmailValidating(true);

      axios.get('https://api.bridgepay.io/api/users/checkuser/' + email).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)

                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    email: '',
                  }));

                  
                  
    
              } else {
    
                  console.log(response.data);

                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    email: 'Email is already in use.',
                  }));

              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);

          setFormErrors((prevErrors) => ({
            ...prevErrors,
            email: '',
          }));
    
      });

     




    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email must contain "@" and "."',
      }));
    }
  };

  const validatePasswords = () => {
    let errors = {};

    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    } else {
      errors.password = '';

      console.log(formData.password)
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.confirmPassword !== formData.password) {

        console.log(formData.confirmPassword)
        console.log(formData.password)
      errors.confirmPassword = 'Passwords do not match';
    } else {
      errors.confirmPassword = '';
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'Email is invalid';
    }

    if (!formData.firstName) {
      isValid = false;
      errors.firstName = 'First name is required';
    }

    if (!formData.lastName) {
      isValid = false;
      errors.lastName = 'Last name is required';
    }

    if (!formData.dateOfBirth) {
      isValid = false;
      errors.dateOfBirth = 'Date of birth is required';
    }

    setFormErrors(errors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();


      setDialogOpen(true);
    
  };

  const navigate = useNavigate();

  const goToRooms = () => navigate('/',true)

  const handleConfirmation = async () => {
    setDialogOpen(false);
    setProgressVisible(true);

    const uuid = uuidv4(); // Generate UUID



    try {
      const response = await axios.get('https://api.bridgepay.io/api/users/LostPasswordEmail/' + formData.email);
      console.log('Form submission successful:', response.data);
      setToastOpen(true);
      setFormData({
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error scenario (show error message, retry, etc.)
    } finally {
      setProgressVisible(false);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };


  return (
    <Container component="main" maxWidth="xs">
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 2 }}
          onClick={() => goToRooms()}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Login
        </Typography>
      </Toolbar>
    </AppBar>
    <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">
        Lost Password
      </Typography>
      <Box component="form" sx={{ mt: 3 }} noValidate onSubmit={handleSubmit}>
 
      
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address or Username"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}

            />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
        {progressVisible && <LinearProgress sx={{ width: '100%', mt: 3 }} />}
      </Box>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you sure this is the email you used to register?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmation} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        autoHideDuration={10000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleToastClose}>
          An email was sent to your account to reset your password
        </Alert>
      </Snackbar>
    </Container>
  );
}
