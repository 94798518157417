import {SET_USER_NAME, SET_USER_AGE, SET_JWT_TOKEN,SET_FIRST_NAME,SET_LAST_NAME,SET_TEAM_ID,SET_LEAGUE_ID,SET_TEAM_NAME,SET_TEAM_LOGO,SET_TEAM_ROLE,SET_TEAM_SPORT,SET_USER_ID,SET_MOBILE_PHONE,SET_USER_IMAGE,SET_TEAM_USERID,
    SET_MASTER_ACCOUNT,SET_TEAM_PASSCODE,SET_API_ENDPOINT,SET_TEAM_PID,
    SET_LOGIN_UUID,SET_COMPANY_NAME,
    SET_DOB,SET_PROFILE_IMAGE,SET_PUSH_TOKEN,SET_TIMEZOME,
    SET_ADMIN_FLAG,
    SET_EMP_NAME,
    SET_EMP_ID,
    SET_PANIC_BUTTON,
    SET_BUS_BOARDING,
    SET_EARLY_RELEASE,
    SET_FLAG_ARRAY,
    SET_SLOT_INDEX,
    SET_HALL_PASS,
    SET_REUNIFY,
    SET_EMERGENCY,
    SET_SKIP_FLAG,
    SET_LOGO_URL} from './actions'

const initialState = {
name: '',
age: 0,
jwtToken: '',
firstName: '',
lastName: '',
teamId: '',
leagueId: '',
teamName: '',
teamLogo: '',
teamRole: '',
teamSport: '',
userId: '',
mobilePhone: '',
userImage: 'https://no_image',
teamUserId: '',
masterAccount: 'N',
passcode: '',
apiEndpoint: 'https://api.conciergepadedu.com/api',
teamPId: '',
loginUuid: '',
companyName: '',
userDob: '',
profileImage: 'https://no_image',
pushToken: '',
timeZone: 'EST',
adminFlag: '',
employeeName: '',
employeeId: '',
panicButton: '',
busBoarding: '',
doEarlyRelease: '',
flagArray: [],
sIndex: 0,
hallPassMenu: 'N',
reunifyMenu: 'N',
emergencyMenu: 'N',
skipFlag: null,
logoUrl: 'https://no_image.png',

}

function userReducer(state = initialState,action) {
switch (action.type) {

    case SET_USER_NAME:
        return {...state,name: action.payload};
    case SET_USER_AGE:
        return {...state,age: action.payload}
    case SET_JWT_TOKEN:
            return {...state,jwtToken: action.payload}
    case SET_FIRST_NAME:
             return {...state,firstName: action.payload};
    case SET_LAST_NAME:
             return {...state,lastName: action.payload};
    case SET_TEAM_ID:
             return {...state,teamId: action.payload};
     case SET_LEAGUE_ID:
              return {...state,leagueId: action.payload};
    case SET_TEAM_NAME:
              return {...state,teamName: action.payload};
    case SET_TEAM_LOGO:
              return {...state,teamLogo: action.payload};
    case SET_TEAM_ROLE:
              return {...state,teamRole: action.payload};
    case SET_TEAM_SPORT:
            return {...state,teamSport: action.payload};
    case SET_USER_ID:
            return {...state,userId: action.payload};
    case SET_MOBILE_PHONE:
            return {...state,mobilePhone: action.payload};
    case SET_USER_IMAGE:
            return {...state,userImage: action.payload};
    case SET_TEAM_USERID:
            return {...state,teamUserId: action.payload};
    case SET_MASTER_ACCOUNT:
            return {...state,masterAccount: action.payload};
    case SET_TEAM_PASSCODE:
            return {...state,passcode: action.payload};
    case SET_API_ENDPOINT:
            return {...state,apiEndpoint: action.payload};
    case SET_TEAM_PID:
            return {...state,teamPId: action.payload};
    case SET_LOGIN_UUID:
            return {...state,loginUuid: action.payload};
    case SET_COMPANY_NAME:
            return {...state,companyName: action.payload};
    case SET_DOB:
            return {...state,userDob: action.payload};
    case SET_PROFILE_IMAGE:
            return {...state,profileImage: action.payload};
    case SET_PUSH_TOKEN:
            return {...state,pushToken: action.payload};
    case SET_TIMEZOME:
            return {...state,timeZone: action.payload};
    case SET_ADMIN_FLAG:
            return {...state,adminFlag: action.payload};
    case SET_EMP_NAME:
            return {...state,employeeName: action.payload};
    case SET_EMP_ID:
            return {...state,employeeId: action.payload};
    case SET_PANIC_BUTTON:
            return {...state,panicButton: action.payload};
    case SET_BUS_BOARDING:
            return {...state,busBoarding: action.payload};
    case SET_EARLY_RELEASE:
            return {...state,doEarlyRelease: action.payload};
    case SET_FLAG_ARRAY:
            return {...state,flagArray: action.payload};
    case SET_SLOT_INDEX:
            return {...state,sIndex: action.payload};
    case SET_HALL_PASS:
            return {...state,hallPassMenu: action.payload};
    case SET_REUNIFY:
            return {...state,reunifyMenu: action.payload};
    case SET_EMERGENCY:
            return {...state,emergencyMenu: action.payload};
    case SET_SKIP_FLAG:
            return {...state,skipFlag: action.payload};
    case SET_LOGO_URL:
            return {...state,logoUrl: action.payload};
    default:
        return state;
}
}

export default userReducer;