import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import {reactLocalStorage} from 'reactjs-localstorage';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import { Importer, ImporterField } from 'react-csv-importer';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import {   GridActionsCellItem,
    GridAddIcon,
    GridDeleteIcon,
  } from "@mui/x-data-grid";


// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import io from 'socket.io-client';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Message() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [searchParams] = useSearchParams();
  const [logoUrl,setLogoUrl] = useState();
  const { handle } = useParams()
  const [locArray, setLocArray] = React.useState([])
  const [uploadArray, setUploadArray] = React.useState([])
  const [sId, setSId] = React.useState()
  const [popup, setPopup] = React.useState(false);
  const [groupName, setGroupName] = React.useState();
  const [groupArray, setGroupArray] = React.useState([]);
  const [selectGroup, setSelectGroup] = React.useState(true);
  const [studentArray, setStudentArray] = React.useState([]);
  const [dtlArray, setDtlArray] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const [rowId, setRowId] = React.useState([]);
  const [isConnnected, setIsConnected] = React.useState(false);

  //Room State
  const [room, setRoom] = useState("");
  const [socket, setSocket] = useState(null);

  // Messages States
  const [message, setMessage] = useState("");
  const [messageReceived, setMessageReceived] = useState("");




  useEffect(() => {

    const socketInstance = io('https://www.conciergepadapp.com');

    setSocket(socketInstance);
  
    // listen for events emitted by the server

    socketInstance.on('connect', () => {
      console.log('Connected to server');
      setIsConnected(true)

      var userName = reactLocalStorage.get('userName');
      socketInstance.emit('allRooms', userName);

    });

    socketInstance.on('foundAllRooms', (data) => {

      try {
        
        console.log("user " + data[0].user)
        console.log("room " + data[0].room)



      }
       catch (e) {
       console.log(e); 
      }
      

      console.log('Received message: ' +  data);

      let array=   data.filter((v,i,a)=>a.findIndex(v2=>(v2.room===v.room))===i)

      console.log(array);
      setRooms(array);

      

    });
  
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
        console.log('disconnected...');
      }
    };
  }, []);

  /*useEffect(() => {
    // ...
  
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [socket]);*/

  const handleSubmit = () => {

    console.log("button click")
  
    if (socket) {
      var userName = reactLocalStorage.get('userName');
      socket.emit('allRooms', userName);
    }
  };


  /*useEffect(() => {

    var userName = reactLocalStorage.get('userName');

    socket.emit('allRooms',userName);

    socket.on('foundAllRooms', (data) => {
       console.log("receive message from room: " + data)

       setRooms(data);

     });
     return () => {
       socket.off("foundAllRooms");
     };
 }, [socket]);*/




  const handleOpen = () => {
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  const hadleDialogClose = () => {
    setAlert(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  useEffect(() => {

      

    var logo = reactLocalStorage.get('logoUrl');
    var companyName = reactLocalStorage.get('companyName');

    setLogoUrl("https://s3.us-east-2.amazonaws.com/logosedu/" + logo);


    //getGroups(companyName);
    //getStudents(companyName)




  }, []);



  const getStudents = (compName) => {
    
    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/Rosters/GetStudentsAll/' + compName, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);

                   var tempArray = [];
                   setGroupArray([])

                   for (var i = 0; i < response.data.length; i++) {

                    console.log("===================")
                    console.log(response.data[i].studenT_GROUP)
                    tempArray.push({"label":response.data[i].firsT_NAME + " " + response.data[i].lasT_NAME,"id":response.data[i].studenT_ID,"email":response.data[i].email})

                }

                if (tempArray) {

                    setGroupArray(tempArray)

                }

              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

    const studentChange = (value) => {

        if (value != '' && value != undefined && value != null) {
    
            setStudentArray(value);

            
    
            console.log("======================================")
            console.log(value)
            console.log(value.length)
            console.log("======================================")
        }
    
      
    
    
  }
    

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);
  };


  const getGroups = (compName) => {
    
    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/GroupHdrs/GetGroups/' + compName, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);

                   var tempArray = [];
                   setLocArray([])

                   for (var i = 0; i < response.data.length; i++) {

                    console.log("===================")
                    console.log(response.data[i].grouP_NAME)
                    tempArray.push({"label":response.data[i].grouP_NAME,"id":response.data[i].id})

                }

                if (tempArray) {

                    setLocArray(tempArray)

                }
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    
 const getGroupDetail = (compName,groupId) => {
    
        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/GetGroups/' + compName + '/' + groupId, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                       console.log("============================")
                       console.log("Group Detail Array");
                       console.log(response.data);
                       console.log("============================")


                       setDtlArray(response.data)
    

                   
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }
        



const navigate = useNavigate();

const goToRooms = () => navigate('/room',true)

const goToHall = () => navigate('/hall',true)

const goToPasses = () => navigate('/pass',true)

const goToAdd = () => navigate('/addpass',true)

const goToFolder = () => navigate('/addfolder',true)

const goToAccounts = () => navigate('/student',true)

const goToMenu = () => navigate('/dashboard',true)


const uploadDtl = (userName,studentId) => {

    setLoading(true);
 
    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');
 
 
    const auth = {
       headers: {Authorization: "Bearer " + token} 
     };
 
     console.log("username " + userName)

 
 var apiUrl = reactLocalStorage.get('apiUrl');
 var comnpanyName = reactLocalStorage.get('companyName');
 var dateAdded = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
 
 axios.post(apiUrl + '/GroupDtls',{
     GROUP_ID:sId,
     USERNAME: userName,
     STUDENT_ID: studentId,
     COMPANY_NAME: comnpanyName,
     DATE_ADDED: dateAdded,
     GROUP_USER: userName
 },auth).then(response => {
 
 
     if (response.data.error) {
 
       setLoading(false);
 
     } else {
 
       console.log('success', response.data)

       getGroupDetail(comnpanyName,sId)
 
       setLoading(false);

       
 
 
       
 
     }
 
    }).catch(error => {
 
       setLoading(false);
       console.log(error.response)
 
     });
 
  
   }


   const columns = [
    { field: 'room', headerName: 'Request',  flex: 1,
    renderCell: (params) => {
        return (
            <div className="d-flex justify-content-between align-items-center">

<Box
    style={{
      padding: 10,
      width: 200,
      borderRadius: 10,
      color: 'black' ,
      marginTop: 5,
      marginBottom: 5,
      fontSize: 16
    }}
  >

<Typography style={{ fontSize: 16}}>
    {JSON.parse(params.row.room).student}
    </Typography>








  </Box>


 
             </div>
        );
     }
  },
  ];

  
 
const uploadHdr = () => {

    setLoading(true);
 
    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');
 
 
    const auth = {
       headers: {Authorization: "Bearer " + token} 
     };
 
 
 var apiUrl = reactLocalStorage.get('apiUrl');
 var comnpanyName = reactLocalStorage.get('companyName');
 var dateAdded = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
 
 axios.post(apiUrl + '/GroupHdrs',{
     GROUP_NAME:groupName,
     COMPANY_NAME: comnpanyName,
     DATE_ADDED: dateAdded,
     ACTIVE_FLAG: 'Y',
     GROUP_USER: userName
 },auth).then(response => {
 
 
     if (response.data.error) {
 
       setLoading(false);
 
     } else {
 
       console.log('success', response.data)
 
       setLoading(false);
       setPopup(false);
       getGroups(comnpanyName);
 
 
       
 
     }
 
    }).catch(error => {
 
       setLoading(false);
       console.log(error.response)
 
     });
 
  
   }
 

  const putGroup = (id,activeFlag) => {

    setLoading(true);

    var token = reactLocalStorage.get('jwtToken');


   const auth = {
      headers: {Authorization: "Bearer " + token} 
    };

    console.log("ID " + id)
    console.log("ACTIVE_FLAG " + activeFlag)

var apiUrl = reactLocalStorage.get('apiUrl');
axios.put(apiUrl + '/GroupDtls/RemoveStudent/' + id,{ 
       ID: id,
       ACTIVE_FLAG: activeFlag
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

     

      console.log("==========================")
      console.log('success', response.data)
      setLoading(false);
      var compName = reactLocalStorage.get('companyName');
      notify();
      getGroupDetail(compName,sId);
      console.log("==========================")
  
     
  

      


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }

  const handleOnCellClick = (params) => {

     
    
     console.log(params.row.room)

     reactLocalStorage.set('room', params.row.room);
     navigate("/chat")
    
      
    




  };


  const notify = () => toast("Student was removed from group successfully");

  const doUpload = () => {

    setAlert(false);

    if (studentArray.length > 0 || uploadArray.length > 0) {

        console.log("==========================")
        console.log("the group id: " + sId)
        console.log("the group array: " + studentArray)
        console.log("==========================")

        if (studentArray.length > 0) {

            for (var i = 0; i < studentArray.length; i++) {

                uploadDtl(studentArray[i].email,studentArray[i].id);
        
            }
        }


        if (uploadArray.length > 0) {

            for (var i = 0; i < uploadArray.length; i++) {

                uploadDtl(uploadArray[i].USERNAME,uploadArray[i].STUDENT_ID);
        
            }
        }
       

       
    }

   


  };


  const postPass = (compName,pName,pImage,pColor,timeNeeded,dateCreated,userName,capacity,locationId) => {

    setLoading(true);

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

    console.log("COMPANY_NAME " + compName)
    console.log("PASS_NAME " + pName)
    console.log("PASS_IMAGE " + pImage)
    console.log("PASS_COLOR " + pColor)
    console.log("TIME_NEEDED " + timeNeeded)
    console.log("DATE_CREATED " + dateCreated)
    console.log("USERNAME " + userName)
    console.log("CAPACITY " + capacity)
    console.log("LOCATION_ID " + locationId)

var apiUrl = reactLocalStorage.get('apiUrl');
axios.post(apiUrl + '/PassMaints',{ 
       COMPANY_NAME: compName,
       PASS_NAME: pName,
       PASS_IMAGE: pImage,
       PASS_COLOR: pColor,
       TIME_NEEDED: timeNeeded,
       DATE_CREATED: dateCreated,
       USERNAME: userName,
       CAPACITY: capacity,
       LOCATION_ID: locationId
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setPassName('')

      notify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }


 
  const goToLogout = () => navigate('/');

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };





  const changeGroupName = (val) => {

    console.log(val);

    setGroupName(val);
    
  
  }

  const card = (
    <React.Fragment>
      <CardContent style={{width: 400,height: 300,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Default Time: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}
        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Chat Room(s)
              
            </Typography>
      
            <Typography variant="body2">
          {name}
          {reactLocalStorage.get('userName')}

          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />

      
          <List component="nav">
     
          <ListItemButton onClick={goToMenu}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
 <ListItemButton onClick={goToPasses}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Rooms" />

      </ListItemButton>


 <ListItemButton onClick={goToAccounts}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Accounts" />
    </ListItemButton>



 <ListItemButton onClick={goToHall}>
      <ListItemIcon>
        <HallIcon />
      </ListItemIcon>
      <ListItemText primary="Hall Monitor" />
    </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>

</ListSubheader>

<ListItemButton onClick={goToLogout}>
  <ListItemIcon>
    <AssignmentIcon />
  </ListItemIcon>
  <ListItemText primary="Logout" />
</ListItemButton>
          </List>




        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            


        
  
          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
              <Grid item xs={12} md={8} lg={9}>
        
              </Grid>
       
   

              <Grid item xs={12}>


<DataGrid
        rows={rooms}
        columns={columns}
        autoHeight
        onCellClick={handleOnCellClick}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />



            
            
              </Grid>

              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={hadleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to add to group?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add students to group {groupName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hadleDialogClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}