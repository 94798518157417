import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import ReportIcon from '@mui/icons-material/BarChart';
import TeacherViewIcon from '@mui/icons-material/Face';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';


import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
    GridToolbarExport
  } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import Icon from '@mui/material/Icon';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from "dayjs";




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function StatsPass() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [locArray,setLocArray] = useState([]);
  const [permission, setPermission] = React.useState(1);
  const [dateSelected, setDateSelected] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"));
  const [fromDate, setFromDate] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"));
  const [toDate, setToDate] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"));
  const [alignment, setAlignment] = React.useState('web');
  const [didSelect, setDidSelect] = React.useState(false);
  const [user,setUser] = useState(reactLocalStorage.get('userName'))
  const [searchParams] = useSearchParams();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});


  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {

    var compName = reactLocalStorage.get('companyName');

    //getStaff(compName);

  }, []);

  const [anchorEl, setAnchorEl] = React.useState(false);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changePermission = (event) => {
    setPermission(event.target.value);
  };

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = (pId) => {

        console.log("========================")
        console.log("handleclick");
      const id = pId
      setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'permissioN_FLAG' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }




  const roles = ['Market', 'Finance', 'Development'];


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {

    console.log("===========================")
    console.log("I am here")
    console.log("===========================")


    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });


  };

  const handleSaveClick = (id,permissionFlag) => () => {

    console.log("===========================")
    console.log("save the info")
    console.log("===========================")

    console.log(id)
    console.log(permissionFlag)
    setRowModesModel(
        { ...rowModesModel, 
            [id]: { mode: GridRowModes.View } 
        });

    console.log(rowModesModel)

  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));


    console.log("row update")

    updateProfile(updatedRow.id,updatedRow.permissioN_FLAG)

    

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
      console.log("handleRowModesModelChange")
    setRowModesModel(newRowModesModel);
  };

  const columns = [

    { field: 'studentName', headerName: 'Student', width: 200,flex: 1 },
    { field: 'passName', headerName: 'Pass', width: 200, flex: 1},
    { field: 'studentId', headerName: 'Student ID', width: 200, flex: 1},
    { field: 'passCount', headerName: 'No of Passes', width: 130 },


   
    ];

  const updateProfile = (id,permissionFlag) => {

    var companyName = reactLocalStorage.get('companyName');

    setLoading(true);

    var jwtToken = reactLocalStorage.get('jwtToken');

    const auth = {
        headers: {
            Authorization: "Bearer " + jwtToken
        }
    };

    
    var apiUrl = reactLocalStorage.get('apiUrl');
    axios.put(apiUrl + '/Directories/UpdateProfile/' + id, {
        ID: id,
        PERMISSION_FLAG: permissionFlag
    }, auth).then(response => {
  
        if (response.data.error) {
            setLoading(false);
          
            console.log(response.data.error)
  
        } else {
            
          console.log(response.data)
          setLoading(false);

          //getStaff(companyName);
  
  
        }
  
    }).catch(error => {
  
      setLoading(false);
      console.log(error)
  
    });
  
  }

  

  const goToMenu = () => navigate('/dashboard',true)
  const goToPasses = () => navigate('/pass',true)
  const goToStudents = () => navigate('/reportdates',true)
  const goToStaff = () => navigate('/stats',true)
  const goToStatsByPass = () => navigate('/statspass',true)
  const goToAccounts = () => navigate('/student',true)

  const goToReportMenu = () => navigate('/reportdates',true)

  const goToTeacher = () => navigate('/teacher',true)

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);

    
  };

  const goToHall = () => navigate('/hall',true)

  const goToPass = () => {

    navigate('/addpass')

  }

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => locArray.find((row) => row.id === id));
    console.log(selectedRowsData);
    console.log("Here is the id: " + selectedRowsData.id)

    setDidSelect(!didSelect);
    
   
  };


  const notify = () => toast("Pass was created successfully");

  const doUpload = () => {

    setAlert(false);
   
    var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
    //var dateCreated = new Date();

    postPass(companyName,passName,value,passColor,passTime,dateCreated,name,passCapacity,"0")

  };


  const postPass = (compName,pName,pImage,pColor,timeNeeded,dateCreated,userName,capacity,locationId) => {

    setLoading(true);

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

    console.log("COMPANY_NAME " + compName)
    console.log("PASS_NAME " + pName)
    console.log("PASS_IMAGE " + pImage)
    console.log("PASS_COLOR " + pColor)
    console.log("TIME_NEEDED " + timeNeeded)
    console.log("DATE_CREATED " + dateCreated)
    console.log("USERNAME " + userName)
    console.log("CAPACITY " + capacity)
    console.log("LOCATION_ID " + locationId)

var apiUrl = reactLocalStorage.get('apiUrl');
axios.post(apiUrl + '/PassMaints',{ 
       COMPANY_NAME: compName,
       PASS_NAME: pName,
       PASS_IMAGE: pImage,
       PASS_COLOR: pColor,
       TIME_NEEDED: timeNeeded,
       DATE_CREATED: dateCreated,
       USERNAME: userName,
       CAPACITY: capacity,
       LOCATION_ID: locationId
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setPassName('')

      notify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }

  const goToReport = () => {

    var comp = reactLocalStorage.get('companyName');
    var fDate = moment(fromDate).tz("America/New_York").format("YYYY-MM-DD")
    var tDate = moment(toDate).tz("America/New_York").format("YYYY-MM-DD")
    getReport(comp,fDate,tDate)

  }

  const getReport = (compName,fromDate,toDate) => {

    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/Passes/PassStatsStudent/' + compName + "/" + fromDate + "/" + toDate, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);

                  var tempArray = [];
                  setRows([])

                  for (var i = 0; i < response.data.length; i++) {

                   console.log("===================")
                   console.log(response.data[i].pasS_ID)
                   tempArray.push({"id":i,"studentName":response.data[i].studentName,"passName": response.data[i].passName,"studentId": response.data[i].studentId,"passCount": response.data[i].passCount})

               }

               if (tempArray) {

                setRows(tempArray)

               }


    
            
               

           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    


  const navigate = useNavigate();
  const goToLogout = () => navigate('/');
  const goToLocation = () => navigate('/location')

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };
  

  

  const handleClose = () => {
    setAlert(false);
  };



  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{width: 400,height: 300,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Default Time: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}
        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             

        Accounts

            </Typography>
      
            <Typography variant="body2">
          {name}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
     
     <ListItemButton onClick={goToMenu}>
<ListItemIcon>
<DashboardIcon />
</ListItemIcon>
<ListItemText primary="Dashboard" />
</ListItemButton>
<ListItemButton onClick={goToPasses}>
<ListItemIcon>
<BarChartIcon />
</ListItemIcon>
<ListItemText primary="Rooms" />

</ListItemButton>


<ListItemButton onClick={goToAccounts}>
<ListItemIcon>
<PeopleIcon />
</ListItemIcon>
<ListItemText primary="Accounts" />
</ListItemButton>






<ListItemButton onClick={goToReportMenu}>
<ListItemIcon>
<ReportIcon />
</ListItemIcon>
<ListItemText primary="Reports" />
</ListItemButton>
  <Divider sx={{ my: 1 }} />
  <ListSubheader component="div" inset>

</ListSubheader>


<ListItemButton onClick={goToTeacher}>
<ListItemIcon>
<TeacherViewIcon />
</ListItemIcon>
<ListItemText primary="Teacher View" />
</ListItemButton>



<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>




</List>



        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          <ButtonGroup style={{marginBottom: 10}} variant="outlined" aria-label="outlined button group">
      <Button onClick={goToStudents}>Dates</Button>
      <Button onClick={goToStaff}>Sum Stats</Button>
      <Button onClick={goToStatsByPass}>By Pass</Button>
    </ButtonGroup>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
       
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

<Stack style={{marginBottom: 5}} direction="row" alignItems="center" justifyContent="center" spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DemoContainer style={{marginBottom: 10}} components={['DatePicker']}>
        <DatePicker style={{marginBottom: 10}} label="From date"
          value={dayjs(fromDate)}
          onChange={(newValue) => {


            var dateFormatted = new Date(dayjs(newValue))
            var dateForSelected = moment(dateFormatted).tz("America/New_York").format("MM/DD/YYYY")
            var dateForEvents = moment(dateFormatted).tz("America/New_York").format("YYYY-MM-DD")

           
            console.log(moment(dateForSelected).tz("America/New_York").format("MM/DD/YYYY"))

            setFromDate(dateForSelected)

            var compName = reactLocalStorage.get('companyName');

            //getEvents(compName,dateForEvents)
          
          }
        }
           
       
        />
      </DemoContainer>
    </LocalizationProvider>


    <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DemoContainer style={{marginBottom: 10}} components={['DatePicker']}>
        <DatePicker style={{marginBottom: 10}} label="To date"
          value={dayjs(toDate)}
          onChange={(newValue) => {


            //var myDate = JSON.stringify(newValue.$d)

            //console.log(myDate);

            var dateFormatted = new Date(dayjs(newValue))
            var dateForSelected = moment(dateFormatted).tz("America/New_York").format("MM/DD/YYYY")
            var dateForEvents = moment(dateFormatted).tz("America/New_York").format("YYYY-MM-DD")

           
            console.log(moment(dateForSelected).tz("America/New_York").format("MM/DD/YYYY"))

            setToDate(dateForSelected)

            var compName = reactLocalStorage.get('companyName');

            //getEvents(compName,dateForEvents)
          
          }
        }
           
       
        />
      </DemoContainer>
    </LocalizationProvider>

<Button onClick={goToReport}>Search</Button>


    </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        slots={{ toolbar: GridToolbar,EditToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            setRows, 
            setRowModesModel
          },
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}


        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />



                </Paper>
              </Grid>

              <Grid item xs={12}>


              {didSelect ? (
      

      <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >


      <ToggleButton variant="contained" color="success" value="web">Passes</ToggleButton>
      <ToggleButton variant="contained" color="info" value="android">Edit</ToggleButton>
      <ToggleButton variant="contained" color="error" value="ios">Delete</ToggleButton>
    </ToggleButtonGroup>

        ) : (
          <>
            
          </>
        )}
       



              </Grid>

              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to create a pass?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are create a hall pass for {passName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}