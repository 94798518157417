import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { SketchPicker,CirclePicker } from 'react-color'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AddPass() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [travelType, setTravelType] = React.useState("Round-trip")
  const [roomNo, setRoomNo] = React.useState("")
  const [checked, setChecked] = React.useState(false)

  useEffect(() => {

      
    console.log("==========================")
    var compName = reactLocalStorage.get('companyName');
    console.log("Get companyName: " + compName)
    console.log("==========================")




  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);
  };


  const notify = () => toast("Pass was created successfully");

  const doUpload = () => {

    setAlert(false);
   
    var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
    //var dateCreated = new Date();

    var comp = reactLocalStorage.get('companyName');

    postPass(comp,passName,'',passColor,passTime,dateCreated,name,passCapacity,'1','1',travelType,roomNo,checked)

  };


  const postPass = (compName,pName,pImage,pColor,timeNeeded,dateCreated,userName,capacity,locationId,roomId,roundTrip,roomNo,approve) => {

   setLoading(true);

   var token = reactLocalStorage.get('jwtToken');

   var approveFlag = "N";

   if (approve == true) {

      approveFlag = "Y";
   }


   const auth = {
      headers: {Authorization: "Bearer " + token} 
    };

    console.log("COMPANY_NAME " + compName)
    console.log("PASS_NAME " + pName)
    console.log("PASS_IMAGE " + pImage)
    console.log("PASS_COLOR " + pColor)
    console.log("TIME_NEEDED " + timeNeeded)
    console.log("DATE_CREATED " + dateCreated)
    console.log("USERNAME " + userName)
    console.log("CAPACITY " + capacity)
    console.log("LOCATION_ID " + locationId)
    console.log("ROOM_ID " + roomId)
    console.log("ROUND_TRIP " + roundTrip)
    console.log("ROOM_NO " + roomNo)

var apiUrl = reactLocalStorage.get('apiUrl');

axios.post(apiUrl + '/PassMaints',{ 
       COMPANY_NAME: compName,
       PASS_NAME: pName,
       PASS_IMAGE: pImage,
       PASS_COLOR: pColor,
       TIME_NEEDED: timeNeeded,
       DATE_CREATED: dateCreated,
       USERNAME: userName,
       CAPACITY: capacity,
       LOCATION_ID: locationId,
       ROOM_ID: roomId,
       ROUND_TRIP: roundTrip,
       ROOM_NO: roomNo,
       APPROVE_FLAG: approveFlag
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setPassName('')

      notify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }


  const navigate = useNavigate();

  const goToMenu = () => navigate('/dashboard',true)

  const goToPasses = () => navigate('/pass',true)

  const goToAccounts = () => navigate('/student',true)

  const goToLogout = () => navigate('/');

  const goToReport = () => navigate('/reportdates',true)

const goToTeacher = () => navigate('/teacher',true)



  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };


  const handleClose = () => {
    setAlert(false);
  };

  const handleSelect = (event) => {
    setTravelType(event.target.value);
  };




  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };


  const changeRoomNo = (val) => {

    console.log(val);

    setRoomNo(val);
    
  
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{borderRadious: 5,width: 400,height: 250,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Time limit: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}

        </Typography>

        <Typography style={{fontSize: 20}} color="white">
Type: {travelType}

        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
                   
       New Room
            </Typography>
      
            <Typography variant="body2">
          {name}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
     

          <List component="nav">
     
     <ListItemButton onClick={goToMenu}>
 <ListItemIcon>
   <DashboardIcon />
 </ListItemIcon>
 <ListItemText primary="Dashboard" />
</ListItemButton>
<ListItemButton onClick={goToPasses}>
 <ListItemIcon>
   <BarChartIcon />
 </ListItemIcon>
 <ListItemText primary="Rooms" />
</ListItemButton>

<ListItemButton onClick={goToAccounts}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Accounts" />
    </ListItemButton>




       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>

<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>
     </List>




        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
            
                  }}
                >
    
            <TextField
              margin="normal"
              required
              fullWidth
              defaultValue={passName}
              value={passName}
              id="passName"
              label="Name of Room/Pass"
              name="passName"
              autoFocus
              onChange={(event) => changePassName(event.target.value)}
            />


<Select
style={{marginTop: 10}}
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={travelType}
    label="Travel Type"
    onChange={handleSelect}
  >
    <MenuItem value='Round-trip'>Round-trip</MenuItem>
    <MenuItem value={'One-way'}>One-way</MenuItem>
  </Select>



<FormControl fullWidth style={{marginTop: 20,marginBottom: 20}}>
<Typography variant="body2">
        Time limit (in minutes)
        </Typography>
<Slider
        size="medium"
        defaultValue={8}
        aria-label="Medium"
        valueLabelDisplay="auto"
        value={passTime} 
        onChange={handleTime}
        marks
        height={40}
        color="secondary"
  min={5}
  max={100}
      />

      </FormControl>


      <FormControl fullWidth>
      <Typography variant="body2">
         Capacity
        </Typography>
<Slider
        size="large"
        defaultValue={20}
        aria-label="Large"
        valueLabelDisplay="auto"
        value={passCapacity} 
        onChange={handleCapacity}
      />


      </FormControl>


      <FormControlLabel control={<Switch checked={checked} onChange={switchHandler} defaultcolor="error"  />} label="Needs Approval" />
      
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={40} md={4} lg={3}>
        
              <Paper
                >



<TextField
style={{marginBottom: 10,marginRight: 5,marginLeft: 5}}
              margin="normal"
              required
              defaultValue={roomNo}
              value={roomNo}
              id="roomNo"
              label="Room No"
              name="passName"
              onChange={(event) => changeRoomNo(event.target.value)}
            />
                <CirclePicker
                style={{marginTop: 5,marginLeft: 5}}
                 color={passColor}
                 onChangeComplete={ color => setPassColor(color.hex) }
                >


                </CirclePicker>

                </Paper>
               
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        
                <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>

                </Paper>
              </Grid>

              <Grid item xs={12}>
        
            <Button 
            style={{marginBottom: 5}}
            variant="outlined" 
            width="100%"
            onClick={() => {
                //console.log("button pressed")
                //alert('clicked');
                handleClickOpen();
           
              }}
           >
         Create Room
      </Button>


              </Grid>

              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to create a pass?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are create a hall pass for {passName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}