import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import Badge from '@mui/material/Badge';
import ReportIcon from '@mui/icons-material/BarChart';
import TeacherViewIcon from '@mui/icons-material/Face';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Cards from 'react-credit-cards-2';
import Link from '@mui/material/Link';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import FolderIcon from '@mui/icons-material/Folder';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridToolbar
} from '@mui/x-data-grid';
import {   GridActionsCellItem,
    GridAddIcon,
    GridDeleteIcon,
  } from "@mui/x-data-grid";
import Avatar from '@mui/material/Avatar';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Icon from '@mui/material/Icon';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import {QRCodeSVG} from 'qrcode.react';
import Stack from '@mui/material/Stack';

const MatEdit = ({ index }) => {

 


    return  <Icon baseClassName="fas" className="fa-plus-circle" color="primary" />
};


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getbridgepay.com/">
        BridgePay
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Pass() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [locArray,setLocArray] = useState([]);
  const [rowId,setRowId] = useState();
  const [isPass,setIsPass] = useState(true);
  const [ccNo,setCcNo] = useState();
  const [ccMonth,setCcMonth] = useState();
  const [ccYear,setCcYear] = useState();
  const [ccSec,setCcSec] = useState();
  const [rows, setRows] = React.useState([]);
  const [secCode, setSecCode] = React.useState();
  const [folderName,setFolderName] = useState();
  const [folderColor,setFolderColor] = useState();
  const [alignment, setAlignment] = React.useState('web');
  const [didSelect, setDidSelect] = React.useState(false);
  const [user,setUser] = useState(reactLocalStorage.get('userName'))
  const [profileAdmin,setProfileAdmin] = useState(reactLocalStorage.get('permissionFlag'))
  const [state, setState] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });
  const [searchParams] = useSearchParams();
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const { pathname } = useLocation();


  useEffect(() => {

      
    console.log("==========================")
    var compName = reactLocalStorage.get('companyName');
    var loginUuid = reactLocalStorage.get('loginuuid');

    console.log("==========================")

    getCustomerId();

    getPasses(loginUuid);



  }, [pathname]);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    
    setState((prev) => ({ ...prev, [name]: value }));
  }

  const handleSecCode = (evt) => {

    setSecCode(evt.target)

  }

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));

  }
  




  const goToMenu = () => navigate('/dashboard',true)
  const goToAccounts = () => navigate('/student',true)
  const goToReport = () => navigate('/reportdates',true)
  const goToTeacher = () => navigate('/teacher',true)
  const goToInbox = () => navigate('/inbox',true)
  const goToHall = () => navigate('/hall',true)

  const handleOnCellClick = (params) => {


    if(params.field === 'pasS_COLOR'){

      console.log("Here")

      if (profileAdmin == 'Super-admin') {

        if (params.row.foldeR_FLAG == "Y") {
          goToEditFolder(params.row.foldeR_ID)
        } else {
          console.log(params.row.id)
          goToEdit(params.row.pasS_ID)
        }

      }

    
      
    }




  };

  const handleEditClick = () => {
    // some action
}

const handleSaveClick = () => {
  // some action
}

const handleCancelClick = () => {
  // some action
}
  const goToEdit=(pId)=>{
    navigate('/editpass',{state:{id:pId}});
    }

    const goToEditFolder=(pId)=>{
      navigate('/editfolder',{state:{id:pId}});
      }

  const goToDelete=(pId)=>{
      
      
    }
  
    const columns = [

      { field: 'cardName', headerName: 'Card in profile', width: 200,flex: 1 },
      { field: 'cardLast4', headerName: 'Last 4', width: 200, flex: 1},
      { field: 'dateAdded', headerName: 'Added', width: 200, flex: 1},
        /*{
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
  
          getActions: (params ) => {

    
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.row.id)}
                color="inherit"
              />
            ];
          },
        },*/
      ];
  
    

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {

    if (state.number.length > 14 && state.name.length > 0 && state.expiry.length == 4 && secCode.length > 0) {
      setAlert(true);
    }
  

    
  };

  const goToPass = () => {

    navigate('/addpass')

  }

  const goToFolder = () => {

    navigate('/addfolder')

  }

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => locArray.find((row) => row.id === id));
    console.log(selectedRowsData);
    console.log("Here is the id: " + selectedRowsData.id)

    setDidSelect(!didSelect);
    
   
  };


  const notify = () => toast("Your card was added successfully to profile");

  const doUpload = () => {

    setAlert(false);


      addCard();



 

   

  };


  const postPass = (compName,pName,pImage,pColor,timeNeeded,dateCreated,userName,capacity,locationId) => {

    setLoading(true);

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

    console.log("COMPANY_NAME " + compName)
    console.log("PASS_NAME " + pName)
    console.log("PASS_IMAGE " + pImage)
    console.log("PASS_COLOR " + pColor)
    console.log("TIME_NEEDED " + timeNeeded)
    console.log("DATE_CREATED " + dateCreated)
    console.log("USERNAME " + userName)
    console.log("CAPACITY " + capacity)
    console.log("LOCATION_ID " + locationId)

var apiUrl = reactLocalStorage.get('apiUrl');
axios.post(apiUrl + '/PassMaints',{ 
       COMPANY_NAME: compName,
       PASS_NAME: pName,
       PASS_IMAGE: pImage,
       PASS_COLOR: pColor,
       TIME_NEEDED: timeNeeded,
       DATE_CREATED: dateCreated,
       USERNAME: userName,
       CAPACITY: capacity,
       LOCATION_ID: locationId
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setPassName('')

      notify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }


  const getPasses = (uuid) => {
    
    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/Mobiles/GetPlans/' + uuid, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {

                   var tempArray = [];
                   setLocArray([])

                   for (var i = 0; i < response.data.length; i++) {

                    tempArray.push({"label":response.data[i].productName,"id":response.data[i].productId})

                }

                if (tempArray) {

                    setLocArray(tempArray)

                }
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

 const getCustomerId = (uuid) => {
    
      var token = reactLocalStorage.get('jwtToken');
      var userName = reactLocalStorage.get('userName');
      var loginUuid = reactLocalStorage.get('loginUuid');
  
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.get(apiUrl + '/StripeCustomers/GetCustomer/' + loginUuid + "/" + userName, auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error)
      
                } else {

                  console.log('response', response.data)

             
                  if (response.data.customeR_ID) {

                    getCards(response.data.customeR_ID)
                  }
  
             
                    
                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
      
        });
      
      }

  
  const getCards = (customerId) => {
    
        var token = reactLocalStorage.get('jwtToken');
        var userName = reactLocalStorage.get('userName');
        var loginUuid = reactLocalStorage.get('loginUuid');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/StripeCards/' +  customerId + "/" + loginUuid, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
  
                    console.log('response', response.data)
           
                    var tempArray = [];

                    var dateAdded = moment(response.data.datE_ADDED).tz("America/New_York").format("YYYY-MM-DD")
                     tempArray.push({"id":response.data.customeR_ID,"cardName":response.data.carD_BRAND,"cardLast4":response.data.carD_LAST_4,"dateAdded":dateAdded})
 
                 
 
                 if (tempArray) {

                    console.log(tempArray)
 
                     setRows(tempArray)
 
                 }
  
                
               
                      
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }


    const addCard = () => {

      setLoading(true);
    
      var token = reactLocalStorage.get('jwtToken');
      var userName = reactLocalStorage.get('userName');
      var loginUuid = reactLocalStorage.get('loginUuid');
  
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };

        var ccMonth = state.expiry.substring(0,2);
        var ccYear = '20' + state.expiry.substring(state.expiry.length - 2);

        console.log(state.name)
        console.log(userName)
        console.log(loginUuid)
        console.log(state.number)
        console.log(state.expiry)
        console.log(ccMonth)
        console.log(ccYear)
        console.log(state.csv)
  
        var apiUrl = reactLocalStorage.get('apiUrl');

        console.log(apiUrl + '/StripeCustomers/PlatformCustomer/' + "StripeCustomer" + "/" + state.name + "/" + userName + "/" + loginUuid + "/" + state.number + "/" + ccMonth + "/" + ccYear + "/" + secCode)

        axios.get(apiUrl + '/StripeCustomers/PlatformCustomer/' + "StripeCustomer" + "/" + state.name + "/" + userName + "/" + loginUuid + "/" + state.number + "/" + ccMonth + "/" + ccYear + "/" + secCode, auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error)
                    setLoading(false);
      
                } else {
  
                  setLoading(false);
                  notify();
                  getCustomerId();
                  setState({
                    number: '',
                    expiry: '',
                    cvc: '',
                    name: '',
                    focus: '',
                  })
                  setSecCode();
  
                  
  
          
      
      
                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
            setLoading(false);
      
        });
      
      }




  const putPass = (id,activeFlag) => {

      setLoading(true);
  
      var token = reactLocalStorage.get('jwtToken');
  
  
     const auth = {
        headers: {Authorization: "Bearer " + token} 
      };
  
      console.log("ID " + id)
      console.log("ACTIVE_FLAG " + activeFlag)
  
  var apiUrl = reactLocalStorage.get('apiUrl');
  axios.put(apiUrl + '/PassMaints/RemovePass/' + id,{ 
         ACTIVE_FLAG: activeFlag
      },auth).then(response => {
  
  
      if (response.data.error) {
  
        setLoading(false);
  
      } else {
  
       
  
        console.log("==========================")
        console.log('success', response.data)
        setLoading(false);
        var compName = reactLocalStorage.get('companyName');
        notify();
        getPasses(compName);
        console.log("==========================")
    
       
    
  
        
  
  
        
  
      }
  
     }).catch(error => {
  
        setLoading(false);
        console.log(error.response)
  
      });
  
   
    }


const removeFolder = (id,folderName,color,activeFlag) => {

      setLoading(true);
  
      var token = reactLocalStorage.get('jwtToken');
      var companyName = reactLocalStorage.get('companyName');
      var dateAdded = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
  
     const auth = {
        headers: {Authorization: "Bearer " + token} 
      };
  
      console.log("ID " + id)
      console.log("ACTIVE_FLAG " + activeFlag)
  
  var apiUrl = reactLocalStorage.get('apiUrl');
  axios.put(apiUrl + '/Folders/' + id,{ 
         ID: id,
         COMPANY_NAME: companyName,
         FOLDER_NAME: folderName,
         DATE_ADDED: dateAdded,
         COLOR: color,
         ACTIVE_FLAG: activeFlag
      },auth).then(response => {
  
  
      if (response.data.error) {
  
        setLoading(false);
  
      } else {
  
       
  
        console.log("==========================")
        console.log('success', response.data)
        setLoading(false);
        var compName = reactLocalStorage.get('companyName');
        notify();
        getPasses(compName);
        console.log("==========================")
    
       
    
  
        
  
  
        
  
      }
  
     }).catch(error => {
  
        setLoading(false);
        console.log(error.response)
  
      });
  
   
    }
  
  
  const navigate = useNavigate();
  const goToLogout = () => navigate('/');

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };
  

  

  const handleClose = () => {
    setAlert(false);
  };



  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }



  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{width: 400,height: 300,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Default Time: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}
        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             

       Add Payment

            </Typography>
      
            <Typography variant="body2">

          {reactLocalStorage.get('userName')}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
       <Stack direction="row" spacing={{ xs: 1, sm: 2 }}  justifyContent="flex-end"
  alignItems="baseline" >

<img style={{width: 100}} alt="logo" src="https://bridgepaypics.s3.us-east-2.amazonaws.com/bridgepay_logo_text.png" />

            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
          
            </IconButton>

    </Stack>
          </Toolbar>
          <Divider />
          <List component="nav">
     
     <ListItemButton onClick={goToMenu}>
 <ListItemIcon>
   <DashboardIcon />
 </ListItemIcon>
 <ListItemText primary="Dashboard" />
</ListItemButton>
<ListItemButton >
 <ListItemIcon>
   <CreditCardIcon />
 </ListItemIcon>
 <ListItemText primary="Card" />

 </ListItemButton>




<ListItemButton onClick={goToAccounts}>
 <ListItemIcon>
   <AutoModeIcon />
 </ListItemIcon>
 <ListItemText primary="Auto Pay" />
</ListItemButton>



<ListItemButton>
 <ListItemIcon>
   <AllInboxIcon />
 </ListItemIcon>
 <ListItemText primary="Forms" />
</ListItemButton>

<ListItemButton>
 <ListItemIcon>
   <AccountBalanceWalletIcon />
 </ListItemIcon>
 <ListItemText primary="History" />

 </ListItemButton>
       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>


<ListItemButton>
<ListItemIcon>
<TeacherViewIcon />
</ListItemIcon>
<ListItemText primary="My Student(s)" />
</ListItemButton>




<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>




     </List>



        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}



    
   
 


            <Grid container spacing={3}>
              {/* Chart */}
             
       
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>


   


         <Grid style={{marginLeft: 5}}  spacing={2}>

      

        <Stack>

        <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      />

   
        </Stack>

        <Stack>
     

<TextField
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
                required
                style={{marginLeft:5,marginTop: 10,width: 400}}
                value={state.name}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />

        </Stack>


<Stack>

<TextField
          type="number"
          name="number"
          style={{marginLeft:5,marginRight: 5,marginTop: 10,width: 400}}
          placeholder="Card Number"
          value={state.number}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />

</Stack>


<Stack direction="row" spacing={1} >
<TextField
          type="number"
          name="expiry"
          style={{marginLeft:5,marginRight: 5,marginTop: 10}}
          placeholder="expiry"
          value={state.expiry}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />

<TextField
          type="number"
          name="cvc"
          style={{marginLeft:5,marginRight: 5,marginTop: 10}}
          placeholder="cvc"
          value={secCode}
          onChange={(event) => setSecCode(event.target.value)}
          onFocus={handleInputFocus}
        />

</Stack>




<Button onClick={handleClickOpen} style={{marginTop: 5}} >
Add Card
</Button>
<Typography style={{fontSize: 14}} color="red">
         *IMPORTANT - to replace card just add another in the form above and it will replace the card in your profile
        </Typography>

    </Grid>

                </Paper>
              </Grid>

              <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>


              <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />
       


       </Paper>
              </Grid>

              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add card to your profile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to add this card to your profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}