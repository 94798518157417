import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import IconButton from '@mui/material/IconButton';
import ReportIcon from '@mui/icons-material/BarChart';
import ClockIcon from '@mui/icons-material/AccessTime';
import UndoIcon from '@mui/icons-material/Undo';
import TeacherViewIcon from '@mui/icons-material/Face';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import RedoIcon from '@mui/icons-material/HighlightOff';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import TeacherIcon from '@mui/icons-material/School';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import {reactLocalStorage} from 'reactjs-localstorage';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import { Importer, ImporterField } from 'react-csv-importer';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Chip from '@mui/material/Chip';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";

// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PassCreate() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [approveFlag,setApproveFlag] = useState('N');
  const [timeNeeded,setTimeNeeded] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [searchParams] = useSearchParams();
  const [logoUrl,setLogoUrl] = useState();
  const { handle } = useParams()
  const [locArray, setLocArray] = React.useState([])
  const [teacherArray, setTeacherArray] = React.useState([])
  const [teacherId, setTeacherId] = React.useState()
  const [teacherSelected, setTeacherSelected] = React.useState()
  const [roomArray, setRoomArray] = React.useState([])
  const [passId, setPassId] = React.useState()
  const [passSelected, setPassSelected] = React.useState()
  const [dateTimeSelected, setDateTimeSelected] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"))
  const [passArray, setPassArray] = React.useState([])
  const [sId, setSId] = React.useState()
  const [popup, setPopup] = React.useState(false);
  const [groupName, setGroupName] = React.useState([]);
  const [groupArray, setGroupArray] = React.useState([]);
  const [selectGroup, setSelectGroup] = React.useState(true);
  const [spacer, setSpacer] =  useState('  ');
  const [passImage,setPassImage] = useState('');
  const [travelType, setTravelType] = React.useState("Round-trip")
  const [roomNo, setRoomNo] = React.useState("")
  const [folderId, setFolderId] = React.useState("")
  const [passOpen, setPassOpen] = React.useState(true)
  const steps = ['Select Students', 'Current Room', 'To Where?','When?','Create Pass'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [render, setRender] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(true);
  const [notes, setNotes] = React.useState('');


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    //handleNext();

    setAlert(true);

  };

  const getFolderPasses = (compName,folderId) => {
    
    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/PassMaints/GetFolder/' + compName + "/" + folderId, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);
    
            
                
                  var tempArray = [];
                  setRoomArray([])

                  for (var i = 0; i < response.data.length; i++) {

                   console.log("===================")
                   console.log(response.data[i].pasS_ID)
                   tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].id,"color": response.data[i].pasS_COLOR,"folderFlag": response.data[i].foldeR_FLAG ,"approve": response.data[i].approvE_FLAG, "folderId": response.data[i].foldeR_ID})

               }

               if (tempArray) {

                setRoomArray(tempArray)

               }

              
                
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleOpen = () => {
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  const closeDialog = () => {
    setAlert(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };



  useEffect(() => {

      
    console.log("==========================")
    var logo = reactLocalStorage.get('profilePic');
    var companyName = reactLocalStorage.get('companyName');
    console.log(logo)
    setLogoUrl(logo);
    console.log("==========================")

    getStudents(companyName);
    getTeachers(companyName)
    getPasses(companyName)





  }, []);


  useEffect(() => {

      
    console.log("==========================")
    console.log(activeStep)
    if (activeStep + 1 == 1 || activeStep == 0) {

        console.log("active step is zero")
        setTeacherId()
        setTeacherSelected()
        setPassId()
        setPassSelected()
        setGroupArray([])
        setGroupName();
        setSId()
        setDropdown(true)
        setRender(!render);

    }
    if (activeStep + 1 == 4) {

      setDateTimeSelected(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS") ) ;
    }
    if (activeStep + 1 == 5) {

        console.log("get pass")
        console.log(passId);

        getPass(passId)

    }
    console.log("==========================")





  }, [activeStep]);


  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);
  };



  const checkPass = (compName,studentId) => {

    var companyName = reactLocalStorage.get('companyName');
    var userName = reactLocalStorage.get('userName');

    var dateCreated = moment(dateTimeSelected).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

    if (currentTime === true) {

      dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

    } 

    var editTime = moment(dateCreated)
        .add(timeNeeded, 'minutes')
    
    var passTime = moment(editTime).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

    var token = reactLocalStorage.get('jwtToken');

    const auth = {
        headers: {
            Authorization: "Bearer " + token,
            
        }
    };
    var apiUrl = reactLocalStorage.get('apiUrl');
    axios.get(apiUrl + "/Passes/CheckOpen/" + compName + "/" + studentId, auth).then(response => {
  
            if (response.data.error) {
              console.log("error")
              console.log(response.data.error);
   

            } else {

                if (response.data.length > 0) {


                  passInSession(response.data[0].studenT_NAME);
                  
               
                } else {

                  postPass(companyName,
                    studentId,
                    passName,
                    timeNeeded,
                    dateCreated,
                    passTime,
                    userName,
                    "Web",
                    passColor,
                    travelType,
                    "visitingUsername",
                    passName,
                    dateCreated,
                    "N",
                    userName,
                    "Y",
                    passId,
                    passCapacity,
                    approveFlag,
                    teacherSelected,
                    notes)
                  
                }
                
  
            }
  
    }).catch(error => {
      console.log("catch in")
      console.log(error);

  
    });
  
  }


  const getStudents = (compName) => {
    
    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/PassMaints/GetStudents/' + compName + '/' + userName + '/' + 'N', auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);

                   var tempArray = [];
                   setLocArray([])

                   for (var i = 0; i < response.data.length; i++) {

                    console.log("===================")
                    console.log(response.data[i].studenT_GROUP)
                    tempArray.push({"label":response.data[i].studenT_GROUP,"id":response.data[i].studenT_ID,"groupType":response.data[i].grouP_TYPE})

                }

                if (tempArray) {

                    setLocArray(tempArray)

                }
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    
  const getTeachers = (compName) => {
    

    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/PassMaints/GetTeachers/' + compName, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);
    
            
                

                   var tempArray = [];
                   setTeacherArray([])

                   for (var i = 0; i < response.data.length; i++) {

                    console.log("===================")
                    console.log(response.data[i].pasS_NAME)
                    tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].pasS_ID,"color": response.data[i].color,"passType": response.data[i].pasS_TYPE})

                }

                if (tempArray) {

                    setTeacherArray(tempArray)

                }
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

    const getGroup = (groupId,dateCreated,passTime,userName,companyName) => {


        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/GroupDtls/GetDetail/' + groupId, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                      console.log(response.data);
        
            
    
                       for (var i = 0; i < response.data.length; i++) {
    
                        console.log("===================")
                        console.log(response.data[i].grouP_ID)

                        postPass(companyName,
                            response.data[i].studenT_ID,
                            passName,
                            timeNeeded,
                            dateCreated,
                            passTime,
                            userName,
                            "Web",
                            passColor,
                            travelType,
                            "visitingUsername",
                            "visitingName",
                            dateCreated,
                            "N",
                            userName,
                            "Y",
                            passId,
                            passCapacity,
                            approveFlag,
                            teacherSelected,
                            notes)
                     
    
                    }
    
              
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }
    

  const getPasses = (compName) => {
    

        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/GetList/' + compName, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                      console.log(response.data);
        
                
                    
    
                       var tempArray = [];
                       setPassArray([])
    
                       for (var i = 0; i < response.data.length; i++) {
    
                        tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].pasS_ID,"color": response.data[i].pasS_COLOR,"folderFlag": response.data[i].foldeR_FLAG ,"approve": response.data[i].approvE_FLAG, "folderId": response.data[i].foldeR_ID})
    
                    }
    
                    if (tempArray) {
    
                        setPassArray(tempArray)
    
                    }
                   
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }
        
 const getPass = (id) => {

            var token = reactLocalStorage.get('jwtToken');
        
            const auth = {
                  headers: {
                      Authorization: "Bearer " + token
                  }
              };
              var apiUrl = reactLocalStorage.get('apiUrl');
              axios.get(apiUrl + '/PassMaints/' + id, auth).then(response => {
            
                      if (response.data.error) {
            
                          console.log('error', response.data.error)
            
                      } else {
            
        
                           console.log(response.data.pasS_NAME)
        
                           if (response.data) {
        
        
                                setPassName(response.data.pasS_NAME)
                                setPassColor(response.data.pasS_COLOR)
                                setPassCapacity(response.data.capacity)
                                setTimeNeeded(response.data.timE_NEEDED)
                                setPassImage(response.data.pasS_IMAGE)
                                setTravelType(response.data.rounD_TRIP)

                                if (response.data.rounD_TRIP == "Y" || response.data.rounD_TRIP == null) {

                                  setTravelType("Round-trip")

                                } 
                                if (response.data.rounD_TRIP == "N") {

                                  setTravelType("One-way")

                                }
                                setRoomNo(response.data.rooM_NO)
                                setFolderId(response.data.foldeR_ID)
                                setApproveFlag(response.data.approvE_FLAG)
                           }
        
        
        
                   
                          
            
            
                      }
            
                  
            
              }).catch(error => {
            
                  console.log(error.response);
            
              });
            
}
            


const navigate = useNavigate();

const goToRooms = () => navigate('/room',true)

const goToInbox = () => navigate('/inbox',true)

const goToPasses = () => navigate('/pass',true)

const goToHall = () => navigate('/hall',true)

const goToAdd = () => navigate('/addpass',true)

const goToFolder = () => navigate('/addfolder',true)

const goToAccounts = () => navigate('/student',true)

const goToReport = () => navigate('/reportdates',true)

const goToTeacher = () => navigate('/teacher',true)

const goToMenu = () => navigate('/dashboard',true)


const postPass = (companyName,
    studentId,
    passName,
    timeNeeded,
    dateCreated,
    passTime,
    userName,
    deviceName,
    passColor,
    roundTrip,
    visitingUsername,
    visitingName,
    receivedDate,
    receivedFlag,
    issuedBy,
    activateFlag,
    hallPassId,
    capacity,
    approveFlag,
    fromPass,
    notes) => {

    setLoading(true);
 
    var token = reactLocalStorage.get('jwtToken');
 
    const auth = {
       headers: {Authorization: "Bearer " + token} 
     };
 
     console.log("username " + userName)

     if (roundTrip == "One-way") {

        roundTrip = "N";

     } else {

        roundTrip = "Y";

     }

 
    var apiUrl = reactLocalStorage.get('apiUrl');
  
 
 axios.post(apiUrl + '/Passes',{
     COMPANY_NAME:companyName,
     STUDENT_ID: studentId,
     PASS_NAME: passName,
     TIME_NEEDED: timeNeeded,
     DATE_CREATED: dateCreated,
     PASS_TIME: passTime,
     USERNAME: userName,
     DEVICE_NAME: deviceName,
     PASS_COLOR: passColor,
     ROUND_TRIP: roundTrip,
     VISITING_USERNAME: visitingUsername,
     VISITING_NAME: visitingName,
     RECEIVED_DATE: receivedDate,
     RECEIVED_FLAG: receivedFlag,
     ISSUED_BY: issuedBy,
     ACTIVATE_FLAG: activateFlag,
     HALL_PASS_ID: hallPassId,
     CAPACITY: capacity,
     APPROVE_FLAG: approveFlag,
     FROM_PASS: fromPass,
     NEEDS_APPROVAL: approveFlag,
     NEEDS_APPROVAL_BY: userName,
     NOTES: notes
 },auth).then(response => {
 
 
     if (response.data.error) {
 
       setLoading(false);
 
     } else {
 
 
       setLoading(false);

       notify(response.data.studenT_NAME)

       setActiveStep(0)

       
 
 
       
 
     }
 
    }).catch(error => {
 
       setLoading(false);
       console.log(error.response)
 
     });
 
  
   }
 


const uploadDtl = (userName,studentId) => {

    setLoading(true);
 
    var token = reactLocalStorage.get('jwtToken');
 
 
    const auth = {
       headers: {Authorization: "Bearer " + token} 
     };
 
     console.log("username " + userName)

 
 var apiUrl = reactLocalStorage.get('apiUrl');
 var comnpanyName = reactLocalStorage.get('companyName');
 var dateAdded = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
 
 axios.post(apiUrl + '/GroupDtls',{
     GROUP_ID:sId,
     USERNAME: userName,
     STUDENT_ID: studentId,
     COMPANY_NAME: comnpanyName,
     DATE_ADDED: dateAdded
 },auth).then(response => {
 
 
     if (response.data.error) {
 
       setLoading(false);
 
     } else {
 
       console.log('success', response.data)
 
       setLoading(false);
 
 
       
 
     }
 
    }).catch(error => {
 
       setLoading(false);
       console.log(error.response)
 
     });
 
  
   }

 
const uploadHdr = () => {

    setLoading(true);
 
    var token = reactLocalStorage.get('jwtToken');
 
 
    const auth = {
       headers: {Authorization: "Bearer " + token} 
     };
 
 
 var apiUrl = reactLocalStorage.get('apiUrl');
 var comnpanyName = reactLocalStorage.get('companyName');
 var dateAdded = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
 
 axios.post(apiUrl + '/GroupHdrs',{
     GROUP_NAME:groupName,
     COMPANY_NAME: comnpanyName,
     DATE_ADDED: dateAdded,
     ACTIVE_FLAG: 'Y'
 },auth).then(response => {
 
 
     if (response.data.error) {
 
       setLoading(false);
 
     } else {
 
       console.log('success', response.data)
 
       setLoading(false);
       setPopup(false);
       getStudents(comnpanyName);
 
 
       
 
     }
 
    }).catch(error => {
 
       setLoading(false);
       console.log(error.response)
 
     });
 
  
   }
 



  const notify = (studentName) => 
  
  toast("Pass was created successfully for " + studentName);

  const passInSession = (studentName) => 
  
  toast("You cannot create pass for " + studentName + " because there is a pass in session.");


  const doUpload = () => {

    var companyName = reactLocalStorage.get('companyName');
    var userName = reactLocalStorage.get('userName');

    setAlert(false);

    var dateCreated = moment(dateTimeSelected).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
        console.log(dateCreated)
        console.log(timeNeeded)
        var editTime = moment(dateCreated)
        .add(timeNeeded, 'minutes')
    
    var passTime = moment(editTime).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

    for (var i = 0; i < groupArray.length; i++) {

        checkPass(companyName,groupArray[i].id);

        if (groupArray[i].groupType == "G") {

            console.log("do call for group detail")


            getGroup(groupArray[i].id,dateCreated,passTime,userName,companyName);

        }

    }




    //postPass(companyName,passName,value,passColor,passTime,dateCreated,name,passCapacity,"0")

  };


  const handleSelect = (event) => {
    setTravelType(event.target.value);
  };


  const handleChange = (event, newValue) => {
    setTimeNeeded(newValue);
  };

 
  const goToLogout = () => navigate('/');

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setTimeNeeded(newValue);
  };

  const studentChange = (value) => {

    if (value != '' && value != undefined && value != null) {

        setSId(value.id)
        setGroupName(value.label)
        setGroupArray(value)

        console.log("======================================")
        console.log(value)
        console.log(value.length)
        console.log("======================================")
    }

  


  }

  const teacherChange = (value) => {

    if (value != '' && value != undefined && value != null) {

        setTeacherId(value.id)
        setTeacherSelected(value.label)

    }

  


  }

  const passChange = (value) => {

    if (value != '' && value != undefined && value != null) {

        console.log(value.id)
        setPassId(value.id)
        setPassSelected(value.label)

    }

  


  }






  const changeGroupName = (val) => {

    console.log(val);

    setGroupName(val);
    
  
  }


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Create Pass
            </Typography>
      
            <Typography variant="body2">
          {name}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />

      
          <List component="nav">
     
     <ListItemButton onClick={goToMenu} >
 <ListItemIcon>
   <DashboardIcon />
 </ListItemIcon>
 <ListItemText primary="Dashboard" />
</ListItemButton>
<ListItemButton onClick={goToPasses}>
 <ListItemIcon>
   <BarChartIcon />
 </ListItemIcon>
 <ListItemText primary="Rooms" />

 </ListItemButton>


<ListItemButton onClick={goToAccounts}>
 <ListItemIcon>
   <PeopleIcon />
 </ListItemIcon>
 <ListItemText primary="Accounts" />
</ListItemButton>






<ListItemButton onClick={goToReport}>
 <ListItemIcon>
   <ReportIcon />
 </ListItemIcon>
 <ListItemText primary="Reports" />
</ListItemButton>
       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>


<ListItemButton onClick={goToTeacher}>
<ListItemIcon>
<TeacherViewIcon />
</ListItemIcon>
<ListItemText primary="Teacher View" />
</ListItemButton>



<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>




     </List>




        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container style={{backgroundColor: 'white'}}maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            



        
  
          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}



<Box sx={{ width: '100%' }}>
      <Stepper  activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit">
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>


            <Autocomplete
            key={render} 
                multiple
            id="combo-box-demo"
            onChange={(event, value) => {
    
            studentChange(value)
  

            }} 
        options={locArray}
        getOptionLabel={(option) => option.label}
        style={{marginBottom: 20}}
        renderOption={(props, option) => (
        <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

        {option.groupType == "G" ? (
      
<>
      <GroupIcon/>
 </>
         ) : (
           <>
              <SchoolIcon/>
           </>
         )}
     

    <Typography style={{marginLeft: 5}}>{option.label} </Typography>



    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Choose a student or group"
    />
                )}
            />




        {activeStep + 1 ==  2 ? (
                <>
                <Divider variant="middle" />
                <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
            Select your current room
            </Typography>

            <Autocomplete
            key={render}
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => {
    
            teacherChange(value)
  

            }} 
        options={teacherArray}
        style={{marginBottom: 20}}
        renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

{option.passType == "P" ? (
      
      <>
          
          <Box
          style={{
            backgroundColor: option.color,
            width: 200,
            padding: 5,
            borderRadius: 10,
            color: 'white'

          }}
        >
<Typography>{option.label}</Typography>
            </Box>
     

    



       </>
               ) : (
                 <>

<Avatar sx={{ bgcolor: option.color, marginRight: 5 }}>
  <TeacherIcon />
</Avatar>
                  <Typography> {option.label}</Typography>
                 </>
               )}



    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select where your student(s) are"
    />
  )}
/>

</>
                ) : (
<>
</>
                )}


{activeStep + 1 ==  3 ? (
                <>
                <Divider variant="middle" />
            <Typography style={{fontSize: 16}} sx={{ mt: 2, mb: 1, py: 1 }}>
           {teacherSelected} → {passSelected}
            </Typography>


  



{dropdown ? (
      

      <Autocomplete
      key={render}
      disablePortal
      id="combo-box-demo"
      onChange={(event, value) => {

      passChange(value)
      
      if (value != '' && value != undefined && value != null) {

        if (value.folderFlag == "Y") {

          var companyName = reactLocalStorage.get('companyName');
          getFolderPasses(companyName,value.folderId)
          setModalOpen(true);

        }
      }



      }} 
  options={passArray}
  style={{marginBottom: 20}}
  renderOption={(props, option) => (
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

    <Box
    style={{
      backgroundColor: option.color,
      width: 200,
      padding: 5,
      borderRadius: 10,
      color: 'white'

    }}
  >

{option.folderFlag == "Y" ? (
<>



<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} >

<Typography>{option.label}</Typography>
<ArrowForwardIcon/>



</Stack>


</>          

  ) : (
    <>
   <Typography>{option.label}</Typography>
    </>
  )}
     
      </Box>










</Box>










)}
renderInput={(params) => (
<TextField
{...params}
label="Where to?"
/>
)}
/>


        ) : (
          <>
            

       <Stack direction="row" alignItems="center"  spacing={1} >

      <Typography style={{fontSize: 20}}>{passSelected}</Typography>

      <Button variant="ghost" onClick={() => {

          setDropdown(true);
          setPassSelected();
          setPassId();
        }

      }startIcon={<RedoIcon size="large" />}>
      </Button>



      </Stack>

          </>
        )}




<Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{marginBottom: 10}} id="modal-modal-title" variant="h6" component="h2">
            Select Room
          </Typography>
        
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => {

            console.log(value.id)
    
            passChange(value)

            setModalOpen(false);

            setDropdown(false);
  

            }} 
        options={roomArray}
        style={{marginBottom: 20}}
        renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

          <Box
          style={{
            backgroundColor: option.color,
            width: 200,
            padding: 5,
            borderRadius: 10,
            color: 'white'

          }}
        >

            <Typography>{option.label}</Typography>
     
            </Box>
     

    


     




    </Box>










  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Where to?"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password', // disable autocomplete and autofill
      }}
    />
  )}
/>

<Button onClick={handleModalClose}>Cancel</Button>

        </Box>

      </Modal>


</>
                ) : (
<>
</>
                )}

{activeStep + 1 ==  4 ? (
<>

{currentTime ? (
<>

<Stack direction="row" style={{marginBottom: 10}} alignItems="center" spacing={1} >

<ClockIcon />  <Typography>
            Right now
          </Typography>


</Stack>

<Button variant="outlined" onClick={() => setCurrentTime(false)}>Change Date or Time</Button>



</> ) :(
<>

<Stack direction="row" style={{marginBottom: 5}} alignItems="center" spacing={1} >

<UndoIcon />  <Button onClick={() => setCurrentTime(true)}>
            Current Time
          </Button>


</Stack>

<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <MobileDateTimePicker label="Choose date and time"
          value={dayjs(dateTimeSelected)}
          onChange={(newValue) => {


            //var myDate = JSON.stringify(newValue.$d)

            //console.log(myDate);

            var dateFormatted = new Date(dayjs(newValue))
            var dateForSelected = moment(dateFormatted).tz("America/New_York").format("MM/DD/YYYY hh:mm A")

           
            console.log(moment(dateForSelected).tz("America/New_York").format("MM/DD/YYYY hh:mm A"))

            setDateTimeSelected(dateForSelected)

          
          }
        }
           
       
        />
      </DemoContainer>
    </LocalizationProvider>
    
    
</>
)}
    

    </>
) : (

<>

</>

)}


{activeStep + 1 ==  5 ? (

<>

<TextField
style={{ marginBottom: 10, margin: 0, width: 400 }}
label="Add Note (optional)"
placeholder="Ex. Student has excused tardiness"
helperText="(300 character maximum)"
inputProps={{ maxLength: 300 }}
multiline
onChange={(event) => setNotes(event.target.value)}
rows="4"
autoFocus={true}
variant="outlined"/>

{currentTime ? (
<>

<Stack direction="row" style={{marginBottom: 5}} alignItems="center" spacing={1} >

<ClockIcon />  <Typography>
            Right now
          </Typography>


</Stack>


</> ) :(
<>

        <Stack direction="row" style={{marginBottom: 5}} alignItems="center" spacing={1} >

<ClockIcon />      <Typography style={{color: 'black'}}>
        To be created on: {dateTimeSelected}
        </Typography>


</Stack>

</>
)}


<Box sx={{ minWidth: 275 }}>
<CardContent style={{width: 400,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
        </Typography>
  
        {approveFlag == "Y" ? (
      

      <Typography style={{color: 'white'}}>
        **Requires approval**
      </Typography>
        ) : (
          <>
            
          </>
        )}
     
        <Chip style={{marginTop: 5, marginBottom: 5, backgroundColor: "white"}} label={teacherSelected + " → " + passSelected} size="small" />

        <Typography style={{fontSize: 16,marginBottom: 20}} color="white">

Time limit: {timeNeeded} minutes

<Slider
  size="small"
  defaultValue={timeNeeded}
  aria-label="Small"
  valueLabelDisplay="auto"
  onChange={handleChange}
/>


        </Typography>
        <Typography style={{fontSize: 16}} color="white">
         Capacity: {passCapacity}
        </Typography>

  
        <Select
    style={{marginTop: 10, marginBottom: 20,color:'white'}}
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={travelType}
    label="Travel Type"
    onChange={handleSelect}
  >
    <MenuItem value='Round-trip'>Round-trip</MenuItem>
    <MenuItem value={'One-way'}>One-way</MenuItem>
  </Select>


      </CardContent>
      <CardActions>

      </CardActions>
    </Box>

</>
  ) : (
<>
</>
                )}



            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />


              

              {activeStep + 1 ==  5 ? (
      

      <Button onClick={handleComplete}>
                 Create Pass
                  </Button>

        ) : (
          <>
        
          </>
        )}


{activeStep + 1 ==  1 && groupArray.length > 0 ? (

<Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
           

        ) : (
          <>
        
          </>
        )}

{activeStep + 1 ==  2 && teacherSelected ? (

<Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
           

        ) : (
          <>
        
          </>
        )}


{activeStep + 1 ==  3 && passSelected ? (

<Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
           

        ) : (
          <>
        
          </>
        )}

{activeStep + 1 ==  4 && dateTimeSelected ? (

<Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
           

        ) : (
          <>
        
          </>
        )}

                 
            
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>





    <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to create a pass?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are create a hall pass for {passName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}