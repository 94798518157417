import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import TeacherIcon from '@mui/icons-material/School';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import FolderIcon from '@mui/icons-material/Folder';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar,
    GridToolbarExport
  } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'; 
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Icon from '@mui/material/Icon';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import {QRCodeSVG} from 'qrcode.react';
import Stack from '@mui/material/Stack';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Countdown from 'react-countdown';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';

const MatEdit = ({ index }) => {

    const handleEditClick = () => {
        // some action
    }


    return  <Icon baseClassName="fas" className="fa-plus-circle" color="primary" />
};


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function StudentDash() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [locArray,setLocArray] = useState([]);
  const [rowId,setRowId] = useState();
  const [roomArray, setRoomArray] = React.useState([])
  const [passId, setPassId] = React.useState()
  const [passSelected, setPassSelected] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false);
  const [mOpen, setMOpen] = React.useState(false);
  const [dOpen, setDOpen] = React.useState(false);
  const [profilePic,setProfilePic] = useState();
  const [folderOpen, setFolderOpen] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(false);
  const [alignment, setAlignment] = React.useState('web');
  const [didSelect, setDidSelect] = React.useState(false);
  const [user,setUser] = useState(reactLocalStorage.get('userName'))
  const [searchParams] = useSearchParams();
  const [passArray, setPassArray] = React.useState([]);
  const [pieArray,setPieArray] = useState([]);
  const [lineArray,setLineArray] = useState([]);
  const [button1,setButton1] = useState("contained");
  const [button2,setButton2] = useState("outlined");
  const [downloadReport,setDownloadReport] = useState("ALL");
  const [dateSelected, setDateSelected] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD"));
  const [selectedId, setSelectedId] = React.useState()
  const [from, setFrom] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD"));
  const [to, setTo] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD"));

  const [reportArray, setReportArray] = React.useState([]);
  const [message, setMessage] = React.useState(null);

  const [studentArray,setStudentArray] = useState([]);
  const [sId,setSId] = useState(null);
  const [groupName,setGroupName] = useState(null);
  const [groupArray,setGroupArray] = useState([]);
  const [showAuto,setShowAuto] = useState(false);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const { pathname } = useLocation();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    flex: 1,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


  useEffect(() => {

    var compName = reactLocalStorage.get('companyName');
    var userName = reactLocalStorage.get('userName');
    var profile = reactLocalStorage.get('profilePic');

    setProfilePic(profile)

    var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
    var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");

    getEvents(compName,fDate,tDate,downloadReport)
    getPasses(compName)

    getLineChart(compName,fDate,tDate,downloadReport,userName);
    getPieChart(compName,fDate,tDate,downloadReport,userName);

    getStudents(compName);


  }, []);


  useEffect(() => {
    function updateEvents() {
      //logic to change color here
  
      console.log("==============================")
      console.log("update screen")
      var compName = reactLocalStorage.get('companyName');
      var userName = reactLocalStorage.get('userName');

      var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD")
      var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD")
      //console.log(dateForEvents)
      

      if (showAuto) {

        getEventsStudent(compName,fDate,tDate,downloadReport,sId)
        getLineStdnt(compName,fDate,tDate,downloadReport,userName,sId);
        getPieStdnt(compName,fDate,tDate,downloadReport,userName,sId);


      } else {

        getEvents(compName,fDate,tDate,downloadReport)
        getLineChart(compName,fDate,tDate,downloadReport,userName);
        getPieChart(compName,fDate,tDate,downloadReport,userName);

      }



      console.log("==============================")

    }
    let interval = setInterval(updateEvents, 3000)
  
    return (() => { //we return a "cleanup" function that will be called on unmount, since we've set an interval we also need to clear it later.
     clearInterval(interval)
    })
   }, [from,to,downloadReport,showAuto,sId])


  const goToMenu = () => navigate('/teacher',true)
  const goToAccounts = () => navigate('/student',true)
  const goToPasses = () => navigate('/pass',true)



    const getStudents = (compName) => {
    
        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/GetStudents/' + compName, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                      console.log(response.data);
    
                       var tempArray = [];
                       setStudentArray([])
    
                       for (var i = 0; i < response.data.length; i++) {
    
                        console.log("===================")
                        console.log(response.data[i].studenT_GROUP)
                        tempArray.push({"label":response.data[i].studenT_GROUP,"id":response.data[i].studenT_ID,"groupType":response.data[i].grouP_TYPE})
    
                    }
    
                    if (tempArray) {
    
                        setStudentArray(tempArray)
    
                    }
                   
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }

    const passChange = (value) => {

      if (value != '' && value != undefined && value != null) {
  
          console.log(value.id)
          setPassId(value.id)
          setPassSelected(value.label)
  
      }
  
    
  
  
    }

    const getFolderPasses = (compName,folderId) => {
    
        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/GetFolder/' + compName + "/" + folderId, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                      console.log(response.data);
        
                
                    
                      var tempArray = [];
                      setRoomArray([])
    
                      for (var i = 0; i < response.data.length; i++) {
    
                       console.log("===================")
                       console.log(response.data[i].pasS_ID)
                       tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].id,"color": response.data[i].pasS_COLOR,"folderFlag": response.data[i].foldeR_FLAG ,"approve": response.data[i].approvE_FLAG, "folderId": response.data[i].foldeR_ID})
    
                   }
    
                   if (tempArray) {
    
                    setRoomArray(tempArray)
    
                   }
    
                  
                    
                   
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }

 const checkDate = (inputDate) => {

      var showCounter = false

      var today = new Date();

      if (today.toDateString() == inputDate.toDateString()) {

        showCounter = true

        } else {

        showCounter = false
      }

      return showCounter;
  }

const isExpired = (end) => {

  var now = moment(new Date()),
  end = moment(end),
  minutes = end.diff(now, 'seconds');

  var expiredFlag = true;

  if (minutes < 0) {

    expiredFlag = true

  } else {

    expiredFlag = false

  }

  return expiredFlag;
}

const getPieChart = (compName,fromDate,toDate,permission,userName) => {

    var token = reactLocalStorage.get('jwtToken');
  
    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/PassMaints/GetPieChart/' + compName + "/" + fromDate + "/" + toDate + "/" + permission + "/" + userName, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
     
                  setPieArray(response.data)
         
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    
const getLineChart = (compName,fromDate,toDate,permission,userName) => {
  
      var token = reactLocalStorage.get('jwtToken');
    
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.get(apiUrl + '/PassMaints/GetLineChart/' + compName + "/" + fromDate + "/" + toDate + "/" + permission + "/" + userName , auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error)
      
                } else {
      
       
                    setLineArray(response.data)
                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
      
        });
      
      }

const getPieStdnt = (compName,fromDate,toDate,permission,userName,studentId) => {

        var token = reactLocalStorage.get('jwtToken');
      
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/StdtPieChart/' + compName + "/" + fromDate + "/" + toDate + "/" + permission + "/" + userName + "/" + studentId, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
                      setPieArray([])
        
                  } else {
        
    
                      setPieArray(response.data)
        
                  }
        
              
        
          }).catch(error => {

              setPieArray([])
              console.log(error.response);
        
          });
        
        }
        
const getLineStdnt = (compName,fromDate,toDate,permission,userName,studentId) => {
      
          var token = reactLocalStorage.get('jwtToken');
        
          const auth = {
                headers: {
                    Authorization: "Bearer " + token
                }
            };
            var apiUrl = reactLocalStorage.get('apiUrl');
            axios.get(apiUrl + '/PassMaints/StdtLineChart/' + compName + "/" + fromDate + "/" + toDate + "/" + permission + "/" + userName + "/" + studentId , auth).then(response => {
          
                    if (response.data.error) {
          
                        console.log('error', response.data.error)
                        setLineArray([])
          
                    } else {
          
                        console.log(response.data);
                        setLineArray(response.data)
                    }
          
                
          
            }).catch(error => {
                setLineArray([])
                console.log(error.response);
          
            });
          
          }
          

const expireLogic = (start,end,name,receivedFlag) => {

  

   var seconds = (end.getTime() - start.getTime()) / 1000;

   var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

   var dateNow = Date.now()

   var now = moment(new Date()),
   end = moment(end),
   minutes = end.diff(now, 'seconds');

   //var diffInMinutes = dateCreated.diff(end, 'minutes');
   //console.log("date diff: " + minutes)

   
   
   var myDate = Date.now() + minutes

   if (minutes < 0 ) {

    /*console.log("==================")
    console.log("expired")
    console.log(name + ' ' + seconds)
    console.log("==================")*/

   } else {

    /*console.log("==================")
    console.log("not expired")
    console.log(name + ' ' + seconds)
    console.log("==================")*/

    myDate = Date.now() + minutes * 1000

   }

   if (receivedFlag == "Y") {

    myDate = Date.now() 

   }

  


    return myDate;
}

const myRenderer = (hours,minutes,seconds,completed,passTime,timeNeeded) => {

    if (completed) {
      // Render a completed state
  
      console.log(" i am here in the completed")
      return <Typography style={{ fontSize: 14,color: 'red'}}> {calcMin(passTime,timeNeeded) + ' min(s)'} </Typography>;
  
    } else {
      // Render a countdown
      return <Typography style={{ fontSize: 16,color: 'black'}}> {hours}:{minutes}:{seconds} </Typography>;
    }
  
  }
  

const Completionist = () => 
<>
<Typography style={{fontSize: 12, color:'red'}}>Open* </Typography>
</>
;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed}) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};
  
const calcMin = (passTime, timeNeeded) => {

    
    var editTime = moment(passTime).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
    var nowTime = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
    
    var now = moment(editTime); //todays date
    var end = moment(nowTime); // another date
    var duration = moment.duration(now.diff(end));
    var days = duration.asMinutes();
    //console.log(days)
    
      //console.log("get min diff: " + days);
    
    
    
      var minutes = Math.ceil(days)
    
      return minutes
    
    }
  const columns = [
    
      {
          field: "title",
          headerName: "",
          width: 200,
          height: 150,
          marginTop: 10,
          marginBottom: 10,
          renderCell: (params) => {
              return (
                  <div className="d-flex justify-content-between align-items-center">
  
        <Box
          style={{
            backgroundColor: params.row.color,
            padding: 10,
            borderRadius: 10,
            color: 'white' ,
            marginTop: 5,
            marginBottom: 5,
            fontSize: 16
          }}
        >

          <Typography style={{ fontSize: 14}}>
          {params.row.title}
          </Typography>
        </Box>
                   </div>
              );
           }
      },
      {
        field: "fromPass",
        headerName: "Origin/Return",
        width: 220,
        height: 150,
        renderCell: (params) => {
            return (
      <Box
      style={{

        width: 200,
        color: 'black' ,
        fontSize: 14
      }}
      >


{params.row.needsApproval == "Y"  && params.row.studentId == reactLocalStorage.get('studentId') ? (
<>
<Button variant="ghost" style={{fontSize: 14}} onClick={() => {




console.log(params.row.id);
}}
>

{passSelected == null || passSelected == undefined || passSelected == ""  ? (
<>

<Typography style={{fontSize: 14,textDecoration: 'underline'}}>

{params.row.fromPass}

</Typography>

</> ) : (
<>

<Typography style={{fontSize: 14,textDecoration: 'underline'}}>

{params.row.fromPass}

</Typography>

</>

)}





</Button>


</>
  ) : (

    <>
   {params.row.fromPass}
    </>

  )}
 

      




      </Box>
     
            );
         }
      },

        { field: 'desc', headerName: 'Student',flex: 1},
        {
          field: "start",
          headerName: "Created",
          flex: 1,

          renderCell: (params) => {
              return (
        <Box
          style={{
            color: 'black' ,
            marginTop: 5,
            marginBottom: 5,
            fontSize: 16
          }}
        >
          <Typography style={{ fontSize: 12}}>
          {moment(params.row.start).format("MM/DD/YYYY hh:mm a")}
          </Typography>
        </Box>
       
              );
           }
        },

        {
          field: "end",
          headerName: "Expires",
          flex: 1,

          renderCell: (params) => {
              return (
    
        <Box
          style={{
            color: 'black' ,
            marginTop: 5,
            marginBottom: 5,
            fontSize: 14
          }}
        >
          <Typography style={{ fontSize: 14}}>
               {moment(params.row.end).format("hh:mm a")}
          </Typography>
        </Box>
       
              );
           }
        },

        {
          field: "timeNeeded",
          headerName: "Time left",
          flex: 1,

          renderCell: (params) => {
              return (
        <Box
          style={{
            color: 'black' ,
            marginTop: 5,
            marginBottom: 5,
            fontSize: 16
          }}
        >

    {checkDate(params.row.start) && params.row.receivedFlag == "N" && params.row.approve == "N" ? (
       
      <>

       {moment(params.row.start).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS") > moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS") ? (
        <>
          <Typography style={{ fontSize: 12,color: 'blue'}}>
               Scheduled
          </Typography>
        </>

       ) : (
        <>

<Countdown
        date={expireLogic(params.row.start,params.row.end,params.row.title,params.row.receivedFlag)}
        renderer={(props) => 
        
          
        <div>{myRenderer(props.hours,props.minutes,props.seconds,props.completed,params.row.end,params.row.timeNeeded)}</div>

        
      
      
      }
        />

<Button variant="ghost" onClick={() => putPass(params.row.id,"Y")}>

<Typography style={{fontSize: 11}}>
End Pass
</Typography>

</Button>
        </>

       )}

      </>
  

        ) : (


          <>

{params.row.receivedFlag == "Y" && params.row.approve == "N"  ? (

  <>
 <Typography style={{ fontSize: 12,color: 'green'}}>
               Pass Ended
          </Typography>

          <Button variant="ghost" onClick={() => putPass(params.row.id,"N")}>

<Typography style={{fontSize: 12}}>
Undo
</Typography>

</Button>
  </>

  

) : (

  <>
  


{params.row.approve == "Y"  ? (

<>
{params.row.receivedFlag == "X" ? (

    <>
        <Typography style={{ fontSize: 12,color: 'purple'}}>
                 Canceled
       </Typography>

       <Button variant="ghost" onClick={() => putPass(params.row.id,"N")}>

        <Typography style={{fontSize: 12}}>
        Undo
        </Typography>

        </Button>

    </>

    
  
  ) : (

    <>

       <Typography style={{ fontSize: 14,color: 'orange'}}>
             waiting approval...
        </Typography>

<Button variant="contained" onClick={() => putPass(params.row.id,"X")}>

<Typography style={{fontSize: 12}}>
  Cancel
</Typography>

</Button>

    </>
  )}



</>

) : (

<>


    <Typography style={{ fontSize: 12,color: 'red'}}>
             Open
    </Typography>
</>

)}


  </>

)}
            
          </>
        )}



        </Box>
       
              );
           }
        },


        {

          flex: 1,

          renderCell: (params) => {
              return (
        <Box
          style={{
            color: 'black' ,
            marginTop: 5,
            marginBottom: 5,
            fontSize: 16
          }}
        >


{params.row.approve == "Y" && params.row.studentId == reactLocalStorage.get('studentId')  ? (


<>

</>

    ) : (

      <>

      </>

    )}




          

{checkDate(params.row.start) && !isExpired(params.row.end) && params.row.receivedFlag == "N" && params.row.approve == "N" && params.row.studentId == reactLocalStorage.get('studentId') ? (

<>


{moment(params.row.start).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS") > moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS") && params.row.studentId == reactLocalStorage.get('studentId') ? (
    <>
      <Button variant="contained" onClick={() => putPass(params.row.id,"X")}>

        <Typography style={{fontSize: 12}}>
          Cancel
        </Typography>

    </Button>
    </>



   ) : (
    <>
   <Button variant="contained" onClick={() => putPass(params.row.id,"Y")}>

          <Typography style={{fontSize: 12}}>
           End Pass
          </Typography>

    </Button>
  
  </>

   )}
 


</>
      
      
      ) : (





          <>

{checkDate(params.row.start) && isExpired(params.row.end) && params.row.studentId == reactLocalStorage.get('studentId')  ? (

  <>

  </>

) : (

  <>

  </>

)}
            
          </>
        )}



        </Box>
       
              );
           }
        },
        {
            field: 'notes',
            headerName: '',
            flex: 1,
            renderCell: (params) => {
                return (
<>
               {params.row.notes ? (
                   <>
 <Typography style={{fontSize: 12}}>
           Note
          </Typography>
                   </>

               ) : (
<>

</>

               )}


              </>
              );
           }
        },



    ];
  
    

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);

    
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleFolderClose = () => {
    setFolderOpen(false);
  };

  const goToPass = () => {

    navigate('/addpass')

  }

  const goToHall = () => navigate('/studentdash',true)

  const goToRoom = () => navigate('/room',true)

  const goToTeacher = () => navigate('/studentcreate',true)

  const goToGroups = () => navigate('/groupteacher',true)

  const goToFolder = () => {

    navigate('/addfolder')

  }

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => locArray.find((row) => row.id === id));
    console.log(selectedRowsData);
    console.log("Here is the id: " + selectedRowsData.id)

    setDidSelect(!didSelect);
    
   
  };


  const notify = () => toast("Pass was updated successfully");

  const reportNotify = () => toast("Incident message was sent successfully");


  const doUpload = () => {

    setAlert(false);

    putPass(rowId,"N")

  };


  const reportStudent = (compName,fromUser,studentName,message,studentId) => {

   setDOpen(false)
   setMOpen(false)
   setLoading(true);
   

   var jwtToken = reactLocalStorage.get('jwtToken');

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

    console.log("COMPANY_NAME " + compName)


var apiUrl = reactLocalStorage.get('apiUrl');
axios.post(apiUrl + '/Profiles/ReportStudent',{ 
       companyName: compName,
       fromUser: fromUser,
       studentName: studentName,
       message: message,
       studentId: studentId
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setReportArray([]);
      setMessage(null)

      reportNotify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }


  const getEvents = (compName,from,to,role) => {

    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');
    var studentId = reactLocalStorage.get('studentId');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };

      var apiUrl = reactLocalStorage.get('apiUrl');

      var  apiEndpoint = '/Passes/StudentId/' + compName + "/" + from + "/" + to + "/" + userName + "/" + studentId;

    
      axios.get(apiUrl + apiEndpoint, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {


                    console.log("-------------------------------")
                    console.log(response.data)

                    var tempArray = []

                    for (var i = 0; i < response.data.length; i++) {

                        
    
                        tempArray.push({"id":response.data[i].id,"start":moment(response.data[i].start).toDate(),"end":moment(response.data[i].end).toDate(),"title":response.data[i].title ,"desc":response.data[i].detail,"color":response.data[i].color,"timeNeeded":response.data[i].timeNeeded,"approve":response.data[i].approve,"receivedFlag":response.data[i].receivedFlag,"fromPass":response.data[i].fromPass,"needsApproval":response.data[i].needsApproval,"needsApprovalBy":response.data[i].needsApprovalBy,"notes":response.data[i].notes,"studentId":response.data[i].studentId})
    
                    }

                    if (tempArray) {

                        setLocArray(tempArray)
                    }

                  
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

  const getEventsStudent = (compName,from,to, role,studentId) => {

        var token = reactLocalStorage.get('jwtToken');
        var userName = reactLocalStorage.get('userName');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
    
          var apiUrl = reactLocalStorage.get('apiUrl');
          var apiEndpoint = '/Passes/StudentDates/' + compName + "/" + from + "/" + to + "/" + studentId;
          console.log("-------student role-------------")
          console.log(role);
    
          if (role != 'ALL') {
    
            apiEndpoint = '/Passes/StudentUser/' + compName + "/" + from + "/" + to + "/"+ userName + "/" + studentId;
    
          }
        
          axios.get(apiUrl + apiEndpoint, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
                      setLocArray([])
        
                  } else {
    
    
             
                        
    
                        var tempArray = []
    
                        for (var i = 0; i < response.data.length; i++) {
    
                            
        
                            tempArray.push({"id":response.data[i].id,"start":moment(response.data[i].start).toDate(),"end":moment(response.data[i].end).toDate(),"title":response.data[i].title ,"desc":response.data[i].detail,"color":response.data[i].color,"timeNeeded":response.data[i].timeNeeded,"approve":response.data[i].approve,"receivedFlag":response.data[i].receivedFlag,"fromPass":response.data[i].fromPass,"needsApproval":response.data[i].needsApproval,"needsApprovalBy":response.data[i].needsApprovalBy,"notes":response.data[i].notes})
        
                        }
    
                        if (tempArray) {
    
                            setLocArray(tempArray)
                        }
    
                      
                  }
        
              
        
          }).catch(error => {
              setLocArray([])
              console.log(error.response);
        
          });
        
        }
    

    const getPasses = (compName) => {
    
      var token = reactLocalStorage.get('jwtToken');
  
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };

        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.get(apiUrl + '/PassMaints/GetList/' + compName, auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error)
      
                } else {
      
                    //console.log(response.data);

  
                     var tempArray = [];
                     setPassArray([])
  
                     for (var i = 0; i < response.data.length; i++) {
  
                      tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].pasS_ID,"color": response.data[i].pasS_COLOR,"folderFlag": response.data[i].foldeR_FLAG ,"approve": response.data[i].approvE_FLAG, "folderId": response.data[i].foldeR_ID})
  
                  }
  
                  if (tempArray) {
  
                      setPassArray(tempArray)
  
                  }
                 
             
                    
      
      
                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
      
        });
      
      }
  
  


    const approvePass = (id,timeNeeded) => {

      var companyName = reactLocalStorage.get('companyName');
      var userName = reactLocalStorage.get('userName');

      var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
          console.log(dateCreated)
          console.log(timeNeeded)
          var editTime = moment(dateCreated)
          .add(timeNeeded, 'minutes')
      
      var passTime = moment(editTime).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

      console.log(passTime)
      console.log(timeNeeded)

      setLoading(true);

      var jwtToken = reactLocalStorage.get('jwtToken');

      const auth = {
          headers: {
              Authorization: "Bearer " + jwtToken
          }
      };
  
      
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.put(apiUrl + '/Passes/ApprovePass/' + id, {
          APPROVE_FLAG: "N",
          APPROVE_BY: userName,
          DATE_CREATED: dateCreated,
          PASS_TIME: passTime
      }, auth).then(response => {
    
          if (response.data.error) {
              setLoading(false);
            
              console.log(response.data.error)
    
          } else {
              
            console.log(response.data)
            setLoading(false);

            var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
            var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");


            if (showAuto) {

                getEventsStudent(companyName,fDate,tDate,downloadReport,sId)

            } else {

                getEvents(companyName,fDate,tDate,downloadReport);
            }
          
    
    
          }
    
      }).catch(error => {
    
  
        console.log(error)
    
      });
    
    }
  
    

    const updateDest = (id,fromPass) => {

        var companyName = reactLocalStorage.get('companyName');
        var userName = reactLocalStorage.get('userName');
  
        setLoading(true);
  
        var jwtToken = reactLocalStorage.get('jwtToken');
  
        const auth = {
            headers: {
                Authorization: "Bearer " + jwtToken
            }
        };
    
        
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.put(apiUrl + '/Passes/UpdateDest/' + id, {
            ID: id,
            FROM_PASS: fromPass,
            USERNAME: userName
        }, auth).then(response => {
      
            if (response.data.error) {
                setLoading(false);
                console.log("error")
                console.log(response.data.error)
      
            } else {
                
              console.log(response.data)
              setLoading(false);
    
            var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
            var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");

            if (showAuto) {

                getEventsStudent(companyName,fDate,tDate,downloadReport,sId);

            } else {

                getEvents(companyName,fDate,tDate,downloadReport);
            }
      
      
            }
      
        }).catch(error => {
      
          setLoading(false);
          console.log("catch")
          console.log(error)
      
        });
      
      }
    
    

    const putPass = (id,pFlag) => {

      //in post request

      var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
  
     
      var userName = reactLocalStorage.get('userName');
      var name = reactLocalStorage.get('name');
      var companyName = reactLocalStorage.get('companyName');


      setLoading(true);

      var jwtToken = reactLocalStorage.get('jwtToken');

      const auth = {
          headers: {
              Authorization: "Bearer " + jwtToken
          }
      };
  
      
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.put(apiUrl + '/Passes/PutPass/' + id, {
          RECEIVED_FLAG: pFlag,
          RECEIVED_DATE: dateCreated,
          RECEIVED_BY: name
      }, auth).then(response => {
    
          if (response.data.error) {
              setLoading(false);
            
              console.log(response.data.error)
    
          } else {
              
            console.log(response.data)
            setLoading(false);
  
            var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
            var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");

            if (showAuto) {

                getEventsStudent(companyName,fDate,tDate,downloadReport,sId);

            } else {

                getEvents(companyName,fDate,tDate,downloadReport);
            }
            
    
    
          }
    
      }).catch(error => {
    
  
        console.log(error)
    
      });
    
    }

    const studentChange = (value) => {

        if (value != '' && value != undefined && value != null) {
    
            setSId(value.id)
            setGroupName(value.label)
            setGroupArray(value)
    
            console.log("======================================")
            console.log(value)
            console.log(value.length)
            console.log("======================================")
        }
    
      
    
    
      }
    


   const studentReportChange = (value) => {

        if (value != '' && value != undefined && value != null) {
    
            setReportArray(value)
    
            console.log("======================================")
            console.log(value)
            console.log(value.length)
            console.log("======================================")
        }
    
      
    
    
      }
  

  const navigate = useNavigate();
  const goToLogout = () => navigate('/');


  const changeButtonGroup = (value) => {

    console.log(value);

    if (value == 0) {

        setButton1("contained");
        setButton2("outlined");
        setDownloadReport('ALL');

        var compName = reactLocalStorage.get('companyName');
        var userName = reactLocalStorage.get('userName');
    
        var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
        var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");
    
        if (showAuto) {

            getEventsStudent(compName,fDate,tDate,'ALL',sId)
            getLineStdnt(compName,fDate,tDate,'ALL',userName,sId);
            getPieStdnt(compName,fDate,tDate,'ALL',userName,sId);

        } else {

            getEvents(compName,fDate,tDate,'ALL')
            getLineChart(compName,fDate,tDate,'ALL',userName);
            getPieChart(compName,fDate,tDate,'ALL',userName);
        }
       

    } else {

        setButton1("outlined");
        setButton2("contained");
        setDownloadReport('teacher');

        var compName = reactLocalStorage.get('companyName');
        var userName = reactLocalStorage.get('userName');
    
        var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
        var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");

        if (showAuto) {

            getEventsStudent(compName,fDate,tDate,'teacher',sId)
            getLineStdnt(compName,fDate,tDate,'teacher',userName,sId);
            getPieStdnt(compName,fDate,tDate,'teacher',userName,sId);

        } else {

            getEvents(compName,fDate,tDate,'teacher')
            getLineChart(compName,fDate,tDate,'teacher',userName);
            getPieChart(compName,fDate,tDate,'teacher',userName);
        }

    }


  }
  
  const changeAuto = (value) => {

    console.log(value);

    setPieArray([])
    setLineArray([])
    setLocArray([])


    if (value == 0) {

        setSId(null)
        setShowAuto(false);
  
        var compName = reactLocalStorage.get('companyName');
        var userName = reactLocalStorage.get('userName');
    
        var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
        var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");
    
        if (showAuto) {

            setPieArray([])
            setLineArray([])
            setLocArray([])

            getEventsStudent(compName,fDate,tDate,downloadReport,sId)
            getLineStdnt(compName,fDate,tDate,downloadReport,userName,sId);
            getPieStdnt(compName,fDate,tDate,downloadReport,userName,sId);

        } else {

            getEvents(compName,fDate,tDate,downloadReport)
            getLineChart(compName,fDate,tDate,downloadReport,userName);
            getPieChart(compName,fDate,tDate,downloadReport,userName);
        }
       

    } else {

        setShowAuto(true);
        setSId(null)

        var compName = reactLocalStorage.get('companyName');
        var userName = reactLocalStorage.get('userName');
    
        var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
        var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");

        if (showAuto) {

            setPieArray([])
            setLineArray([])
            setLocArray([])

            getEventsStudent(compName,fDate,tDate,downloadReport,sId)
            getLineStdnt(compName,fDate,tDate,downloadReport,userName,sId);
            getPieStdnt(compName,fDate,tDate,downloadReport,userName,sId);

        } else {


            getEvents(compName,fDate,tDate,downloadReport)
            getLineChart(compName,fDate,tDate,downloadReport,userName);
            getPieChart(compName,fDate,tDate,downloadReport,userName);
        }

    }


  }
  

  const getStudentData = () => {

    console.log(sId)

    var compName = reactLocalStorage.get('companyName');
    var userName = reactLocalStorage.get('userName');

    var fDate = moment(from).tz("America/New_York").format("YYYY-MM-DD");
    var tDate = moment(to).tz("America/New_York").format("YYYY-MM-DD");


    getEventsStudent(compName,fDate,tDate,downloadReport,sId)
    getLineStdnt(compName,fDate,tDate,downloadReport,userName,sId);
    getPieStdnt(compName,fDate,tDate,downloadReport,userName,sId);


    
  }

  const handleClose = () => {
    setAlert(false);
  };


  const mClose = () => {
    setMOpen(false);
  };

  const dClose = () => {
    setDOpen(false);
  };

  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{width: 400,height: 300,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Default Time: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}
        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />

            </IconButton>


            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              style={{fontSize: 12}}
              sx={{ flexGrow: 1 }}
            >
             

        
<Avatar
  src={profilePic}
/>
{reactLocalStorage.get('userName') }
            </Typography>

            
           <Button variant="contained" onClick={goToTeacher} style={{backgroundColor: 'white'}}>


           <Typography style={{color: 'black'}}>
               + Create Pass
               </Typography>
           </Button>



          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
                   <Stack direction="row" spacing={{ xs: 1, sm: 2 }}  justifyContent="flex-end"
  alignItems="baseline" >

<img style={{width: 100}} alt="logo" src="https://conciergepad-images.s3.amazonaws.com/concierge_logo.png" />

            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
          
            </IconButton>

    </Stack>
          </Toolbar>
          <Divider />
          <List component="nav">
     




<ListItemButton onClick={goToHall}>
 <ListItemIcon>
   <HallIcon />
 </ListItemIcon>
 <ListItemText primary="Hall Monitor" />
</ListItemButton>
       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>

<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>
     </List>




        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>





                
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
     
       
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                {showAuto ? (
<>

<Stack direction="row" style={{marginBottom: 5}}spacing={{ xs: 1, sm: 2 }}  justifyContent="space-between"
  alignItems="baseline"  >
       <Typography style={{fontSize: 12}}> </Typography>
  <Typography style={{fontSize: 12}}>{from} → {to} </Typography>
      </Stack>

              


</> ) : (
<>

</>

)}

  
         

                <Stack direction="row" spacing={{ xs: 1, sm: 2 }} 
  alignItems="baseline" >



      {showAuto ? (
<>





<Stack direction="row" spacing={2}
  alignItems="center" >


<Stack spacing={2} style={{alignItems: 'center'}}>


<Autocomplete
key={sId} 
id="combo-box-demo"
onChange={(event, value) => {

studentChange(value)


}} 
options={studentArray}
style={{width: 300}}
getOptionLabel={(option) => option.label}
renderOption={(props, option) => (
<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

{option.groupType == "G" ? (

<>
<GroupIcon/>
</>
) : (
<>
<SchoolIcon/>
</>
)}


<Typography style={{marginLeft: 5}}>{option.label} </Typography>



</Box>
)}
renderInput={(params) => (
<TextField
{...params}
label="Choose a student"
/>
)}
/>


</Stack>



</Stack>
</>
) : (

    <>




    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer style={{marginBottom: 10}} components={['DatePicker']}>
   <DatePicker style={{marginBottom: 10}} label="From date"
     value={dayjs(from)}
     onChange={(newValue) => {


       //var myDate = JSON.stringify(newValue.$d)

       //console.log(myDate);

       var dateFormatted = new Date(dayjs(newValue))
       var dateForSelected = moment(dateFormatted).tz("America/New_York").format("MM/DD/YYYY")
       var dateForEvents = moment(dateFormatted).tz("America/New_York").format("YYYY-MM-DD")

      
       console.log(moment(dateForSelected).tz("America/New_York").format("MM/DD/YYYY"))

       setFrom(dateForSelected)

       var compName = reactLocalStorage.get('companyName');

       getEvents(compName,dateForSelected,to,downloadReport)
     
     }
   }
      
  
   />
 </DemoContainer>
</LocalizationProvider>


<LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer style={{marginBottom: 10}} components={['DatePicker']}>
   <DatePicker style={{marginBottom: 10}} label="To date"
     value={dayjs(to)}
     onChange={(newValue) => {


       //var myDate = JSON.stringify(newValue.$d)

       //console.log(myDate);

       var dateFormatted = new Date(dayjs(newValue))
       var dateForSelected = moment(dateFormatted).tz("America/New_York").format("MM/DD/YYYY")
       var dateForEvents = moment(dateFormatted).tz("America/New_York").format("YYYY-MM-DD")

      
       console.log(moment(dateForSelected).tz("America/New_York").format("MM/DD/YYYY"))

       setTo(dateForSelected)

       var compName = reactLocalStorage.get('companyName');

       getEvents(compName,from,dateForEvents,downloadReport)
     
     }
   }
      
  
   />
 </DemoContainer>
</LocalizationProvider>

</>

)}






  
  </Stack>



      {showAuto ? (
<>

<Stack direction="row" style={{marginBottom: 5}}spacing={{ xs: 1, sm: 2 }}  justifyContent="space-between"
  alignItems="baseline"  >
       <Typography style={{fontSize: 12}}> </Typography>
  <Typography style={{fontSize: 12}}>{from} → {to} </Typography>
      </Stack>

              


</> ) : (
<>

</>

)}

  
         



  <Stack direction="row" spacing={2} alignItems="center">


<LineChart
  dataset={lineArray}
  xAxis={[{ 

    dataKey: 'x',
    scaleType: 'time',
    valueFormatter: (date) => moment.utc(date*3600*1000).format('h a')
      ,
  
  }]}
  series={[{ dataKey: 'y'}]}
  width={600}
  height={200}
/>



</Stack>

      <DataGrid
        style={{marginTop: 10}}
        rows={locArray}
        columns={columns}
        autoHeight
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />



                </Paper>
              </Grid>

              <Grid item xs={12}>


              {didSelect ? (
      

      <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >


      <ToggleButton variant="contained" color="success" value="web">Passes</ToggleButton>
      <ToggleButton variant="contained" color="info" value="android">Edit</ToggleButton>
      <ToggleButton variant="contained" color="error" value="ios">Delete</ToggleButton>
    </ToggleButtonGroup>

        ) : (
          <>
            
          </>
        )}
       



              </Grid>



              <Modal
        open={mOpen}
        onClose={mClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography style={{marginBottom: 10}} id="modal-modal-title" variant="h6" component="h2">
            Report Student
          </Typography>
        
          <Autocomplete
key={sId} 
id="combo-box-demo"
onChange={(event, value) => {

studentReportChange(value)


}} 
options={studentArray}
style={{marginBottom: 10}}
getOptionLabel={(option) => option.label}
renderOption={(props, option) => (
<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

{option.groupType == "G" ? (

<>
<GroupIcon/>
</>
) : (
<>
<SchoolIcon/>
</>
)}


<Typography style={{marginLeft: 5}}>{option.label} </Typography>



</Box>
)}
renderInput={(params) => (
<TextField
{...params}
label="Choose a student"
/>
)}
/>

<Stack>


<TextField
style={{ margin: 0, width: '100%' }}
label="Message"
placeholder="Ex. Student did not return to class"
helperText="(300 character maximum)"
inputProps={{ maxLength: 300 }}
multiline
onChange={(event) => setMessage(event.target.value)}
rows="4"
autoFocus={true}
variant="outlined"/>


<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} >
<Button onClick={mClose}>Cancel</Button>

<Button variant="outlined" onClick={() => {
    
    setDOpen(true)}

}>Send</Button>
</Stack>

</Stack>
        </Box>

      </Modal>



    <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{marginBottom: 10}} id="modal-modal-title" variant="h6" component="h2">
            Change Origin/Return
          </Typography>
        
          <Autocomplete
          disablePortal
          id="combo-box-demo"
      onChange={(event, value) => {

        console.log("on change")

      }} 
  options={passArray}
  style={{marginBottom: 20}}
  renderOption={(props, option) => (
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

    <Box
    style={{
      backgroundColor: option.color,
      width: 200,
      padding: 5,
      borderRadius: 10,
      color: 'white'

    }}
  >

{option.folderFlag == "Y" ? (
<>



<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} >

<Typography>{option.label}</Typography>
<ArrowForwardIcon/>



</Stack>


</>          

  ) : (
    <>
   <Typography>{option.label}</Typography>
    </>
  )}
     
      </Box>










</Box>










)}
renderInput={(params) => (
<TextField
{...params}
label="Where to?"
/>
)}
/>


<Button onClick={handleModalClose}>Cancel</Button>

        </Box>

      </Modal>


      <Modal
        open={folderOpen}
        onClose={handleFolderClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography style={{marginBottom: 10}} id="modal-modal-title" variant="h6" component="h2">
            Select Room
          </Typography>
        
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event, value) => {

            console.log(value.id)

            console.log(selectedId)
            console.log(value.label)
            updateDest(selectedId,value.label)
    
            passChange(value)

            setModalOpen(false);

            setFolderOpen(false);
  

            }} 
        options={roomArray}
        style={{marginBottom: 20}}
        renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

          <Box
          style={{
            backgroundColor: option.color,
            width: 200,
            padding: 5,
            borderRadius: 10,
            color: 'white'

          }}
        >

            <Typography>{option.label}</Typography>
     
            </Box>
     

    


     




    </Box>










  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Where to?"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password', // disable autocomplete and autofill
      }}
    />
  )}
/>

<Button onClick={handleFolderClose}>Cancel</Button>

        </Box>

      </Modal>


              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove Room/Pass"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this room/pass?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       




      <Dialog
        open={dOpen}
        onClose={dClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Report incident"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and send incident for this student?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dClose}>Cancel</Button>
          <Button onClick={() => {
              
              if (reportArray && (message !== '' || message !== undefined || message !== null)) {

                var compName = reactLocalStorage.get('companyName');
                var name = reactLocalStorage.get('name');
  
                reportStudent(compName,name,reportArray.label,message,reportArray.id)

              }

           
          }
              } autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}