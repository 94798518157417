import {useEffect, useState,useLayoutEffect} from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
  Snackbar,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from '@mui/material';
import { Alert } from '@mui/material';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';

export default function LostUpdate() {


  const [progressVisible, setProgressVisible] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [items, setItems] = useState([]);
  const [uuid, setUuid] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  });

  const [emailValidating, setEmailValidating] = useState(false);
  const [emailTimeout, setEmailTimeout] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);



  useEffect(() => {

    var urlParams = new URLSearchParams(window.location.search);
    var uuid = urlParams.get('id');

    setUuid(uuid)

    console.log(uuid);

    getUser(uuid);


  

  }, []);


  const getUser = (uuid) => {
    
      axios.get('https://api.bridgepay.io/api/Users/GetUserUuid/' + uuid).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);

                  setEmail(response.data.email)

                  setItems(response.data)
                
             
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

    const changePassword = (e) => {


        console.log(e.target.value)

        setPassword(e.target.value)

    }

    const changePassword2 = (e) => {


        console.log(e.target.value)

        setPassword2(e.target.value)

    }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });


   



    console.log(value)

    if (name === 'password' || name === 'confirmPassword') {
      //validatePasswords();
    }
  };


  const validatePasswords = () => {
    let errors = {};

    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    } else {
      errors.password = '';

      console.log(formData.password)
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.confirmPassword !== formData.password) {

        console.log(formData.confirmPassword)
        console.log(formData.password)
      errors.confirmPassword = 'Passwords do not match';
    } else {
      errors.confirmPassword = '';
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'Email is invalid';
    }

    if (!formData.firstName) {
      isValid = false;
      errors.firstName = 'First name is required';
    }

    if (!formData.lastName) {
      isValid = false;
      errors.lastName = 'Last name is required';
    }

    if (!formData.dateOfBirth) {
      isValid = false;
      errors.dateOfBirth = 'Date of birth is required';
    }

    setFormErrors(errors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length > 5 && (password === password2)) {

        setDialogOpen(true);
    }

  
  };

  const navigate = useNavigate();

  const goToRooms = () => navigate('/',true)

  const handleConfirmation = async () => {
    setDialogOpen(false);
    setProgressVisible(true);

    console.log("id => " + items.id)
    console.log("companyName => " + items.companY_NAME)

    const postData = {
        ID: items.id,
        USERNAME: email,
        PASSWORD:  password,
        COMPANY_NAME: items.companY_NAME,
        UUID: items.hangeR_ID,
        DATE_CREATED: items.datE_CREATED,
        MOBILE_PHONE:  items.mobilE_PHONE,
        FIRST_NAME: items.firsT_NAME,
        LAST_NAME: items.lasT_NAME,
        EMAIL: items.email,
        DOB: items.dob,
        HANGER_ID: items.hangeR_ID,
        PUSH_TOKEN:'',
        REGISTRATION_UUID: uuid,
        PRIMARY_ACCOUNT: items.primarY_ACCOUNT,
        LINKED_ACCOUUNT: items.linkeD_ACCOUNT,
        ACTIVATE_FLAG: items.activE_FLAG
    };

    try {
      const response = await axios.put('https://api.bridgepay.io/api/Users/PutUser/' + items.id, postData);
      console.log('Form submission successful:', response.data);
      setToastOpen(true);
      setPassword('')
      setPassword2('')
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error scenario (show error message, retry, etc.)
    } finally {
      setProgressVisible(false);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };


  return (
    <Container component="main" maxWidth="xs">
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 2 }}
          onClick={() => goToRooms()}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Login
        </Typography>
      </Toolbar>
    </AppBar>
    <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">
        Submit
      </Typography>
      <Box component="form" sx={{ mt: 3 }} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address or Username"
              name="email"
              autoComplete="email"
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={changePassword}

            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={password2}
              onChange={changePassword2}

            />
          </Grid>

  

          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={submitting || progressVisible}
          >
            Submit
          </Button>
        </Box>
        {progressVisible && <LinearProgress sx={{ width: '100%', mt: 3 }} />}
      </Box>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to update password?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmation} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        autoHideDuration={10000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleToastClose}>
          Account created successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
}
