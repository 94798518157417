import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import Badge from '@mui/material/Badge';
import ReportIcon from '@mui/icons-material/BarChart';
import TeacherViewIcon from '@mui/icons-material/Face';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import FolderIcon from '@mui/icons-material/Folder';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridToolbar
} from '@mui/x-data-grid';
import {   GridActionsCellItem,
    GridAddIcon,
    GridDeleteIcon,
  } from "@mui/x-data-grid";
import Avatar from '@mui/material/Avatar';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Icon from '@mui/material/Icon';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import {QRCodeSVG} from 'qrcode.react';
import Stack from '@mui/material/Stack';

const MatEdit = ({ index }) => {

    const handleEditClick = () => {
        // some action
    }


    return  <Icon baseClassName="fas" className="fa-plus-circle" color="primary" />
};


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getbridgepay.com/">
        BridgePay
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Student() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [locArray,setLocArray] = useState([]);
  const [rowId,setRowId] = useState();
  const [isPass,setIsPass] = useState(true);
  const [folderName,setFolderName] = useState();
  const [folderColor,setFolderColor] = useState();
  const [alignment, setAlignment] = React.useState('web');
  const [didSelect, setDidSelect] = React.useState(false);
  const [user,setUser] = useState(reactLocalStorage.get('userName'))
  const [profileAdmin,setProfileAdmin] = useState(reactLocalStorage.get('permissionFlag'))
  const [searchParams] = useSearchParams();

  const [compName,setCompName] = useState();
  const [fName,setFName] = useState();
  const [lName,setLName] = useState();
  const [price,setPrice] = useState();
  const [customerId,setCustomerId] = useState();
  const [customerName,setCustomerName] = useState();
  const [customerEmail,setCustomerEmail] = useState();
  const [priceId,setPriceId] = useState();
  const [paymentAccount,setPaymentAccount] = useState();
  const [productName,setProductName] = useState();
  const [interval,setInterval] = useState();
  const [subDate,setSubDate] = useState();
  const [productId,setProductId] = useState();
  const [subArray,setSubArray] = useState([]);


  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const { pathname } = useLocation();


  useEffect(() => {
    (async function() {
        try {
          setSubArray([])
          console.log("==========================")
          var compName = reactLocalStorage.get('companyName');
          var loginUuid = reactLocalStorage.get('loginUuid');
      
          console.log("==========================")
      
         
          await getCustomerId(loginUuid);
        } catch (e) {
            console.error(e);
        }
    })();
}, []);



  const getCustomerId = async (uuid) => {
    

    console.log("in the customer id section")
    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');
    var loginUuid = reactLocalStorage.get('loginUuid');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/StripeCustomers/GetCustomer/' + uuid + "/" + userName, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {

                console.log('response', response.data)



                console.log("customerId => " + response.data.customeR_ID)
                console.log("email => " + response.data.email)
                console.log("customerName => " + response.data.namE_ON_FILE)

                setCustomerId(response.data.customeR_ID)
                setCustomerEmail(response.data.email)
                setCustomerName(response.data.namE_ON_FILE)

                getPasses(loginUuid,response.data.customeR_ID);



           
                  
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }





  const goToMenu = () => navigate('/dashboard',true)
  const goToAccounts = () => navigate('/student',true)
  const goToReport = () => navigate('/pass',true)
  const goToTeacher = () => navigate('/teacher',true)
  const goToInbox = () => navigate('/inbox',true)
  const goToHall = () => navigate('/hall',true)

  const handleOnCellClick = (params) => {


    if(params.field === 'pasS_COLOR'){

      console.log("Here")

      if (profileAdmin == 'Super-admin') {

        if (params.row.foldeR_FLAG == "Y") {
          goToEditFolder(params.row.foldeR_ID)
        } else {
          console.log(params.row.id)
          goToEdit(params.row.pasS_ID)
        }

      }

    
      
    }




  };

  const goToEdit=(pId)=>{
    navigate('/editpass',{state:{id:pId}});
    }

    const goToEditFolder=(pId)=>{
      navigate('/editfolder',{state:{id:pId}});
      }

  const goToDelete=(pId)=>{
      
      
    }
  
    const columns = [

     
      { field: 'productName', headerName: 'Plan Name', width: 200, flex: 1},
      { field: 'amount', headerName: 'Amount', width: 200, flex: 1},
      { field: 'customerName', headerName: 'Name', width: 200, flex: 1},
      { field: 'status', headerName: 'Status', width: 200, flex: 1},
        /*{
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
  
          getActions: (params ) => {

    
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(params.row.id)}
                color="inherit"
              />
            ];
          },
        },*/
      ];
  
    

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);

    
  };

  const goToPass = () => {

    navigate('/addpass')

  }

  const goToFolder = () => {

    navigate('/addfolder')

  }

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => locArray.find((row) => row.id === id));
    console.log(selectedRowsData);
    console.log("Here is the id: " + selectedRowsData.id)

    setDidSelect(!didSelect);
    
   
  };


  const notify = () => toast("Subscription successly attached to your account.");

  const doUpload = () => {

    setAlert(false);

    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');
    var loginUuid = reactLocalStorage.get('loginUuid');

    console.log(compName);
    console.log(fName)
    console.log(lName);
    console.log(price);
    console.log(customerId);
    console.log(customerName);
    console.log(userName)
    console.log(priceId)
    console.log(paymentAccount)
    console.log(loginUuid)
    console.log(productName)
    console.log(interval)
    console.log(subDate)
    console.log(productId)

    postAutoPay(compName,
                fName,
                lName,
                price,
                customerId,
                customerName,
                userName,
                priceId,
                paymentAccount,
                loginUuid,
                productName,
                interval,
                subDate,
                productId)

   

  };


  const postAutoPay = (compName,
                      fName,
                      lName,
                      price,
                      customerId,
                      customerName,
                      userName,
                      priceId,
                      paymentAccount,
                      loginUuid,
                      productName,
                      interval,
                      subDate,
                      productId) => {

    setLoading(true);

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

var apiUrl = reactLocalStorage.get('apiUrl');
axios.post(apiUrl + '/Stores/DoAutopay',{ 
       CompanyName: compName,
       StudentFName: fName,
       StudentLName: lName,
       PriceCart: price,
       CustomerId: customerId,
       CustomerName: customerName,
       CustomerEmail: userName,
       PriceId: priceId,
       PaymentAccount: paymentAccount,
       LoginUuid: loginUuid,
       ProductName: productName,
       Interval: interval,
       SubDate: subDate,
       ProductId: productId,
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

    } else {

      console.log('success', response.data)

      setLoading(false);

      setFName('')
      setLName('')
      setCompName('')
      setCustomerId('')
      setCustomerName('')
      setCustomerEmail('')
      setPriceId('')
      setPaymentAccount('')
      setProductName('')
      setInterval('')
      setSubDate('')
      setProductId('')

      notify();


      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }


  const getSubscriptions = async (customerId,companyName,paymentAccount) => {
    

    setSubArray([]);
    
    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/Stores/subscription-schedules/' + customerId + '/' + companyName + '/' + paymentAccount,auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
                  setSubArray([]);
    
              } else {


                   console.log("=================== >")
                   console.log(response.data);

                   setSubArray(response.data);

              }
    
          
    
      }).catch(error => {

          setSubArray([]);
    
          console.log(error.response);
    
      });
    
    }

  const doAdd =  async  (customerId,companyName,paymentAccount) => {

    await getSubscriptions(customerId,companyName,paymentAccount)

  }
  const getPasses = async (uuid,customerId) => {
    
    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/Mobiles/GetPlans/' + uuid, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {

                   var tempArray = [];
                   setLocArray([])
                   setSubArray([])
                  
                   for (var i = 0; i < response.data.length; i++) {

                    tempArray.push({"label":response.data[i].productName,
                                    "id":response.data[i].productId,
                                    "interval":response.data[i].interval,
                                    "companyName":response.data[i].companyName,
                                    "subDate":response.data[i].subDate,
                                    "amount":response.data[i].subAmount,
                                    "name":response.data[i].subName,
                                    "productId":response.data[i].productId,
                                    "paymentAccount":response.data[i].paymentAccount,
                                    "priceId":response.data[i].priceId})

                    

                                    //doAdd(customerId,response.data[i].companyName,response.data[i].paymentAccount)

                }

                if (tempArray) {

                    setLocArray(tempArray)

                }
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

    const addEmp = () => {

      if (fName != undefined && fName != '' && fName != null && lName != undefined && lName != '' && lName != null)
      {
        setAlert(true)
      }

      
    }

  const putPass = (id,activeFlag) => {

      setLoading(true);
  
      var token = reactLocalStorage.get('jwtToken');
  
  
     const auth = {
        headers: {Authorization: "Bearer " + token} 
      };
  
      console.log("ID " + id)
      console.log("ACTIVE_FLAG " + activeFlag)
  
  var apiUrl = reactLocalStorage.get('apiUrl');
  axios.put(apiUrl + '/PassMaints/RemovePass/' + id,{ 
         ACTIVE_FLAG: activeFlag
      },auth).then(response => {
  
  
      if (response.data.error) {
  
        setLoading(false);
  
      } else {
  
       
  
        console.log("==========================")
        console.log('success', response.data)
        setLoading(false);
        var loginUuid = reactLocalStorage.get('loginUuid');
        notify();
        getPasses(loginUuid);
        console.log("==========================")
    
       
    
  
        
  
  
        
  
      }
  
     }).catch(error => {
  
        setLoading(false);
        console.log(error.response)
  
      });
  
   
    }


const removeFolder = (id,folderName,color,activeFlag) => {

      setLoading(true);
  
      var token = reactLocalStorage.get('jwtToken');
      var companyName = reactLocalStorage.get('companyName');
      var dateAdded = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");
  
     const auth = {
        headers: {Authorization: "Bearer " + token} 
      };
  
      console.log("ID " + id)
      console.log("ACTIVE_FLAG " + activeFlag)
  
  var apiUrl = reactLocalStorage.get('apiUrl');
  axios.put(apiUrl + '/Folders/' + id,{ 
         ID: id,
         COMPANY_NAME: companyName,
         FOLDER_NAME: folderName,
         DATE_ADDED: dateAdded,
         COLOR: color,
         ACTIVE_FLAG: activeFlag
      },auth).then(response => {
  
  
      if (response.data.error) {
  
        setLoading(false);
  
      } else {
  
       
  
        console.log("==========================")
        console.log('success', response.data)
        setLoading(false);
        var compName = reactLocalStorage.get('companyName');
        notify();
        getPasses(compName);
        console.log("==========================")
    
       
    
  
        
  
  
        
  
      }
  
     }).catch(error => {
  
        setLoading(false);
        console.log(error.response)
  
      });
  
   
    }
  
  
  const navigate = useNavigate();
  const goToLogout = () => navigate('/');

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };
  

  

  const handleClose = () => {
    setAlert(false);
  };



  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{width: 400,height: 300,backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
    
        <br />
        </Typography>
        <Typography style={{fontSize: 20,marginBottom: 20}} color="white">
     
  
    <br />
Default Time: {passTime} minutes
      
        </Typography>
        <Typography style={{fontSize: 20}} color="white">
         Capacity: {passCapacity}
        </Typography>
      </CardContent>
      <CardActions>

      </CardActions>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             

        Auto Pay

            </Typography>
      
            <Typography variant="body2">

          {reactLocalStorage.get('userName')}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
          <Stack direction="row" spacing={{ xs: 1, sm: 2 }}  justifyContent="flex-end"
  alignItems="baseline" >

<img style={{width: 100}} alt="logo" src="https://bridgepaypics.s3.us-east-2.amazonaws.com/bridgepay_logo_text.png" />

            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
          
            </IconButton>

    </Stack>
          </Toolbar>
          <Divider />
          <List component="nav">
     
     <ListItemButton  onClick={goToMenu}>
 <ListItemIcon>
   <DashboardIcon />
 </ListItemIcon>
 <ListItemText primary="Dashboard" />
</ListItemButton>
<ListItemButton onClick={goToReport}>
 <ListItemIcon>
   <CreditCardIcon />
 </ListItemIcon>
 <ListItemText primary="Card" />

 </ListItemButton>




<ListItemButton onClick={goToAccounts}>
 <ListItemIcon>
   <AutoModeIcon />
 </ListItemIcon>
 <ListItemText primary="Auto Pay" />
</ListItemButton>



<ListItemButton>
 <ListItemIcon>
   <AllInboxIcon />
 </ListItemIcon>
 <ListItemText primary="Forms" />
</ListItemButton>

<ListItemButton>
 <ListItemIcon>
   <AccountBalanceWalletIcon />
 </ListItemIcon>
 <ListItemText primary="History" />

 </ListItemButton>
       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>


<ListItemButton>
<ListItemIcon>
<TeacherViewIcon />
</ListItemIcon>
<ListItemText primary="My Student(s)" />
</ListItemButton>




<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>




     </List>



        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
       
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                <Typography style={{fontSize: 14}} color="red">
         *IMPORTANT - you must add plan to each student individually.  If you have 2 students you have to add 2 plans, one for each student.
        </Typography>
         <Grid style={{marginTop: 5,marginLeft: 5}}  spacing={2}>
<Autocomplete
      disablePortal
      id="combo-box-demo"
      onChange={(event, value,index) => {
          
        if (value !== undefined && value !== null && value !== '') {

          console.log(value.label);
          console.log(value.priceId)
          console.log(value.productId)
          console.log(value.companyName)
          console.log(value.paymentAccount)
          console.log(value.name)
          console.log(value.amount)
          console.log(value.companyName)
          console.log(value.interval)
          console.log(value.subDate)
          console.log(value.customerId)

          setCompName(value.companyName)
          setPriceId(value.priceId)
          setProductId(value.productId)
          setProductName(value.name)
          setSubDate(value.subDate)
          setPaymentAccount(value.paymentAccount)
          setPrice(value.amount)
          setInterval(value.interval)

          console.log("=> customer ID " + customerId) 
          console.log("=> companyName " + value.companyName) 
          console.log("=> companyName " + value.companyName) 

          getSubscriptions(customerId,value.companyName,value.paymentAccount)

       
          
      




        }
       
    }} 
      options={locArray}
      sx={{ width: 500 }}
      renderInput={
          
        (params) => <TextField {...params} label="Plans" />
    
    }
    />

<Stack>


<TextField
                type="text"
                name="fname"
                className="form-control"
                placeholder="Student First Name"
                required
                style={{marginTop: 10,width: 400}}
                value={fName}
                onChange={(event) => setFName(event.target.value)}

              />



<TextField
          type="text"
          name="lname"
          required
          style={{marginRight: 5,marginTop: 10,width: 400}}
          placeholder="Student Last Name"
          value={lName}
          onChange={(event) => setLName(event.target.value)}

        />


</Stack>





<Button variant="outlined" style={{marginTop: 10}} onClick={addEmp}>

Add to Profile

</Button>
    </Grid>

                </Paper>
              </Grid>

              <Grid item xs={12} style={{backgroundColor: 'white'}}>


              <DataGrid
        rows={subArray}
        columns={columns}
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />
       



              </Grid>

              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add subscription for student: " + fName + " " + lName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}