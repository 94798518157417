import * as React from 'react';
import {useEffect, useState,useLayoutEffect,useRef} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ReportIcon from '@mui/icons-material/BarChart';
import TeacherViewIcon from '@mui/icons-material/Face';
import HomeIcon from '@mui/icons-material/Home';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import RedoIcon from '@mui/icons-material/HighlightOff';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import TeacherIcon from '@mui/icons-material/School';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ClockIcon from '@mui/icons-material/AccessTime';
import UndoIcon from '@mui/icons-material/Undo';
import ColorPicker from 'react-pick-color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import {reactLocalStorage} from 'reactjs-localstorage';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation,useSearchParams,createSearchParams } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import { Importer, ImporterField } from 'react-csv-importer';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Chip from '@mui/material/Chip';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";
import useFileUpload from 'react-use-file-upload';

// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import AWS from 'aws-sdk';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();




export default function Absense() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [approveFlag,setApproveFlag] = useState('N');
  const [timeNeeded,setTimeNeeded] = useState('8');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [searchParams] = useSearchParams();
  const [logoUrl,setLogoUrl] = useState();
  const { handle } = useParams()
  const [locArray, setLocArray] = React.useState([])
  const [teacherArray, setTeacherArray] = React.useState([])
  const [teacherId, setTeacherId] = React.useState()
  const [teacherSelected, setTeacherSelected] = React.useState()
  const [roomArray, setRoomArray] = React.useState([])
  const [passId, setPassId] = React.useState()
  const [passSelected, setPassSelected] = React.useState()
  const [dateTimeSelected, setDateTimeSelected] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"))
  const [passArray, setPassArray] = React.useState([])
  const [sId, setSId] = React.useState('')
  const [popup, setPopup] = React.useState(false);
  const [groupName, setGroupName] = React.useState([]);
  const [groupArray, setGroupArray] = React.useState([]);
  const [selectGroup, setSelectGroup] = React.useState(true);
  const [spacer, setSpacer] =  useState('  ');
  const [passImage,setPassImage] = useState('');
  const [travelType, setTravelType] = React.useState("Round-trip")
  const [roomNo, setRoomNo] = React.useState("")
  const [folderId, setFolderId] = React.useState("")
  const [render, setRender] = React.useState(false);
  const [currentTime, setCurrentTime] = useState(true);

  const steps = ['Student Information', 'Parent/Guardian Information'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [defaultArray, setDefaultArray] = React.useState([]);
  const [home, setHome] = React.useState(false);
  const [showUndo, setShowUndo] = React.useState(false);
  const [notes, setNotes] = React.useState('');

  const [studentFName, setStudentFName] = React.useState('');
  const [studentLName, setStudentLName] = React.useState('');
  const [schoolType, setSchoolType] = React.useState('');
  const [grade, setGrade] = React.useState('');
  const [from, setFrom] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD"));
  const [to, setTo] = React.useState(moment().tz("America/New_York").format("YYYY-MM-DD"));
  const [reason, setReason] = React.useState('');
  const [parentFName, setParentFName] = React.useState('');
  const [parentLName, setParentLName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [agreeFlag, setAgreeFlag] = React.useState(false);
  const [schoolArray, setSchoolArray] = React.useState([]);
  const [logo, setLogo] = React.useState('');
  const [uploadProgress, setUploadProgress] = React.useState('');

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [studentCheck, setStudentCheck] = useState(false);
  const [studentIdLabel, setStudentIdLabel] = useState();


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file)

    if (file) {

      console.log(file.name)
      setFileName(file.name);
    }
   
    setFile(file);

    console.log(file[0])
    
  };

  const queryParams = new URLSearchParams(window.location.search);
  const urlParam = queryParams.get('id'); // Retrieve the value of a specific query parameter




  const uploadFile = async () => {
      const S3_BUCKET = "docscp";
      const REGION = "us-east-2";
  
      AWS.config.update({
        accessKeyId: "AKIAYCFY2CS7MWI5S3UP",
        secretAccessKey: "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",
        acl: "public-read-write",
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
        acl: "public-read-write",

      });
  
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ACL: 'public-read'
      };
  
      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );

          setUploadProgress("Uploaded: " + parseInt((evt.loaded * 100) / evt.total) + "%")

        })
        .promise();
  
      await upload.then((err, data) => {
        console.log(err);
        console.log("=============== file uploaded")
        console.log(data)
      });
    };


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {

    console.log(studentFName)
    console.log(studentLName)
    console.log(sId)
    console.log(schoolType)
    console.log(grade)
    console.log(from)
    console.log(to)
    console.log(reason)

    if (studentFName === undefined || studentFName === '' || studentFName === ' '|| studentFName === null ||
    studentLName === undefined || studentLName === '' || studentLName === ' '|| studentLName === null ||
    sId === undefined || sId === '' || sId === ' '|| sId === null ||
    schoolType === undefined || schoolType === '' || schoolType === ' '|| schoolType === null ||
    grade === undefined || grade === '' || grade  === ' '|| grade === null ||
    from === undefined || from === '' || from  === ' '|| from === null ||
    to === undefined || to === '' || to  === ' '|| to === null ||
    reason === undefined || reason === '' || reason  === ' '|| reason === null || studentCheck === false) {

      passNameNull()

    } else {

      const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {

    console.log(studentFName)
    console.log(studentLName)
    console.log(sId)
    console.log(schoolType)
    console.log(grade)
    console.log(from)
    console.log(to)
    console.log(reason)


    if (
      studentFName === undefined || studentFName === '' || studentFName === ' '|| studentFName === null ||
      studentLName === undefined || studentLName === '' || studentLName === ' '|| studentLName === null ||
      sId === undefined || sId === '' || sId === ' '|| sId === null ||
      schoolType === undefined || schoolType === '' || schoolType === ' '|| schoolType === null ||
      grade === undefined || grade === '' || grade  === ' '|| grade === null ||
      from === undefined || from === '' || from  === ' '|| from === null ||
      to === undefined || to === '' || to  === ' '|| to === null ||
      reason === undefined || reason === '' || reason  === ' '|| reason === null ||
      parentFName === undefined || parentFName === '' || parentFName  === ' '|| parentFName === null ||
      parentLName === undefined || parentLName === '' || parentLName  === ' '|| parentLName === null ||
      email === undefined || email === '' || email  === ' '|| email === null ||
      phone === undefined || phone === '' || phone  === ' '|| phone === null
    
    
    ) {

      passNameNull()


    } else {


    const newCompleted = completed;

    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    //handleNext();

    setAlert(true);

    }


    

  };



  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleOpen = () => {
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  const closeDialog = () => {
    setAlert(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };



  useEffect(() => {

      
    console.log("==========================")

    var companyName = urlParam;
    getSchoolProfile(companyName);

    console.log(moment().tz("America/New_York").format("YYYY-MM-DD"));

    setFrom(moment().tz("America/New_York").format("YYYY-MM-DD"))
    setTo(moment().tz("America/New_York").format("YYYY-MM-DD"))



  }, []);




  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);
  };

  const postAb = (studentId,
                firstName,
                lastName,
                entryTime,
                schoolType,
                schoolCode,
                demeritCode,
                comments,
                pickupMethod,
                laneNo,
                homeRoom,
                hangerId,
                companyName,
                entryDate,
                demeritDesc,
                pushToken,
                pLastName,
                pFirstName,
                docUrl,
                userName) => {

    setLoading(true);

   const auth = {
      headers: {Authorization: "Bearer " + jwtToken} 
    };

    console.log("STUDENT_ID " + studentId)
    console.log("FIRST_NAME " + firstName)
    console.log("LAST_NAME " + lastName)
    console.log("ENTRY_TIME " + entryTime)
    console.log("SCHOOL_TYPE " + schoolType)
    console.log("SCHOOL_CODE " + schoolCode)
    console.log("DEMERIT_CODE " + demeritCode)
    console.log("COMMENTS " + comments)
    console.log("PICKUP_METHOD " + pickupMethod)
    console.log("CARLINE_GATE_NO " + laneNo)
    console.log("HOMEROOM " + homeRoom)
    console.log("HANGER_ID " + hangerId)
    console.log("COMPANY_NAME " + companyName)
    console.log("ENTRY_DATE " + entryDate)
    console.log("DEMERIT_DESC " + demeritDesc)
    console.log("PUSH_TOKEN " + pushToken)
    console.log("DOC_URL " + pushToken)

axios.post('https://api.conciergepadedu.com/api/Slips/PostSlipWeb',{ 
       STUDENT_ID: studentId,
       FIRST_NAME: firstName,
       LAST_NAME: lastName,
       ENTRY_TIME: entryTime,
       SCHOOL_TYPE: schoolType,
       SCHOOL_CODE: schoolCode,
       DEMERIT_CODE: demeritCode,
       COMMENTS: comments,
       PICKUP_METHOD: pickupMethod,
       CARLINE_GATE_NO: laneNo,
       HOMEROOM: homeRoom,
       HANGER_ID: hangerId,
       COMPANY_NAME: companyName,
       ENTRY_DATE: entryDate,
       DEMERIT_DESC: demeritDesc,
       PUSH_TOKEN: pushToken,
       PARENT_LAST_NAME: pLastName,
       PARENT_FIRST_NAME: pFirstName,
       USERNAME: userName,
       CARLINE_RUN: laneNo,
       LANE: 'Lane 1',
       SLOT: 'Slot 1',
       DOC_URL:docUrl
       
    },auth).then(response => {


    if (response.data.error) {

      setLoading(false);

      console.log(response.data.error)

    } else {

      console.log('success', response.data)

      setLoading(false);

       notify(studentFName + ' ' + studentLName);

       setActiveStep(0)





      

    }

   }).catch(error => {

      setLoading(false);
      console.log(error.response)

    });

 
  }






  const getSchoolProfile = (compName) => {
    
    var token = reactLocalStorage.get('jwtToken');
    var userName = reactLocalStorage.get('userName');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get("https://api.conciergepadedu.com/api" + '/Profiles/GetSchoolProfile/' + compName , auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {

                   console.log(response.data);


                   setLogo("https://logosedu.s3.us-east-2.amazonaws.com/" + response.data.logO_URL)
    
                   setSchoolArray(response.data)
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }

  const getCheckStudent = (compName,studentId) => {
    
      var token = reactLocalStorage.get('jwtToken');
      var userName = reactLocalStorage.get('userName');
  
      const auth = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.get('https://api.conciergepadedu.com/api' + '/Rosters/GetStudentId/' + studentId + "/" + compName, auth).then(response => {
      
                if (response.data.error) {
      
                    console.log('error', response.data.error);

                    setStudentCheck(true);
                    setStudentIdLabel("")
      
                } else {
  
                     console.log(response.data);
  
                    if (response.data) {

                      console.log("===============================")
                      console.log("found")
                      setStudentCheck(true);
                      setStudentIdLabel("Student ID found ✅")

                    } else {

                      setStudentCheck(false);
                      setStudentIdLabel("")
                    }

                }
      
            
      
        }).catch(error => {
      
            console.log(error.response);
            setStudentCheck(true);
            setStudentIdLabel("")
      
        });
      
      }
    






            


const navigate = useNavigate();

const goToRooms = () => navigate('/room',true)

const goToPasses = () => navigate('/pass',true)

const goToHall = () => navigate('/hallteacher',true)

const goToAdd = () => navigate('/addpass',true)

const goToFolder = () => navigate('/addfolder',true)

const goToAccounts = () => navigate('/student',true)

const goToReport = () => navigate('/reportdates',true)

const goToTeacher = () => navigate('/teacher',true)

const goToMenu = () => navigate('/teacher',true)

const goToReported = () => navigate('/reported',true)

const goToRoom = () => navigate('/room',true)





 

   const changeStudentFName = (val) => {

    setStudentFName(val);
    
  
  }

  const changeStudentLName = (val) => {

    setStudentLName(val);
    
  
  }

  const changeSId = (val) => {

    setSId(val);

    getCheckStudent(urlParam,val);

    
    
  
  }

  const changeReason = (val) => {

    setReason(val);
    
  
  }

  const changeParentFName = (val) => {

    setParentFName(val);
    
  
  }

  const changeParentLName = (val) => {

    setParentLName(val);
    
  
  }

  const changeEmail = (val) => {

    setEmail(val);
    
  
  }

  const changePhone = (val) => {

    setPhone(val);
    
  
  }
 



  const notify = (studentName) => toast("Absense was reported successfully for " + studentName);

  const passInSession = (studentName) => 
  
  toast("You cannot create pass for " + studentName + " because there is a pass in session.");

  const passNameNull = () => 
  
  toast("Please make sure all data is complete.  It seems like data is missing.");


  const doUpload = () => {

      setAlert(false);

      var fileName = ''

      if (file) {

        console.log("===========================")
        console.log("try to")

        fileName = 'https://docscp.s3.us-east-2.amazonaws.com/' + file.name

        uploadFile();

      }

      var start = new Date(from);
      var end = new Date(to);

      var loop = new Date(start);

      while(loop <= end){

           console.log("==================================");
           console.log(loop);
           console.log("==================================");

            postAb(sId,studentFName,studentLName,loop,schoolType,schoolType,'AB',reason + ' => created by: ' + email + ' tel: ' + phone,'Absense','','','',urlParam,loop,'Absense Reported on Web','',parentLName,parentFName,fileName,email)
        
            var newDate = loop.setDate(loop.getDate() + 1);
  
            loop = new Date(newDate);
  
        }
  

  
      setStudentFName('');
      setStudentLName('');
      setSId('')
      setSchoolType('');
      setReason('');
      setParentFName('');
      setParentLName('');
      setEmail('');
      setPhone('');
      setUploadProgress('');
      setFileName('')
      setStudentCheck(false);
      setStudentIdLabel("")


  };


  const handleSelect = (event) => {
    setSchoolType(event.target.value);
  };

  const handleGradeSelect = (event) => {
    setGrade(event.target.value);
  };

  const handleAgreeFlag = (event) => {
    setAgreeFlag(event.target.value);
  };



  const handleChange = (event, newValue) => {
    setTimeNeeded(newValue);
  };

 
  const goToLogout = () => navigate('/');

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setTimeNeeded(newValue);
  };

  const studentChange = (value) => {

    if (value != '' && value != undefined && value != null) {

        setSId(value.id)
        setGroupName(value.label)
        setGroupArray(value)

        console.log("======================================")
        console.log(value)
        console.log(value.length)
        console.log("======================================")
    }

  


  }


  const teacherChange = (value) => {

    if (value != '' && value != undefined && value != null) {


        console.log("teacher change")
        console.log(value.id)
        console.log(value.label)
        setTeacherId(value.label)
        setTeacherSelected(value.label)

    }

  


  }

  const passChange = (value) => {
  

    if (value != '' && value != undefined && value != null) {

        console.log(value.id)
        setPassId(value.id)
        setPassSelected(value.label)

    }

  


  }






  const changeGroupName = (val) => {

    console.log(val);

    setGroupName(val);
    
  
  }


  return (
    <ThemeProvider theme={defaultTheme}>


      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
    
          <Toolbar style={{backgroundColor: 'white'}} alignItems="center">
            <Box justifyContent="center"  alignItems="center" style={{alignItems: 'center'}}>
            <Stack direction="row" style={{marginBottom: 5}}spacing={{ xs: 1, sm: 2 }}  justifyContent="space-between"
  alignItems="center"  >

<img alt="logo" style={{marginBottom: 10,width: 200}} src={logo} />
            <Typography style={{fontSize: 16,fontWeight: 'bold'}}>
            STUDENT ABSENCE NOTIFICATION FORM
            </Typography>
    </Stack>

     
           
            </Box>

       

          </Toolbar>


    
          <Container style={{backgroundColor: 'white'}}maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            



    <div>
    <p>{urlParam}</p>

    </div>
 
    
        
  
          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


<TextField
fullWidth
style={{ marginBottom: 10, marginTop: 10}}
label="Instructions"
helperText="(300 character maximum)"
multiline
value={'Please complete the Absence Notification Form within 3 days of the student’s return to school to help school officials determine the excused/unexcused status of the absence(s). Students will receive a class admit the following morning certifying the status. Please keep the admits for your records. The Attendance Review Committee (ARC) may request it during a “No Grade Appeal” if a student has accumulated 5 or more absences in a semester course or 10 or more absences in an annual course. Attach any necessary documentation to this form. Five or more consecutive absences require a doctor’s note or other documentation. In an effort to standardize attendance procedures to review school absences, no other letters/forms will be accepted.\n __________________________________________________________________________________________\n Por favor complete el formulario de Justificación de Ausencias en los tres días subsiguientes al retorno del estudiante a la escuela. Esto permitirá que la administración clasifique la ausencia como es debido. Los estudiantes recibirán un pase de admisión a clase en la mañana siguiente. Por favor archive dicha notificación para ser mostrada a la Junta Evaluadora de la Asistencia , si es necesario apelar debido a que su hijo/a no recibió grado por razones de inasistencia (5 o más en un curso semestral – 10 o más en un curso anual). Por favor adjunte toda la documentación necesaria a este formulario. Cinco o más ausencias consecutivas requieren una justificación médica u otro tipo de excusa que pueda ser demostrada. En un esfuerzo por regular los procedimientos de justificación de inasistencias, no se aceptara ningún formulario distinto de este.'}
rows="12"
autoFocus={false}
variant="outlined"/>



<Box sx={{ width: '100%' }}>
      <Stepper  activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit">
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>

            {activeStep + 1 ==  1 ? (<>
            
            
              <TextField
              margin="normal"
              required
              fullWidth
              value={studentFName}
              id="studentFName"
              label="Student First Name"
              name="studentFName"
              onChange={(event) => changeStudentFName(event.target.value)}
            
            />

          <TextField
              margin="normal"
              required
              fullWidth
              value={studentLName}
              id="studentLName"
              label="Student Last Name"
              name="studentLName"
              onChange={(event) => changeStudentLName(event.target.value)}
            
            />

          <TextField
              margin="normal"
              required
              fullWidth
              value={sId}
              id="sId"
              label="Student ID"
              name="sId"
              onChange={(event) => changeSId(event.target.value)}
            
            />
            <Typography>{studentIdLabel}</Typography>

 <FormControl fullWidth>
 <InputLabel required id="test-select-label">School Type</InputLabel>
<Select
    style={{marginTop: 10, marginBottom: 20}}
    id="test-select-label"
    required
    value={schoolType}
    label="School Type"
    onChange={handleSelect}
  >
    <MenuItem value='MS'>Middle School</MenuItem>
    <MenuItem value={'HS'}>High School</MenuItem>
  </Select>
  </FormControl>

  <FormControl fullWidth>
 <InputLabel required id="test-select-label">Grade</InputLabel>
  <Select
    style={{marginTop: 10, marginBottom: 20,color:'black'}}
    id="test-select-label"
    required
    value={grade}
    label="Grade"
    onChange={handleGradeSelect}
  >
    <MenuItem value='6'>6th</MenuItem>
    <MenuItem value={'7'}>7th</MenuItem>
    <MenuItem value={'8'}>8th</MenuItem>
    <MenuItem value={'9'}>9th</MenuItem>
    <MenuItem value={'10'}>10th</MenuItem>
    <MenuItem value={'11'}>11th</MenuItem>
    <MenuItem value={'12'}>12th</MenuItem>
  </Select>
  </FormControl>


  <LocalizationProvider dateAdapter={AdapterDayjs}>

    <DemoContainer style={{marginBottom: 10}} components={['DatePicker']}>
    <FormControl>
   <DatePicker style={{marginBottom: 10}} label="Start Date of Absense"
     value={dayjs(from)}
     onChange={(newValue) => {

       var dateFormatted = new Date(dayjs(newValue))
       var fromDate = moment(dateFormatted).tz("America/New_York").format("YYYY-MM-DD")
       setFrom(fromDate)

     }
   }
      
  
   />
    </FormControl>
 </DemoContainer>

</LocalizationProvider>

<Box style={{marginTop: 10,marginBottom: 10}}>
<LocalizationProvider dateAdapter={AdapterDayjs} required>
    <DemoContainer style={{marginBottom: 10,marginTop: 20}} components={['DatePicker']} required>
    <FormControl>
   <DatePicker style={{marginBottom: 10}} required label="End Date of Absense"
     value={dayjs(to)}
     onChange={(newValue) => {

       var dateFormatted = new Date(dayjs(newValue))
       var toDate = moment(dateFormatted).tz("America/New_York").format("YYYY-MM-DD")
       setTo(toDate)

     }
   }
      
  
   />
    </FormControl>
 </DemoContainer>
</LocalizationProvider>
</Box>

<TextField
style={{ marginBottom: 10, marginTop: 10, width: 400 }}
required
label="Reason for Absense"
placeholder="Reason for Absense"
helperText="(300 character maximum)"
inputProps={{ maxLength: 300 }}
multiline
value={reason}
autoFocus
onChange={(event) => setReason(event.target.value)}
rows="4"
variant="outlined"/>

<Box>
<FormControl>

        <Typography style={{marginBottom: 10}}>Attach Doctor's Note</Typography>

        <input type="file" onChange={handleFileChange} />

        <Typography>{uploadProgress}</Typography>

        <Typography>{fileName}</Typography>



   
        
  </FormControl>
            
  </Box> 

      
            </>) : (<></>)} 

           





        {activeStep + 1 ==  2 ? (
                <>



                <Divider variant="middle" />
                <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
            Parent/Guardian Information
            </Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              value={parentFName}
              id="parentFName"
              label="Parent/Guardian First Name"
              name="parentFName"
              onChange={(event) => changeParentFName(event.target.value)}
            
            />

            <TextField
              margin="normal"
              required
              fullWidth
              value={parentLName}
              id="parentLName"
              label="Parent/Guardian Last Name"
              name="parentLName"
              onChange={(event) => changeParentLName(event.target.value)}
            
            />

            <TextField
              margin="normal"
              required
              fullWidth
              value={email}
              id="email"
              label="Parent/Guardian Email"
              name="email"
              onChange={(event) => changeEmail(event.target.value)}
            
            />


            <TextField
              margin="normal"
              required
              fullWidth
              value={phone}
              id="phone"
              label="Parent/Guardian Phone"
              name="phone"
              onChange={(event) => changePhone(event.target.value)}
            
            />

<Typography sx={{ mt: 2, mb: 1, py: 1 }}>
            I agree that I have reviewed and accurately completed all the information provided in this form and agree to all terms.
            </Typography>

<FormControl fullWidth>
<InputLabel id="test-select-label">Acknowledgement</InputLabel>
<Select
    style={{marginTop: 10, marginBottom: 20,color:'black'}}
    id="test-select-label"
    required
    value={agreeFlag}
    label="Acknowledgement"
    onChange={handleAgreeFlag}
  >
    <MenuItem value={true}>I agree</MenuItem>

  </Select>


</FormControl>






</>
                ) : (
<>
</>
                )}









            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

            <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>

              <Box sx={{ flex: '1 1 auto' }} />


              

        


{activeStep + 1 ==  1 ? (

<Button variant="outlined" onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
           

        ) : (
          <>
        
          </>
        )}

{activeStep + 1 ==  2 && agreeFlag === true ? (



              <Button variant="outlined" onClick={handleComplete}>
               Submit Absense
              </Button>

           

        ) : (
          <>
        
          </>
        )}






                 
            
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>


    <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to submit absense?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are submitting an absense for {studentFName}  {studentLName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}