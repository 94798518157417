import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listitems';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { SketchPicker,CirclePicker } from 'react-color'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardIcon from '@mui/icons-material/Speed';
import HallIcon from '@mui/icons-material/DirectionsWalk';
import ShoppingCartIcon from '@mui/icons-material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useNavigate,useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import EditIcon from '@mui/icons-material/ArrowForwardIos';
import {reactLocalStorage} from 'reactjs-localstorage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Autocomplete from '@mui/material/Autocomplete';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridToolbar
} from '@mui/x-data-grid';
import {   GridActionsCellItem,
    GridAddIcon,
    GridDeleteIcon,
  } from "@mui/x-data-grid";
  import ButtonGroup from '@mui/material/ButtonGroup';
  import {QRCodeSVG} from 'qrcode.react';
  import Stack from '@mui/material/Stack';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getconciergepad.com/">
        ConciergePad LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function EditFolder() {

  const {name,age,jwtToken,firstName,lastName,teamId,leagueId,teamName,teamLogo,teamSport,masterAccount,apiEndpoint,loginUuid,companyName,hallPassMenu,reunifyMenu,emergencyMenu,logoUrl} = useSelector(state => state.userReducer);
  const [open, setOpen] = React.useState(true);
  const [passName,setPassName] = useState('');
  const [passTime,setPassTime] = useState('8');
  const [passImage,setPassImage] = useState('');
  const [passCapacity,setPassCapacity] = useState('20');
  const [passColor,setPassColor] = useState('#000');
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("FaUsers")
  const [travelType, setTravelType] = React.useState("Round-trip")
  const [roomNo, setRoomNo] = React.useState("")
  const location = useLocation();
  const [locArray,setLocArray]  = useState([]);
  const [folders,setFolders]  = useState([]);
  const [rowId,setRowId] = useState();
  const [sId,setSId] = useState();

  useEffect(() => {

      
    console.log("==========================")
    var compName = reactLocalStorage.get('companyName');
    console.log("Get companyName: " + compName)
    console.log("Folder id: " + location.state.id)
    console.log("==========================")
    getPass(location.state.id)
    getPasses(compName);
    getFolderPasses(compName,location.state.id)


  }, []);

  const getPasses = (compName) => {
    

    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/PassMaints/GetPasses/' + compName, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {
    
                  console.log(response.data);
    
            
                

                   var tempArray = [];
                   setLocArray([])

                   for (var i = 0; i < response.data.length; i++) {

                    console.log("===================")
                    console.log(response.data[i].pasS_NAME)
                    tempArray.push({"label":response.data[i].pasS_NAME,"id":response.data[i].id})

                }

                if (tempArray) {

                    setLocArray(tempArray)

                }
               
           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    

const getFolderPasses = (compName,folderId) => {
    

        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/GetFolder/' + compName + "/" + folderId, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                      console.log(response.data);
        
                
                    
    
    
                        setFolders(response.data)
    
                    
                   
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }
        
  const getPass = (id) => {

    var token = reactLocalStorage.get('jwtToken');

    const auth = {
          headers: {
              Authorization: "Bearer " + token
          }
      };
      var apiUrl = reactLocalStorage.get('apiUrl');
      axios.get(apiUrl + '/Folders/' + id, auth).then(response => {
    
              if (response.data.error) {
    
                  console.log('error', response.data.error)
    
              } else {

                   console.log(response.data.foldeR_NAME)

                   if (response.data) {


                        setPassName(response.data.foldeR_NAME)
                        setPassColor(response.data.color)
              
                   }



           
                  
    
    
              }
    
          
    
      }).catch(error => {
    
          console.log(error.response);
    
      });
    
    }
    



  const toggleDrawer = () => {
    setOpen(!open);
  };


  const handleClickOpen = () => {
    setAlert(true);
  };


  const notify = () => toast("Pass was updated successfully");

  const doUpload = () => {

    setAlert(false);
    var userName = reactLocalStorage.get('userName');
    var compName = reactLocalStorage.get('companyName');

    var dateCreated = moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS");

    putFolder(location.state.id,compName,passName,passColor,dateCreated);

  };


  const columns = [
    {
        field: "pasS_NAME",
        headerName: " ",
        sortable: false,
        width: 220,
        height: 150,
        flex: 1,
        marginTop: 10,
        marginBottom: 10,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            return (
                <div className="d-flex justify-content-between align-items-center">

<Box
        style={{
          backgroundColor: params.row.pasS_COLOR,
          padding: 10,
          width: 200,
          borderRadius: 10,
          color: 'white' ,
          marginTop: 5,
          marginBottom: 5,
          fontSize: 16
        }}
      >
      
        <Stack direction="row" spacing={1} >

  
        <Typography style={{ fontSize: 16}}>
        {params.row.pasS_NAME}
        </Typography>

   


</Stack>

   


      </Box>


     
                 </div>
            );
         }
      },
      {
        field: "pasS_ID",
        headerName: " ",
        sortable: false,
        width: 205,
        height: 150,
        flex: 1,
        marginTop: 10,
        marginBottom: 10,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            return (
                <div className="d-flex justify-content-between align-items-center">
  

     


 

        <QRCodeSVG size={40} margin={10}value={params.row.id} />
      
                 </div>
            );
         }
      },
    { field: 'timE_NEEDED', headerName: 'Time limit (min)', width: 130 },
    { field: 'capacity', headerName: 'Capacity', width: 130 },
    {field: "saveDelete",
  headerAlign: "center",
  align: "center",
  flex: 1.5,
  type: "actions",
  getActions: (params) => [
    
    <GridActionsCellItem
      icon={<AddIcon />}
      label="add"
      onClick={() => {

        goToEmp(location.state.id,params.row.id)
      
        }


      }

    />,
    <GridActionsCellItem
    icon={<GridDeleteIcon />}
    label="delete"
    onClick={() => {

      removePass(params.row.id)
    
      }


    }

  />,
  ]},

  ];

  




  const navigate = useNavigate();

  const goToMenu = () => navigate('/dashboard',true)

  const goToPasses = () => navigate('/pass',true)

  const goToHall = () => navigate('/hall',true)

  const goToAccounts = () => navigate('/student',true)

  const goToInbox = () => navigate('/inbox',true)

  const goToLogout = () => navigate('/');

  const goToEdit=(pId)=>{
    navigate('/editpass',{state:{id:pId}});
    }

    const goToReport = () => navigate('/reportdates',true)

const goToTeacher = () => navigate('/teacher',true)

    const goToEmp=(folderId,passId)=>{
        navigate('/editemp',{state:{folderId:folderId,passId:passId}});
        }

    const goToEditFolder=(pId)=>{
      navigate('/editfolder',{state:{id:pId}});
      }

      const handleOnCellClick = (params) => {
 
        if(params.field === 'pasS_NAME'){

          console.log('passId ' + location.state.id )
          console.log('folderId ' +params.row.id)

          goToEmp(location.state.id,params.row.id)

        }




    
      };

const getPassForPost = () => {

        var token = reactLocalStorage.get('jwtToken');
    
        const auth = {
              headers: {
                  Authorization: "Bearer " + token
              }
          };
          var apiUrl = reactLocalStorage.get('apiUrl');
          axios.get(apiUrl + '/PassMaints/' + sId, auth).then(response => {
        
                  if (response.data.error) {
        
                      console.log('error', response.data.error)
        
                  } else {
        
                      //console.log(response.data);
        
                
                       //setLocArray(response.data)
    
                       console.log(response.data.pasS_NAME)
    
                       if (response.data) {

                            var userName = reactLocalStorage.get('userName');

                             putPass(response.data.id,response.data.pasS_NAME,'',response.data.pasS_COLOR,response.data.timE_NEEDED,response.data.rounD_TRIP,response.data.rooM_NO,response.data.capacity,userName,location.state.id,"Y",response.data.approvE_FLAG)
                       }
    
    
    
               
                      
        
        
                  }
        
              
        
          }).catch(error => {
        
              console.log(error.response);
        
          });
        
        }
        

const putPass = (id,pName,pImage,pColor,timeNeeded,roundTrip,roomNo,capacity,userName,folderId,folderFlag,approveFlag) => {

            setLoading(true);
        
            var token = reactLocalStorage.get('jwtToken');
        
        
           const auth = {
              headers: {Authorization: "Bearer " + token} 
            };
        
            console.log("ID " + id)
            console.log("PASS_NAME " + pName)
            console.log("PASS_IMAGE " + pImage)
            console.log("PASS_COLOR " + pColor)
            console.log("TIME_NEEDED " + timeNeeded)
            console.log("USERNAME " + userName)
            console.log("CAPACITY " + capacity)
            console.log("ROUND_TRIP " + roundTrip)
            console.log("ROOM_NO " + roomNo)
        
        
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.put(apiUrl + '/PassMaints/UpdatePass/' + id,{ 
               ID: id,
               PASS_NAME: pName,
               PASS_IMAGE: pImage,
               PASS_COLOR: pColor,
               TIME_NEEDED: timeNeeded,
               ROUND_TRIP: roundTrip,
               ROOM_NO: roomNo,
               CAPACITY: capacity,
               USERNAME: userName,
               FOLDER_ID: folderId,
               FOLDER_FLAG: folderFlag,
               APPROVE_FLAG: approveFlag
            },auth).then(response => {
        
        
            if (response.data.error) {
        
              setLoading(false);
        
            } else {
        
              console.log('success', response.data)
        
              setLoading(false);

              var compName = reactLocalStorage.get('companyName');
              getFolderPasses(compName,location.state.id)
        
        
              
        
            }
        
           }).catch(error => {
        
              setLoading(false);
              console.log(error.response)
        
            });
        
         
          }


const removePass = (id) => {

            setLoading(true);
        
            var token = reactLocalStorage.get('jwtToken');
        
        
           const auth = {
              headers: {Authorization: "Bearer " + token} 
            };
        
            console.log("ID " + id)
        
        
        var apiUrl = reactLocalStorage.get('apiUrl');
        axios.put(apiUrl + '/PassMaints/RemoveFolder/' + id,{ 
               ID: id,
               FOLDER_ID: 0,
               FOLDER_FLAG: 'N'
            },auth).then(response => {
        
        
            if (response.data.error) {
        
              setLoading(false);
        
            } else {
        
              console.log('success', response.data)
        
              setLoading(false);

              var compName = reactLocalStorage.get('companyName');
              getFolderPasses(compName,location.state.id)

        
            }
        
           }).catch(error => {
        
              setLoading(false);
              console.log(error.response)
        
            });
        
         
          }


const putFolder = (id,compName,pName,pColor,dateCreated) => {

            setLoading(true);
         
            console.log("==================")
            console.log(compName)
            console.log(pName)
            console.log(pColor)
            console.log(dateCreated)
            console.log("==================")
         
            var token = reactLocalStorage.get('jwtToken');
         
         
            const auth = {
               headers: {Authorization: "Bearer " + token} 
             };
         
         
         var apiUrl = reactLocalStorage.get('apiUrl');
         axios.put(apiUrl + '/Folders/' + id,{ 
                ID:id,
                COMPANY_NAME: compName,
                FOLDER_NAME: pName,
                DATE_ADDED: dateCreated,
                COLOR: pColor
             },auth).then(response => {
         
         
             if (response.data.error) {
         
               setLoading(false);
         
             } else {
         
               console.log('success', response.data)
         
               setLoading(false);
         
               setPassName('')
         
               notify();
         
         
               
         
             }
         
            }).catch(error => {
         
               setLoading(false);
               console.log(error.response)
         
             });
         
          
           }
         
        

  const handleCapacity = (event, newValue) => {
    setPassCapacity(newValue);
  };

  const handleTime = (event, newValue) => {
    setPassTime(newValue);
  };


  const handleClose = () => {
    setAlert(false);
  };

  const handleSelect = (event) => {
    setTravelType(event.target.value);
  };




  const changePassName = (val) => {

    console.log(val);

    setPassName(val);
    
  
  }


  const changeRoomNo = (val) => {

    console.log(val);

    setRoomNo(val);
    
  
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent style={{backgroundColor: passColor}} backgroundColor={passColor}>
        <Typography variant="h5" component="div" style={{color: 'white'}}>
        {passName}
        </Typography>

      </CardContent>

    </React.Fragment>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
                   
       Edit Folder
            </Typography>
      
            <Typography variant="body2">
          {name}
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
     
     <ListItemButton onClick={goToMenu}>
 <ListItemIcon>
   <DashboardIcon />
 </ListItemIcon>
 <ListItemText primary="Dashboard" />
</ListItemButton>
<ListItemButton onClick={goToPasses}>
 <ListItemIcon>
   <BarChartIcon />
 </ListItemIcon>
 <ListItemText primary="Rooms" />
</ListItemButton>

<ListItemButton onClick={goToAccounts}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Accounts" />
    </ListItemButton>



    <ListItemButton onClick={goToInbox}>
      <ListItemIcon>
        <VoicemailIcon />
      </ListItemIcon>
      <ListItemText primary="Digital Voicemail" />

      </ListItemButton>


       <Divider sx={{ my: 1 }} />
       <ListSubheader component="div" inset>

</ListSubheader>

<ListItemButton onClick={goToLogout}>
<ListItemIcon>
<AssignmentIcon />
</ListItemIcon>
<ListItemText primary="Logout" />
</ListItemButton>
     </List>



        
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}


            <Grid container spacing={3}>
              {/* Chart */}
             
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
            
                  }}
                >
    
            <TextField
              margin="normal"
              required
              fullWidth
              defaultValue={passName}
              value={passName}
              id="passName"
              label="Name of Room/Pass"
              name="passName"
              autoFocus
              onChange={(event) => changePassName(event.target.value)}
            />

<Grid style={{marginTop: 5,marginLeft: 5}} container spacing={2}>
<Autocomplete
      disablePortal
      id="combo-box-demo"
      onChange={(event, value) => {
        
        if (value !== undefined && value !== null && value !== '') {

          setSId(value.id)
        
        }

        }
        
      
      } 
      options={locArray}
      sx={{ width: 300 }}
      renderInput={
          
        (params) => <TextField {...params} label="Rooms" />
    
    }
    />

<Button onClick={getPassForPost}>
Add Room

</Button>
    </Grid>





                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={40} md={4} lg={3}>
        
              <Paper
                >





                <CirclePicker
                style={{marginTop: 5,marginLeft: 5}}
                 color={passColor}
                 onChangeComplete={ color => setPassColor(color.hex) }
                >


                </CirclePicker>

                </Paper>
               
              </Grid>


              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>

                </Paper>
              </Grid>

              <Grid item xs={12}>
        
    



      <DataGrid
        rows={folders}
        columns={columns}
        onCellClick={handleOnCellClick}
        autoHeight
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          }
 
        }}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
      />


<Button 
            style={{marginTop: 10,marginBottom: 10}}
            variant="outlined" 
            width="100%"
            onClick={() => {
                //console.log("button pressed")
                //alert('clicked');
                handleClickOpen();
           
              }}
           >
         Update Folder
      </Button>


              </Grid>

              <ToastContainer position="top-center" />
              <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to update folder?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are editing folder {passName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doUpload} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           
       
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        
        
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}