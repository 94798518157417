import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './chat.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route,HashRouter,Link } from "react-router-dom";
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import Pass from './screens/Pass';
import Reported from './screens/Reported';
import Staff from './screens/Staff';
import Student from './screens/Student';
import Lost from './screens/Lost';
import AddPass from './screens/AddPass';
import EditPass from './screens/EditPass';
import AddFolder from './screens/AddFolder';
import Absense from './screens/Absense';
import Restrict from './screens/Restrict';
import EditFolder from './screens/EditFolder';
import Reg from './screens/Reg';
import LostUpdate from './screens/LostUpdate';
import EditEmp from './screens/EditEmp';
import Teacher from './screens/Teacher';
import Chat from './screens/Chat';
import Stats from './screens/Stats';
import StatsPass from './screens/StatsPass';
import Clr from './screens/Clr';
import Group from './screens/Group';
import GroupTeacher from './screens/GroupTeacher';
import PassCalendar from './screens/PassCalendar';
import PassCreate from './screens/PassCreate';
import StudentCreate from './screens/StudentCreate';
import Message from './screens/Message';
import ReportDates from './screens/ReportDates';
import StudentDash from './screens/StudentDash';
import Hall from './screens/Hall';
import Room from './screens/Room';
import Schedule from './screens/Schedule';
import Inbox from './screens/Inbox';
import TeacherCreate from './screens/TeacherCreate';
import HallTeacher from './screens/HallTeacher';
import HallStudent from './screens/HallStudent';
import { Provider } from 'react-redux'
import {Store} from './screens/redux/store'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

/*if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}*/




root.render(
  <Provider store={Store}>
    <GoogleOAuthProvider clientId="819239592244-05ina5e64o2o9laaci66k3lor5bic99c.apps.googleusercontent.com">
<React.StrictMode>
    <BrowserRouter>
      <Routes>
         <Route index path="/" element={<Login/>} />
         <Route path="dashboard" element={<Dashboard/>} />
         <Route path="pass" element={<Pass />} />
         <Route path="addpass" element={<AddPass />} />
         <Route path="lost" element={<Lost />} />
         <Route path="lostupdate" element={<LostUpdate />} />
         <Route path="staff" element={<Staff />} />
         <Route path="student" element={<Student />} />
         <Route path="editpass" element={<EditPass />} />
         <Route path="reg" element={<Reg />} />
         <Route path="clr" element={<Clr />} />
         <Route path="addfolder" element={<AddFolder />} />
         <Route path="editfolder" element={<EditFolder />} />
         <Route path="group" element={<Group />} />
         <Route path="groupteacher" element={<GroupTeacher />} />
         <Route path="passcalendar" element={<PassCalendar />} />
         <Route path="passcreate" element={<PassCreate />} />
         <Route path="studentcreate" element={<StudentCreate />} />
         <Route path="inbox" element={<Inbox />} />
         <Route path="studentdash" element={<StudentDash />} />
         <Route path="message" element={<Message />} />
         <Route path="restrict" element={<Restrict />} />
         <Route path="chat" element={<Chat />} />
         <Route path="hall" element={<Hall />} />
         <Route path="editemp" element={<EditEmp />} />
         <Route path="teacher" element={<Teacher />} />
         <Route path="hallteacher" element={<HallTeacher />} />
         <Route path="hallstudent" element={<HallStudent />} />
         <Route path="reportdates" element={<ReportDates />} />
         <Route path="stats" element={<Stats />} />
         <Route path="statspass" element={<StatsPass />} />
         <Route path="teachercreate" element={<TeacherCreate />} />
         <Route path="room" element={<Room />} />
         <Route path="reported" element={<Reported />} />
         <Route path="schedule" element={<Schedule />} />
         <Route path="absense" element={<Absense />} />


      </Routes>
    </BrowserRouter>
    </React.StrictMode>
    </GoogleOAuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
