
import * as React from 'react';
import {useEffect, useState,useLayoutEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import AspectRatio from '@mui/joy/AspectRatio';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import {setName,setAge,setJwtToken,setFirstName,setLastName,setUserId,setMobilePhone,setUserImage,setLoginUuid,setCompanyName,setDob,setPushToken,setAdminFlag,setTimeZone,setPanicButton,setEmployeeName,setEmployeeId,setBusBoarding,setEarlyRelease,setHallPass,setReunify,setEmergency,setSkipFlag,setLogoUrl } from './redux/actions';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate,createSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import {reactLocalStorage} from 'reactjs-localstorage';
import { useHistory } from "react-router-dom";

import { useGoogleLogin,GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import GoogleButton from 'react-google-button'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://getbridgepay.com/">
        BridgePay
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {

  const [username,setUsername] = useState();
  const [password,setPassword] = useState('');
  const [posts,setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teamData,setTeamData] = useState([]);
  const [version,setVersion] = useState([]);

  const {name,age,jwtToken,apiEndpoint} = useSelector(state => state.userReducer);
  const dispatch = useDispatch();

  const notify = () => toast("Username or password was incorrect");


  useEffect(() => {
    document.title = "BridgePay app"
    reactLocalStorage.set('apiUrl', "https://api.bridgepay.io/api");
    //reactLocalStorage.set('apiUrl', " http://cpapi-dev-dev.us-east-1.elasticbeanstalk.com/api");
   
  }, []);
  


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };





  const goToLogin = () => {

    console.log("do login")
    setLoading(true);

    console.log(username +  ' ' + password);
    //axios.post(`http://astro-test.us-east-2.elasticbeanstalk.com/api/${username}` )
    var apiUrl = reactLocalStorage.get('apiUrl');
    axios.post(apiUrl + "/auth/token",
      {
         USERNAME: username,
         PASSWORD: password 
      }
    ).then(response => {
  
        console.log(response.data)

        setPosts(response.data)
  
        dispatch(setName(username));
        dispatch(setJwtToken(response.data));

        getLoginProfile(response.data,username);
  
        //storeData(response.data);
  
       
  
      })
      .catch(error => {
        console.log("In the error section")
        console.log(error);
        notify();
        setLoading(false);
   
      })
  
    //navigation.navigate('Health')
    
  
  }


const goToSso = (userName,profilePic) => {

    console.log("=====================")
    console.log("IN GOOGLE SSO");

    setLoading(true);

    //axios.post(`http://astro-test.us-east-2.elasticbeanstalk.com/api/${username}` )
    var apiUrl = reactLocalStorage.get('apiUrl');
    axios.post(apiUrl + "/auth/ssotoken",
      {
         USERNAME: userName,
      }
    ).then(response => {
  
        console.log(response.data)

        if (response.data.length > 0) {

          dispatch(setName(userName));
          dispatch(setJwtToken(response.data));
  
          reactLocalStorage.set('logoUrl', profilePic);
          reactLocalStorage.set('profilePic', profilePic);
          
  
          getGoogleSsoProfile(response.data,userName);


        } else {

          //try students

          studentSsoLogin(userName,profilePic)
          


          
        }

        

  
      })
      .catch(error => {
        console.log("In the error section")
        console.log(error);
        //notify();
        setLoading(false);
        studentSsoLogin(userName,profilePic)
   
      })
  
    //navigation.navigate('Health')
    
  
  }


  const changeUsername = (val) => {

    console.log(val);

    setUsername(val);
    
  
  }

  const changePassword = (val) => {

    console.log(val);

    setPassword(val);
    
  
  }





const studentSsoLogin = (emailValue,profilePic) => {

  setLoading(true);

  console.log(emailValue);
  var apiUrl = reactLocalStorage.get('apiUrl');
  axios.post(
    apiUrl + "/auth/ssosttoken",
    {
       USERNAME: emailValue,
    }
  ).then(response => {

      console.log(response.data)

     

      dispatch(setJwtToken(response.data));

      getSsoProfile(response.data,emailValue,profilePic);

 

    })
    .catch(error => {
      console.log("In the error section")
      console.log(error);
      setLoading(false);

    

      /*Toast.show({
        description: "Username or Password not correct",
        status: "error",
        placement: "top",
      })*/

    })

  //navigation.navigate('Health')
  

}

const getSsoProfile = (accessToken,emailValue,profilePic) => {
  
  let bearerConfig = {
      headers: {
      Authorization: "Bearer " + accessToken
      }
  }

  console.log("====================")
  console.log(emailValue)
  console.log("====================")

var apiUrl = reactLocalStorage.get('apiUrl');

axios.get(apiUrl + '/Profiles/GetStdnt/' +  emailValue,bearerConfig).then(response => {

if (response.data.error) {

    console.log("ERROR");
    console.log('error', response.data.error);
    setLoading(false);
    notify();
  

} else {


console.log('success', response.data)

    dispatch(setName(emailValue));

    reactLocalStorage.set('logoUrl', profilePic);
    reactLocalStorage.set('profilePic', profilePic);
    reactLocalStorage.set('jwtToken', accessToken);
    reactLocalStorage.set('userName', emailValue);
    reactLocalStorage.set('name', response.data[0].firstName + ' ' + response.data[0].lastName);
    reactLocalStorage.set('permissionFlag', 'S');
    reactLocalStorage.set('companyName', response.data[0].companyName);
    reactLocalStorage.set('empId', response.data[0].id);
    reactLocalStorage.set('studentId', String(response.data[0].studentId));


    setLoading(false);


      handleClick('S');
    








  }

  }).catch(error => {

      setLoading(false);
      notify();
      console.log("CATCH ERROR");
      console.log(error.response);
      console.log(error)


  });


}




const getLoginProfile = (accessToken,emailValue) => {
  
  let bearerConfig = {
      headers: {
      Authorization: "Bearer " + accessToken
      }
  }

var apiUrl = reactLocalStorage.get('apiUrl');

axios.get(apiUrl + '/users/' +  emailValue,bearerConfig).then(response => {

if (response.data.error) {

    console.log("ERROR");
    console.log('error', response.data.error);
    setLoading(false);

    notify();

  

} else {


    console.log('success', response.data)


    dispatch(setUserId(response.data.id))


    reactLocalStorage.set('jwtToken', accessToken);
    reactLocalStorage.set('userName', emailValue);
    reactLocalStorage.set('name', response.data.firsT_NAME + ' ' + response.data.lasT_NAME);

    reactLocalStorage.set('empId', response.data.id);
    reactLocalStorage.set('empPhone', '0');
    reactLocalStorage.set('profilePic', '');


    reactLocalStorage.set('mobile', response.data.mobilE_PHONE);
    reactLocalStorage.set('email', response.data.email);
    reactLocalStorage.set('first', response.data.firsT_NAME);

    reactLocalStorage.set('last', response.data.lasT_NAME);

    reactLocalStorage.set('loginUuid', response.data.uuid);

    reactLocalStorage.set('pId', response.data.id);

    reactLocalStorage.set('dateCreated', response.data.datE_CREATED);
    reactLocalStorage.set('regUuid', response.data.registratioN_UUID);
    reactLocalStorage.set('primaryAccount', response.data.primarY_ACCOUNT);
    reactLocalStorage.set('linkedAccount', response.data.linkeD_ACCOUNT);
    reactLocalStorage.set('activeFlag', response.data.activE_FLAG);




    setLoading(false);

    

    handleClick(response.data.permissioN_FLAG);
    
















  }

  }).catch(error => {

      setLoading(false);

      notify();

      console.log("CATCH ERROR");
      console.log(error.response);


  });


}

const getGoogleSsoProfile = (accessToken,emailValue) => {

  let bearerConfig = {
      headers: {
      Authorization: "Bearer " + accessToken
      }
  }

  var apiUrl = reactLocalStorage.get('apiUrl');

  axios.get(apiUrl + '/Profiles/GetSso/' +  emailValue,bearerConfig).then(response => {

if (response.data.error) {

    console.log("ERROR");
    console.log('error', response.data.error);
    setLoading(false);

    notify();

  

} else {


    console.log('success', response.data)


    dispatch(setUserId(response.data[0].id))


    //reactLocalStorage.clear();

    reactLocalStorage.set('jwtToken', accessToken);
    reactLocalStorage.set('userName', emailValue);
    reactLocalStorage.set('name', response.data[0].empName);
    reactLocalStorage.set('permissionFlag', response.data[0].permissionFlag);
    reactLocalStorage.set('companyName', response.data[0].companyName);
    reactLocalStorage.set('empId', response.data[0].id);
    reactLocalStorage.set('empPhone', response.data[0].empPhone);


    setLoading(false);

    if (response.data[0].permissionFlag) {

      handleClick(response.data[0].permissionFlag);
    }
    



  }

  }).catch(error => {

      setLoading(false);

      notify();

      console.log("CATCH ERROR");
      console.log(error.response);


  });


}


const removeSlashSuffix = (input)  => {
  if (input.charAt(input.length - 1) === "/") {
      return input.substr(0, input.length - 1)
  } else {
      return input;
  }
}



const navigate = useNavigate();
/*const handleClick = (compName) => {
  

  //router.push(`${removeSlashSuffix(router.location.pathname)}/location`)
  //navigate('/location',true);

  navigate({
    pathname: "/dashboard",
    search: createSearchParams({
        id: compName
    }).toString()
  });
  

}*/


//https://calendar.google.com/calendar/u/0/r/eventedit?text=Ka+Ming+Liows+Test+Event&dates=20241106T120000Z/20241106T120000Z&details&location=somewhere&trp=false

const loginToGoogle = useGoogleLogin({
  onSuccess: async tokenResponse => {
      const token = tokenResponse.access_token;
    // fetching userinfo can be done on the client or the server
    console.log("here is the access token: " + tokenResponse.access_token)
    const userInfo = await axios
      .get('https://www.googleapis.com/oauth2/v3/userinfo', 
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      })
    const result = userInfo.data;
    console.log(userInfo.data)
   // contains name, email & googleId(sub)
  },
});


const goToLostPassword = () => {

  navigate('/lost',true)
  
}

const handleClick = (pFlag) => {

  console.log("=======================")
  console.log("Here is the permission")
  console.log(pFlag)
  console.log("=======================")

  if (pFlag == "S") {

    navigate('/studentcreate',true)
  }
  else if (pFlag === "School-admin" || pFlag === "Super-admin") {

    navigate('/dashboard',true)

  } else {

    navigate('/dashboard',true)
  }

  
  

}





  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


          <img alt="logo" style={{marginBottom: 10}} src="https://bridgepaypics.s3.us-east-2.amazonaws.com/bridgepay_logo_text.png" />

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => changeUsername(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => changePassword(event.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />







            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                //console.log("button pressed")
                //alert('clicked');
                goToLogin();
              }}
            >
              Sign In
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                //console.log("button pressed")
                //alert('clicked');
                navigate('/reg',true)
              }}
            >
              Register
            </Button>
            {loading ? (
      

      <LinearProgress color="secondary" />

        ) : (
          <>
            
          </>
        )}
            <Grid container>
              <Grid item xs>
                <Link href=""  onClick={goToLostPassword} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
       
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <ToastContainer
position="top-center"
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
type="error"
pauseOnHover
theme="dark"
/>
      </Container>
    </ThemeProvider>
  );
}